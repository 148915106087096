import React from 'react';
import PropTypes from 'prop-types';
import { map, keys } from 'lodash';
import classNames from 'classnames';

import { getPriority } from 'components/core/PriorityTooltip/components/PriorityButton';
import { priorities } from 'components/core/PriorityTooltip/constants';
import LabelGroup from 'components/core/LabelGroup';
import Button from 'components/core/Button';
import Modal from 'components/core/Modal';
import RadioBox from 'components/core/RadioBox';

import usePriorityModalButton from './usePriorityModalButton';
import styles from './styles.module.scss';

function PriorityModalButton({
  ids,
  className,
  onSave,
  ...restProps
}) {
  const {
    handleSave,
    resetToggleModal,
    isOpenModal,
    isLoading,
    confirmable,
    toggleModal,
    handleChange,
  } = usePriorityModalButton({ ids, onSave });

  const renderPriorities = map(keys(priorities), (priority) => {
    const { label, icon, iconColor } = getPriority(priority);

    return (
      <RadioBox
        key={priority}
        name="priority"
        value={priority}
        onChange={handleChange}
        label={(
          <div className={styles.priority}>
            <i className={classNames(icon, iconColor, styles.priorityIcon)} />
            {label}
          </div>
        )}
      />
    );
  });

  const modalActions = (
    <>
      <Button
        theme="light"
        size="small"
        onClick={resetToggleModal}
      >
        Cancel
      </Button>
      <Button
        size="small"
        isLoading={isLoading}
        disabled={!confirmable}
        onClick={handleSave}
      >
        Confirm
      </Button>
    </>
  );

  return (
    <>
      <Button
        {...restProps}
        className={className}
        isLoading={isLoading}
        onClick={toggleModal}
      >
        Priority
      </Button>

      <Modal
        title="Set a priority"
        isOpen={isOpenModal}
        toggleOpen={resetToggleModal}
        buttons={modalActions}
      >
        <div>
          <LabelGroup>
            {renderPriorities}
          </LabelGroup>
        </div>
      </Modal>
    </>
  );
}

PriorityModalButton.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
  className: PropTypes.string,
  onSave: PropTypes.func,
};

export default PriorityModalButton;
