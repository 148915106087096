import { isEmpty, isArray, isString } from 'lodash';

export function checkIsOpen(initialValues) {
  const {
    eventDate,
    interactionType,
    people,
    bills,
    regulations,
    tags,
    committees,
    documents,
    hillDayActivity,
    customFieldsAnswers,
  } = initialValues;

  const systemFields = [
    eventDate,
    interactionType,
    people,
    bills,
    regulations,
    tags,
    committees,
    documents,
    hillDayActivity,
  ];

  const isArrayOfEmptyStrings = value => isArray(value) && value.every(arrayElement => isString(arrayElement) && isEmpty(arrayElement));
  const isPresent = value => !isEmpty(value) && !isArrayOfEmptyStrings(value);

  const isOpenSystemFields = systemFields.some(isPresent);
  const isOpenCustomFields = Object.values(customFieldsAnswers).some(isPresent);

  return {
    isOpenSystemFields,
    isOpenCustomFields,
  };
}
