import React from 'react';
import PropTypes from 'prop-types';

import {
  filtersShape,
  uiShape,
  cardShape,
  paginationShape,
} from 'components/Grm2/shapes';
import Filters from 'components/Grm2/common/Filters';
import FooterActions from 'components/Grm2/common/FooterActions';
import useAdvancedSearch from 'components/Grm2/useAdvancedSearch';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';
import Header from 'components/Grm2/common/Header';
import Feed from 'components/Grm2/common/Feed';
import AppliedFiltersLabel from 'components/Grm2/common/AppliedFiltersLabel';
import { locationShape } from 'utils/shapes/history';
import { queryParse } from 'utils/query-string';

import Dashboard from '../Dashboard';
import useUpdateActivityPage from '../../useUpdateActivityPage';
import styles from './styles.module.scss';

const Container = ({
  fetchCards,
  className,
  cards,
  updateCard,
  updateDataItem,
  setAdvancedSearch,
  pagination,
  advancedSearch,
  ui,
  filters,
  resetFilters,
  setFilters,
  location,
}) => {
  const { isLoading } = ui;
  const { isMobile } = useMediaQuery();
  const { totalCount, currentPage } = pagination;
  const { lastSearchId } = advancedSearch;
  const isExportOpen = !!queryParse(location.search, 'export');

  useAdvancedSearch({
    fetchCards,
    setAdvancedSearch,
    currentPage,
    lastSearchId,
    setFilters,
  });

  useUpdateActivityPage({ cards, updateCard, updateDataItem });

  return (
    <div className={className}>
      <Header isExportOpen={isExportOpen} />
      <AppliedFiltersLabel
        isLoading={isLoading}
        totalCount={totalCount}
        currentPage={currentPage}
        filters={filters}
        resetFilters={resetFilters}
      />
      <div data-test="activity-page-feed" className={styles.container}>
        <div className={styles.content}>
          <Dashboard />
          <Feed isMobile={isMobile} withReversedLoadingAnimation />
        </div>
        {!isMobile && <Filters />}
      </div>
      <FooterActions withChatOffset />
    </div>
  );
};

Container.propTypes = {
  ui: uiShape.isRequired,
  filters: filtersShape.isRequired,
  resetFilters: PropTypes.func.isRequired,
  fetchCards: PropTypes.func.isRequired,
  updateDataItem: PropTypes.func.isRequired,
  className: PropTypes.string,
  updateCard: PropTypes.func.isRequired,
  cards: PropTypes.arrayOf(cardShape),
  setAdvancedSearch: PropTypes.func.isRequired,
  pagination: paginationShape.isRequired,
  advancedSearch: PropTypes.shape({
    lastSearchId: PropTypes.number,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  location: locationShape.isRequired,
};

export default Container;
