import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import IconButton from 'components/Dashboard/common/IconButton';

import styles from './styles.module.scss';

const sizes = {
  small: styles.small,
  large: styles.large,
  medium: styles.medium,
};

const RemoveButton = ({ size, ...props }) => (
  <IconButton
    {...props}
    className={classNames(styles.button, sizes[size])}
    iconClassName={styles.icon}
    iconClass="fas fa-trash-alt"
  >
    <span className={styles.text}>
      Delete
    </span>
  </IconButton>
);

RemoveButton.defaultProps = {
  size: 'large',
};

RemoveButton.propTypes = {
  size: PropTypes.string,
};

export default RemoveButton;
