import { useState } from 'react';

function useLockBanner() {
  const [visible, setVisible] = useState(false);

  const handleClickOutside = () => {
    setVisible(false);
  };

  const handleWrapperClickCapture = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setVisible(true);
  };

  return {
    visible,
    onClickOutside: handleClickOutside,
    onWrapperClickCapture: handleWrapperClickCapture,
  };
}

export default useLockBanner;
