import React from 'react';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CardPlaceholder } from 'components/Grm2/common/Card';

import styles from './styles.module.scss';

function LoadingPlaceholder({
  isSingleCard,
  isMobile,
  showCheckbox,
  className,
  contentClassName,
  cardsLength,
  withReversedAnimation,
}) {
  const isCheckboxPresent = !isMobile && showCheckbox;

  const cardsToDisplay = isSingleCard ? 1 : cardsLength;

  return (
    <div className={styles[`wrapper${cardsToDisplay}`]}>
      {map(Array(cardsToDisplay).fill(0), (_, index) => (
        <CardPlaceholder
          key={`item-${index}`}
          className={classNames(styles.cardPlaceholder, withReversedAnimation && styles.reversed, className)}
          contentClassName={contentClassName}
          isCheckboxPresent={isCheckboxPresent}
        />
      ))}
    </div>
  );
}

LoadingPlaceholder.defaultProps = {
  isSingleCard: false,
  isMobile: false,
  showCheckbox: true,
  cardsLength: 10,
  withReversedAnimation: false,
};

LoadingPlaceholder.propTypes = {
  isSingleCard: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  isMobile: PropTypes.bool,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  cardsLength: PropTypes.number,
  withReversedAnimation: PropTypes.bool,
};

export default LoadingPlaceholder;
