import React from 'react';
import { Provider } from 'react-redux';
import store from 'redux/store';

import NewNoteForNavigationButton from './components/Container';

const NewNoteForNavigation = props => (
  <Provider store={store}>
    <NewNoteForNavigationButton {...props} />
  </Provider>
);

export default NewNoteForNavigation;
