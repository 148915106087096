import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useMediaQuery from 'utils/breakpoints/useMediaQuery';
import Button from 'components/core/Button';
import NewNoteIcon from 'components/Grm2/common/Header/components/NewNoteIcon';

import styles from './styles.module.scss';

function NewNoteButton({ enabled, handleOpen, className, content, withMobileStyle }) {
  const { isMobile } = useMediaQuery();

  if (isMobile && withMobileStyle) {
    return (
      <Button
        theme="light"
        onClick={handleOpen}
        className={styles.newNoteButton}
      >
        <i className="far fa-file-plus" />
      </Button>
    );
  }

  if (enabled) {
    return (
      <Button
        theme="tertiary"
        onClick={handleOpen}
        className={classNames(styles.newNoteTextButton, className)}
      >
        {content}
      </Button>
    );
  }

  return (
    <NewNoteIcon
      color="white"
      onClick={handleOpen}
      className={styles.newNote}
    />
  );
}

NewNoteButton.defaultProps = {
  content: '+ Add Note',
  withMobileStyle: true,
};

NewNoteButton.propTypes = {
  enabled: PropTypes.bool,
  handleOpen: PropTypes.func,
  className: PropTypes.string,
  content: PropTypes.node,
  withMobileStyle: PropTypes.bool,
};

export default NewNoteButton;
