import { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import useModal from 'components/core/Modal/useModal';
import { ACITIVITY_FOR_NAVIGATION_NAMESPACE } from 'components/Grm2/events/constants';
import { getSystemFields } from 'components/Grm2/NewNoteForNavigation/utils';
import { isGrmEnabled } from 'components/Grm2/utils';

function useNewActivityCard({
  setNamespace,
  customFields,
  fetchCustomFields,
  trackButtonClicked,
}) {
  const modal = useModal();

  useEffect(() => {
    setNamespace(ACITIVITY_FOR_NAVIGATION_NAMESPACE);
  }, []);

  const initialValues = getSystemFields();

  const handleNewCardClick = () => {
    trackButtonClicked();

    if (isEmpty(customFields)) {
      fetchCustomFields();
    }

    modal.openModal();
  };

  const isBannerEnabled = !isGrmEnabled;

  return {
    modal,
    initialValues,
    onNewCardClick: handleNewCardClick,
    isBannerEnabled,
  };
}

export default useNewActivityCard;
