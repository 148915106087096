import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import TextareaWithMentions, {
  createFormikHandleFunction,
  createOnAddFunction,
} from 'components/core/TextareaWithMentions';
import Label from 'components/core/Label';
import Asterisk from 'components/core/Asterisk';
import DismissibleAlert from 'components/core/DismissibleAlert';
import { getError } from 'utils/forms';

import Tooltip from './Tooltip';
import styles from './styles.module.scss';

function TextareaField({
  name,
  title,
  testClassName,
  placeholder,
  isRequired,
}) {
  const modal = document.getElementById('modal');

  const createMentions = form => ([
    {
      trigger: '@',
      strategySlug: 'people',
      onAdd: createOnAddFunction('people', form.setFieldValue, form.values.people),
    },
    {
      trigger: '#',
      strategySlug: 'bill',
      onAdd: createOnAddFunction('bills', form.setFieldValue, form.values.bills),
    },
  ]);

  return (
    <Field name={name}>
      {({ field: { onChange, onBlur, ...restProps }, form }) => (
        <div className={styles.container}>
          <div className={styles.field}>
            <Label className={styles.label}>
              {title}
              <Asterisk show={isRequired} />
              <Tooltip content={placeholder} theme="light" />
            </Label>

            <DismissibleAlert
              type="grm_create_mentions_are_introduced"
              className={styles.alert}
            >
              Hold on there, partner! We’ve just introduced “<b>@mentions</b>” and “<b>#billreferences</b>”.
              Just type “<b>@</b>“ followed by the name of a stakeholder legislator, staffer, or colleague
              to automatically append their profile to the note. Likewise, type “<b>#</b>” followed by a bill
              name (e.g., “#HR1”) to auto-append a link to that bill!
            </DismissibleAlert>

            <div className={testClassName}>
              <TextareaWithMentions
                {...restProps}
                onChange={createFormikHandleFunction(onChange, name)}
                onBlur={createFormikHandleFunction(onBlur, name)}
                mentionsData={createMentions(form)}
                allowSpaceInQuery
                suggestionsPortalHost={modal && modal.parentNode}
                errorMessage={getError(name, form)}
              />
            </div>
          </div>
        </div>
      )}
    </Field>
  );
}

TextareaField.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  testClassName: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
};

export default TextareaField;
