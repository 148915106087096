import React from 'react';

import {
  InputField,
  TextareaField,
  AttachmentField,
} from '../Fields';

function TopFields() {
  return (
    <>
      <InputField
        title="Title"
        name="title"
        isRequired
        testClassName="test__new-card-title"
      />
      <TextareaField
        title="Description"
        name="description"
        isRequired
        testClassName="test__new-card-description"
      />
      <AttachmentField
        name="uploads"
        title="Attachments"
        testClassName="test__new-card-attachments"
      />
    </>
  );
}

export default TopFields;
