import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import styles from './styles.module.scss';
import { animationDuration } from './settings.module.scss';

function CardTransition({ cardId, children, timeout, ...restProps }) {
  return (
    <CSSTransition
      key={cardId}
      timeout={timeout}
      classNames={{
        exit: styles.exit,
        exitActive: styles.exitActive,
      }}
      {...restProps}
    >
      {children}
    </CSSTransition>
  );
}

CardTransition.defaultProps = {
  timeout: parseInt(animationDuration, 10),
};

CardTransition.propTypes = {
  timeout: PropTypes.number,
  cardId: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default CardTransition;
