import PropTypes from 'prop-types';

export const committeesShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  state_and_chamber: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

export const billsShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

export const documentsShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

export const interactionTypesShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

export const peopleShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  party_slug: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

export const regulationsShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

export const assigneeShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  profile_img_url: PropTypes.string,
  value: PropTypes.number,
});
