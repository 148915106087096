import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import TagInput from 'components/core/TagInput';

import { assigneeShape } from '../../shapes';

import styles from '../styles.module.scss';

function Assignee({ value, onChange, defaultOptions }) {
  const customSearchOption = isEmpty(value) ? defaultOptions : [];

  const handleChange = (newValue) => {
    onChange('assignee', newValue);
  };

  const strategyInputs = {
    include_disabled: true,
  };

  return (
    <TagInput
      strategy="user"
      onChange={handleChange}
      overrideValue={value}
      strategyInputs={strategyInputs}
      shouldOverrideValue
      withRichOption
      autoload={customSearchOption}
      groupClassName={classNames(
        'test__filter-assignee-input',
        styles.item,
      )}
    />
  );
}

Assignee.defaultProps = {
  value: [],
  defaultOptions: [],
};

Assignee.propTypes = {
  value: PropTypes.arrayOf(assigneeShape),
  defaultOptions: PropTypes.arrayOf(assigneeShape),
  onChange: PropTypes.func.isRequired,
};

export default Assignee;
