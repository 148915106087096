import { useState } from 'react';

import { STATUS_TYPES } from 'components/Grm2/constants';

function useTasksContent() {
  const [status, setStatus] = useState(STATUS_TYPES.open.value);

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
  };

  const filterCardsBy = card => (
    card.task.status === status
  );

  return {
    status,
    onStatusChange: handleStatusChange,
    filterCardsBy,
  };
}

export default useTasksContent;
