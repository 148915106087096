import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { includes } from 'lodash';
import classNames from 'classnames';

import ButtonGroup from 'components/core/ButtonGroup';
import Link from 'components/core/Link';
import { isGrmEnabled } from 'components/Grm2/utils';
import LockBanner from 'components/Grm2/common/LockBanner';

import styles from './styles.module.scss';

function ToggleButton({ boardUrl, listUrl, match }) {
  const isBoard = includes(match.url, boardUrl);
  const commonButtonProps = {
    size: 'medium',
    theme: 'buttonDefault',
    iconClassName: styles.icon,
  };

  return (
    <div className={styles.wrapper}>
      <LockBanner enabled={!isGrmEnabled} wrapperClassName={styles.lockBannerWrapper}>
        <ButtonGroup>
          <Link
            {...commonButtonProps}
            className={classNames(styles.button, !isBoard && styles.isDisabled)}
            disabled={!isBoard}
            isRouterLink
            to={listUrl}
          >
            <span className={styles.icon}>
              <i className="fas fa-th-list" />
            </span>
          </Link>
          <Link
            {...commonButtonProps}
            className={classNames(styles.button, isBoard && styles.isDisabled)}
            disabled={isBoard}
            isRouterLink
            to={boardUrl}
          >
            <span className={styles.icon}>
              <i className="fas fa-columns" />
            </span>
          </Link>
        </ButtonGroup>
      </LockBanner>
    </div>
  );
}

ToggleButton.propTypes = {
  boardUrl: PropTypes.string.isRequired,
  listUrl: PropTypes.string.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(ToggleButton);
