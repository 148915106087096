import React from 'react';
import PropTypes from 'prop-types';
import { slice, isEmpty, map, size } from 'lodash';

import {
  Column as KanbanColumn,
  DraggableCard,
  Header,
  Footer,
  EmptyPlaceholder,
} from 'components/core/KanbanBoard';
import { cardShape } from 'components/Grm2/shapes';
import LoadingPlaceholder from 'components/Grm2/common/Feed/components/LoadingPlaceholder';

import Task from '../Task';
import styles from './styles.module.scss';

function Column({
  status,
  isLoading,
  isDragDisabled,
  headerProps,
  footerProps,
  emptyPlaceholderContent,
  placeholderCompareFunction,
  displayLimit,
  showFooterOnLimitExceed,
  tasks,
}) {
  const displayTasks = displayLimit ? slice(tasks, 0, displayLimit) : tasks;
  const areTasksLimited = size(tasks) > size(displayTasks);

  const renderHeader = ({ title, count, ...props }) => (
    <Header count={isLoading ? '' : count} {...props}>
      {title}
    </Header>
  );

  const renderEmptyPlaceholder = content => <EmptyPlaceholder>{content}</EmptyPlaceholder>;

  const renderFooter = () => {
    const shouldRenderFooter = (
      !isLoading && !isEmpty(footerProps) && (!showFooterOnLimitExceed || areTasksLimited)
    );

    if (!shouldRenderFooter) {
      return null;
    }

    const { content, ...props } = footerProps;

    return <Footer {...props}>{content}</Footer>;
  };

  const renderTasks = () => {
    if (isLoading) {
      return (
        <LoadingPlaceholder
          showCheckbox={false}
          cardsLength={3}
          contentClassName={styles.contentClassName}
          className={styles.cardPlaceholder}
        />
      );
    }

    return map(displayTasks, (task, index) => (
      <DraggableCard
        id={task.id}
        key={task.id}
        index={index}
        isDragDisabled={isDragDisabled || task.disabled}
      >
        <Task task={task} />
      </DraggableCard>
    ));
  };

  return (
    <KanbanColumn
      header={renderHeader(headerProps)}
      footer={renderFooter()}
      id={status}
      className={styles.column}
      contentClassName={styles.content}
      isPlaceholderBeforeCard={placeholderCompareFunction}
      emptyPlaceholder={renderEmptyPlaceholder(emptyPlaceholderContent)}
      isDropDisabled={isDragDisabled}
    >
      {renderTasks()}
    </KanbanColumn>
  );
}

Column.defaultProps = {
  displayLimit: 0,
  isLoading: false,
};

Column.propTypes = {
  status: PropTypes.string.isRequired,
  headerProps: PropTypes.shape({
    count: PropTypes.number.isRequired,
    onAddClick: PropTypes.func.isRequired,
    counterTheme: PropTypes.string,
    title: PropTypes.string.isRequired,
  }).isRequired,
  footerProps: PropTypes.shape({
    href: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired,
  }),
  isLoading: PropTypes.bool,
  isDragDisabled: PropTypes.bool,
  emptyPlaceholderContent: PropTypes.node.isRequired,
  placeholderCompareFunction: PropTypes.func.isRequired,
  displayLimit: PropTypes.number,
  showFooterOnLimitExceed: PropTypes.bool,
  tasks: PropTypes.arrayOf(cardShape).isRequired,
};

export default Column;
