import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { isEqualWith, isEmpty } from 'lodash';

import Loader from 'components/core/Loader';
import useAccordionState from 'components/core/Accordion/utils/useAccordionState';

import { newNoteValuesShape } from '../../../common/shapes';
import { getInitialAccordionState, handleCustomFieldsErrors } from '../../../common/accordion';
import { compareFieldValues, checkNotEmpty } from '../../../utils';
import Modal from '../../../common/Modal';
import Form from '../Form';
import getValidationSchema from './validationSchema';
import styles from './styles.module.scss';

function Container({
  onClose,
  isLoading,
  initialValues,
  // Formik props
  handleSubmit,
  isSubmitting,
  values,
  setFieldTouched,
  errors,
}) {
  const hasChanged = !isEqualWith(values, initialValues, compareFieldValues);
  const notEmpty = checkNotEmpty(values);
  const { uploads } = values;
  const accordionState = useAccordionState(getInitialAccordionState(initialValues));

  const filesUploading = uploads.filter(({ file_upload_id }) => !file_upload_id);

  const onSubmit = () => {
    handleSubmit();
    handleCustomFieldsErrors({ errors, accordionState, setFieldTouched });
  };

  const renderLoader = () => (
    <div className={styles.loader}>
      <Loader />
    </div>
  );

  const renderModalContent = () => {
    if (isLoading) {
      return renderLoader();
    }

    return (
      <Form
        hasChanged={hasChanged}
        initialValues={initialValues}
        setFieldTouched={setFieldTouched}
        accordionState={accordionState}
      />
    );
  };

  return (
    <Modal
      onClose={onClose}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      areFilesUploading={!isEmpty(filesUploading)}
      hasChanged={hasChanged && notEmpty}
      title="New note"
    >
      {renderModalContent()}
    </Modal>
  );
}

Container.propTypes = {
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  initialValues: newNoteValuesShape,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  values: newNoteValuesShape.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    description: PropTypes.string,
    title: PropTypes.string,
    customFieldsAnswers: PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
    }),
  }).isRequired,
};

export default withFormik({
  validationSchema: props => getValidationSchema(props.customFields),
  enableReinitialize: true,
  displayName: 'NewNoteForm',
  mapPropsToValues: ({ initialValues }) => initialValues,
  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values)
      .then(() => props.onClose())
      .finally(() => setSubmitting(false));
  },
})(Container);
