import { isEmpty, keys } from 'lodash';

import { checkIsOpen } from './Form/utils';
import { CUSTOM_FIELD_NAME_PREFIX } from './constants';

export const SYSTEM_FIELDS_KEY = 'systemFields';
export const CUSTOM_FIELDS_KEY = 'customFieldsAnswers';

export const getInitialAccordionState = (initialValues) => {
  const {
    isOpenSystemFields,
    isOpenCustomFields,
  } = checkIsOpen(initialValues);

  return {
    [SYSTEM_FIELDS_KEY]: isOpenSystemFields,
    [CUSTOM_FIELDS_KEY]: isOpenCustomFields,
  };
};

export const handleCustomFieldsErrors = ({ errors, accordionState, setFieldTouched }) => {
  if (!isEmpty(errors.customFieldsAnswers)) {
    const touchField = key => setFieldTouched(`${CUSTOM_FIELD_NAME_PREFIX}.${key}`);
    keys(errors.customFieldsAnswers).forEach(touchField);

    const { isOpen, handleToggle } = accordionState;
    !isOpen[CUSTOM_FIELDS_KEY] && handleToggle(CUSTOM_FIELDS_KEY);
  }
};
