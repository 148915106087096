import {
  isEqual,
  isArray,
  isPlainObject,
  toString,
  isEmpty,
  compact,
  omitBy,
} from 'lodash';

// After removing a value for CF the customFieldsAnswers will look like:
// customFieldsAnswers: {
//   igrh31so: [null],
//   wgrh3abc: [''],
//   wgrh3abc: undefined,
// },
// The function below removes the empty values for CF

const removeCustomFieldsEmptyValues = (customFieldValues) => {
  const isValueEmpty = (value) => {
    if (!isArray(value)) {
      return true;
    }

    return isEmpty(compact(value));
  };

  return omitBy(customFieldValues, isValueEmpty);
};

// Right now we have differences between initial and current values e.g.,
// initial: [ { value: 'A000367' } ]
// current: [ { record_id: 'A000367' } ]
// The function will check arrays and transform their objects to primitives.

export const compareFieldValues = (currentValue, initialValue, field) => {
  if (field === 'eventDate') { // can be null or {}
    const cleanEventDateA = isEmpty(currentValue) ? null : currentValue;
    const cleanEventDateB = isEmpty(initialValue) ? null : initialValue;

    return isEqual(cleanEventDateA, cleanEventDateB);
  }

  if (field === 'customFieldsAnswers') {
    const cleanCustomFieldsA = removeCustomFieldsEmptyValues(currentValue);
    const cleanCustomFieldsB = removeCustomFieldsEmptyValues(initialValue);

    return isEqual(cleanCustomFieldsA, cleanCustomFieldsB);
  }

  if (!isArray(currentValue) || !isArray(initialValue)) {
    return undefined;
  }

  const mapArrayValues = (values) => {
    if (values.some(isPlainObject)) {
      return values.map(({ value, record_id, id }) => value || record_id || id);
    }

    return values;
  };

  const idA = mapArrayValues(currentValue);
  const idB = mapArrayValues(initialValue);

  return isEqual(idA.map(toString), idB.map(toString));
};

export const checkNotEmpty = (values) => {
  const exceptions = {
    id: true,
  };
  const clearValues = {
    ...values,
    customFieldsAnswers: removeCustomFieldsEmptyValues(values.customFieldsAnswers),
  };

  return Object.keys(clearValues)
    .map(fieldName => !exceptions[fieldName] && !isEmpty(clearValues[fieldName]))
    .includes(true);
};
