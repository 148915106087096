import React from 'react';
import PropTypes from 'prop-types';

import withLazy from 'utils/hoc/withLazy';
import { lazyRetry } from 'utils/lazyLoading';
import reduxConnect from 'components/Grm2/reduxConnect';
import { customFieldShape } from 'components/Grm2/shapes';
import LockBanner from 'components/Grm2/common/LockBanner';
import useNewActivityCard from 'components/Grm2/useNewActivityCard';

import NewNoteButton from '../NewNoteButton';

const NewNote = withLazy(
  lazyRetry(() => import('components/Grm2/common/NewCard/Note'))
);

function NewNoteForNavigationButton({
  fetchCustomFields,
  customFields,
  isAddNoteEnabled,
  trackButtonClicked,
  setNamespace,
  className,
  content,
  withMobileStyle,
  onSuccess,
}) {
  const { modal, onNewCardClick, initialValues, isBannerEnabled } = useNewActivityCard({
    customFields,
    fetchCustomFields,
    trackButtonClicked,
    setNamespace,
  });

  return (
    <>
      <LockBanner enabled={isBannerEnabled}>
        <NewNoteButton
          enabled={isAddNoteEnabled}
          handleOpen={onNewCardClick}
          className={className}
          content={content}
          withMobileStyle={withMobileStyle}
        />
      </LockBanner>

      {modal.isOpenModal && (
        <NewNote
          initialValues={initialValues}
          onClose={modal.closeModal}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
}

NewNoteForNavigationButton.defaultProps = {
  trackButtonClicked: () => {},
};

NewNoteForNavigationButton.propTypes = {
  fetchCustomFields: PropTypes.func.isRequired,
  customFields: PropTypes.arrayOf(customFieldShape).isRequired,
  isAddNoteEnabled: PropTypes.bool,
  trackButtonClicked: PropTypes.func,
  setNamespace: PropTypes.func.isRequired,
  className: PropTypes.string,
  content: PropTypes.node,
  withMobileStyle: PropTypes.bool,
  onSuccess: PropTypes.func,
};

export default reduxConnect(NewNoteForNavigationButton);
