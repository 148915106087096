import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import LinkPreview, { Placeholder } from 'components/core/LinkPreview';

import styles from './styles.module.scss';

const Container = ({
  size,
  isLoading,
  url,
  ...rest
}) => {
  if (isLoading) {
    return (
      <Placeholder />
    );
  }

  if (isEmpty(url)) return null;

  return (
    <LinkPreview
      {...rest}
      url={url}
      className={styles.linkPreview}
      size={size}
    />
  );
};

Container.defaultProps = {
  isLoading: false,
};

Container.propTypes = {
  isLoading: PropTypes.bool,
  url: PropTypes.string,
  size: PropTypes.string,
};

export default Container;
