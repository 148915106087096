import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter, Switch } from 'react-router-dom';

import store from 'redux/store';
import { lazyRetry } from 'utils/lazyLoading';

const ActivityFeed = lazyRetry(() => import('./ActivityFeed'));
const SingleCardView = lazyRetry(() => import('./SingleCardView'));

const Grm2 = (props) => {
  const renderGRM = routerProps => (
    <ActivityFeed {...props} {...routerProps} />
  );

  const renderSingleCard = () => (
    <SingleCardView {...props} />
  );

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense>
          <Switch>
            <Route exact path="/grm" component={renderGRM} />
            <Route exact path="/grm/activities/:id" component={renderSingleCard} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </Provider>
  );
};

export default Grm2;

export { default as TasksButton } from './TasksButton';
export { default as NewNoteForNavigation } from './NewNoteForNavigation';
export { default as NewTaskForNavigation } from './NewTaskForNavigation';
export { default as ActivityButton } from './ActivityButton';
