import React from 'react';
import PropTypes from 'prop-types';

import ButtonTagManager from 'components/core/ButtonTagManager';
import { tagShape } from 'components/Grm2/shapes';

import useApplyTags from './useApplyTags';

function ApplyTagsButton({ cardIds, onSave, theme, buttonClassName, tags, isMultipleItems, cardType }) {
  const {
    handleSave,
    isLoading,
  } = useApplyTags({ cardIds, onSave, cardType, isMultipleItems });

  const defaultTags = isMultipleItems ? [] : tags;

  return (
    <ButtonTagManager
      autoload
      theme={theme}
      buttonClassName={buttonClassName}
      onSaveTags={handleSave}
      isMultipleItems={isMultipleItems}
      isLoading={isLoading}
      tags={defaultTags}
    />
  );
}

ApplyTagsButton.defaultProps = {
  theme: 'light',
  tags: [],
  isMultipleItems: false,
};

ApplyTagsButton.propTypes = {
  cardIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSave: PropTypes.func.isRequired,
  theme: PropTypes.string,
  isMultipleItems: PropTypes.bool,
  tags: PropTypes.arrayOf(tagShape),
  buttonClassName: PropTypes.string,
  cardType: PropTypes.string,
};

export default ApplyTagsButton;
