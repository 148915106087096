import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ButtonGroup from 'components/core/ButtonGroup';
import Button from 'components/core/Button';
import { STATUS_TYPES } from 'components/Grm2/constants';

import useFilters from './useFilters';
import styles from './styles.module.scss';

function Filters({ status, onStatusChange }) {
  const {
    onOpenClick,
    isOpenActive,
    onInProgressClick,
    isInProgressActive,
  } = useFilters({ status, onStatusChange });

  const getButtonStyle = isActive => (
    classNames(styles.button, isActive && styles.isActive)
  );

  return (
    <ButtonGroup className={styles.buttonGroup}>
      <Button
        size="small"
        theme="default"
        className={getButtonStyle(isOpenActive)}
        onClick={onOpenClick}
      >
        OPEN
      </Button>
      <Button
        size="small"
        theme="default"
        className={getButtonStyle(isInProgressActive)}
        onClick={onInProgressClick}
      >
        IN-PROGRESS
      </Button>
    </ButtonGroup>
  );
}

Filters.propTypes = {
  status: PropTypes.oneOf(Object.keys(STATUS_TYPES)),
  onStatusChange: PropTypes.func.isRequired,
};

export default Filters;
