export const CARD_TYPES = {
  hill_day_attendance: {
    label: 'Hill Day attendance',
    color: 'green',
    iconName: 'landmark',
  },
  hill_day_meeting_attendance: {
    label: 'Hill Day meeting attendance',
    color: 'green',
    iconName: 'calendar-week',
  },
  hill_day_note: {
    label: 'Hill Day note',
    color: 'green',
    iconName: 'sticky-note',
  },
  grassroots_legislation: {
    label: 'Grassroots legislation',
    color: 'violet',
    iconName: 'map-marked',
  },
  note: {
    label: 'Note',
    color: 'blue',
    iconName: 'sticky-note',
  },
  task: {
    label: 'Task',
    color: 'blue',
    iconName: 'thumbtack-solid',
  },
};

export const PRIORITY_TYPES = {
  highest: {
    label: 'Highest',
    icon: 'fas fa-chevron-double-up',
    color: 'red',
  },
  high: {
    label: 'High',
    icon: 'fas fa-arrow-alt-up',
    color: 'red',
  },
  no_priority: {
    label: 'No priority',
    icon: 'fas fa-times-circle',
    color: 'black',
  },
  low: {
    label: 'Low',
    icon: 'fas fa-arrow-alt-down',
    color: 'green',
  },
  lowest: {
    label: 'Lowest',
    icon: 'fas fa-chevron-double-down',
    color: 'green',
  },
};

export const PRIORITY_SCORES = {
  lowest: 1,
  low: 2,
  no_priority: 3,
  high: 4,
  highest: 5,
};

export const CARD_SIZES = {
  medium: 'medium',
  large: 'large',
  small: 'small',
};

export const DEFAULT_STATUS = 'open';

export const NO_PRIORITY = 'no_priority';

export const OPEN = 'open';
export const IN_PROGRESS = 'inProgress';
export const CLOSED = 'closed';

export const STATUS_TYPES = {
  [OPEN]: {
    label: 'Open',
    icon: 'fas fa-exclamation-circle',
    color: 'red',
    value: OPEN,
  },
  [IN_PROGRESS]: {
    label: 'In-progress',
    icon: 'fas fa-tasks',
    color: 'blue',
    value: IN_PROGRESS,
  },
  [CLOSED]: {
    label: 'Closed',
    icon: 'fas fa-check-circle',
    color: 'green',
    value: CLOSED,
  },
};

export const ICONS = {
  newNote: {
    iconName: 'sticky-note-plus',
  },
  newTask: {
    iconName: 'thumbtack-solid',
  },
};

export const NOTE_CARD_TYPE = 'note';

export const TASK_CARD_TYPE = 'task';

export const HILL_DAY_NOTE_CARD_TYPE = 'hill_day_note';

export const TAG_STRATEGY_SLUG = 'tag';

// NOTE: keep updated with INTERACTION_TYPES from app/models/grm_v2/interaction_type.rb
export const INTERACTION_TYPES = [
  { value: 'call', label: 'Call' },
  { value: 'client_research', label: 'Client research' },
  { value: 'fly_in', label: 'Hill Day' },
  { value: 'fundraiser', label: 'Fundraiser' },
  { value: 'hill_meeting', label: 'Hill meeting' },
  { value: 'meeting', label: 'Meeting' },
  { value: 'team_internal', label: 'Team internal' },
  { value: 'coalition_meeting', label: 'Coalition meeting' },
  { value: 'dear_colleague_letter', label: 'Dear colleague letter' },
  { value: 'email', label: 'Email' },
  { value: 'organization_sponsored_event_participation', label: 'Organization sponsored event participation' },
  { value: 'hearing', label: 'Hearing' },
  { value: 'pac_event', label: 'PAC event' },
  { value: 'company_site_visit', label: 'Company site visit' },
  { value: 'trade_association_meeting' , label: 'Trade association meeting' },
  { value: 'other', label: 'Other' },
];
