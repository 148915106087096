import {
  get,
  isEqual,
  every,
  find,
  isFunction,
  map,
  filter,
  includes,
} from 'lodash';

import { getCurrentUser } from 'utils/currentUser';
import { getDate } from 'utils/date';
import { PRIORITY_VALUES } from 'utils/constants/priority';
import { findPage } from 'components/Grm2/NewNoteForNavigation/utils';

import {
  NOTE_CARD_TYPE,
  TASK_CARD_TYPE,
  HILL_DAY_NOTE_CARD_TYPE,
} from './constants';

export function createSrcForIcon(iconName, color) {
  return `/images/${iconName}-${color}.svg`;
}

export function getCurrentUserTag() {
  const { fullNameWithEmail, userId } = getCurrentUser();

  return {
    label: fullNameWithEmail,
    value: userId,
  };
}

export function isCardTypeNote(cardType) {
  return cardType === NOTE_CARD_TYPE;
}

export function isCardTypeHillDayNote(cardType) {
  return cardType === HILL_DAY_NOTE_CARD_TYPE;
}

export function isCardTypeTask(cardType) {
  return cardType === TASK_CARD_TYPE;
}

export function isCardEditable(cardType, user) {
  return (isCardTypeNote(cardType) || isCardTypeTask(cardType)) && (getCurrentUser().userId === user.id);
}

export function isCardRemovable({ user = {}, cardType }) {
  return (isCardTypeNote(cardType) || isCardTypeTask(cardType) || isCardTypeHillDayNote(cardType))
   && (getCurrentUser().userId === user.id);
}

export function updateTaskCard({ id, ...rest }) {
  const url = `/api_web/grm_v2/tasks/${id}`;

  const data = {
    task: rest,
  };

  const ajax = $.ajax({
    method: 'PUT',
    url,
    data,
  });

  return Promise.resolve(ajax);
}

export function taskCompareFunction(firstTask, secondTask) {
  const { fullCreationDate: firstDate, task: { priority: firstPriority } } = firstTask || {};
  const { fullCreationDate: secondDate, task: { priority: secondPriority } } = secondTask || {};

  if (firstPriority === secondPriority) {
    return getDate(secondDate) - getDate(firstDate);
  }

  return PRIORITY_VALUES[secondPriority] - PRIORITY_VALUES[firstPriority];
}

export const showGrmTasks = true;

export const { isGrmEnabled } = gon || {};

export function isCardPresent(cards, uiCard) {
  return cards.some(({ id }) => id === uiCard.id);
}

export function isUserTask(uiCard) {
  const { userId } = getCurrentUser();

  if (!isCardTypeTask(uiCard.cardType)) {
    return false;
  }

  return get(uiCard, 'task.assignee.id') === userId;
}

export function mergeCards({ reduxCards, uiCards }) {
  return reduxCards.map((_, index) => ({
    reduxCard: reduxCards[index],
    uiCard: uiCards[index],
  }));
}

export function isAlreadyUpdated(uiCards, uiCard) {
  const card = uiCards.find(({ id }) => id === uiCard.id);

  return isEqual(card, uiCard);
}

export function isLinkedWithThePage(reduxCard) {
  const { page, id } = findPage();

  return get(reduxCard, 'card_links', [])
    .some(({ record_type, record_id }) => record_type === page.recordType && record_id === id);
}

export const isOnlyTasksSelected = (selectedCardIds, cards) => every(
  selectedCardIds,
  (id) => {
    const { cardType } = find(cards, { id }) || {};

    return cardType === TASK_CARD_TYPE;
  }
);

export function sortCardsByFunction(cards = [], compareFunction) {
  if (isFunction(compareFunction)) {
    return cards.sort(compareFunction);
  }

  return cards;
}

export const getCardsTaskIds = cards => map(cards, ({ task }) => task.id);

export const getCardsByIds = (cards, ids) => filter(cards, ({ id }) => includes(ids, id));

export const getEnabledCards = cards => filter(cards, ({ disabled }) => !disabled);
