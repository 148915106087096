import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import classNames from 'classnames';

import styles from './styles.module.scss';

const CustomSelectOption = ({
  isFocused,
  data,
  ...restProps
}) => {
  const { Option } = components;

  return (
    <Option
      {...restProps}
      isFocused={isFocused}
      data={data}
      className={classNames(isFocused && styles.selectOption)}
    >
      <i className={classNames(styles.selectOptionIcon, data.icon, styles[data.value])} />
      {data.label}
    </Option>
  );
};

CustomSelectOption.propTypes = {
  data: PropTypes.shape({
    icon: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  isFocused: PropTypes.bool,
};

export default CustomSelectOption;
