import React from 'react';
import PropTypes from 'prop-types';

import PageHeader, { BackToHeaderBar } from 'components/core/PageHeader';
import titles from 'components/Grm2/titles';

import SearchHeader from './components/SearchHeader';
import styles from './styles.module.scss';

function Header({ haveActions, isExportOpen }) {
  const pageHeaderProps = {
    applyExtraMargin: false,
    headerClassName: styles.pageHeader,
    titleClassName: styles.subProductTitle,
    ...titles.grm,
  };

  return (
    <div className={styles.header}>
      <div className={styles.container}>
        <PageHeader {...pageHeaderProps} />
        {haveActions && <SearchHeader isExportOpen={isExportOpen} />}
      </div>
      {!haveActions && <BackToHeaderBar to="/grm" title="Back to Activity" />}
    </div>
  );
}

Header.defaultProps = {
  haveActions: true,
};

Header.propTypes = {
  haveActions: PropTypes.bool,
  isExportOpen: PropTypes.bool.isRequired,
};

export default Header;
export { default as TasksHeader } from './components/TasksHeader';
