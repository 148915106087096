import moment from 'moment';
import { map, find, times, isEmpty } from 'lodash';

import { CALENDAR_DATE_FORMAT } from 'utils/constants/date';

const barTransformer = (data = [], urlMapper) => ({
  labels: map(data, ({ label }) => label),
  values: map(data, ({ count }) => count),
  isEmpty: isEmpty(data),
  urls: urlMapper ? map(data, urlMapper) : undefined,
});

const NUMBER_OF_MONTHS = 14;

const makeLineDates = () => {
  const firstMonth = moment().subtract(NUMBER_OF_MONTHS, 'months');

  return map(times(NUMBER_OF_MONTHS, null), () => (
    firstMonth.add(1, 'months').format(CALENDAR_DATE_FORMAT)
  ));
};

export const getActivityOverTimeValue = (date, data) => {
  const month = find(data, ({ date: dataDate }) => moment(dataDate).isSame(date, 'month')) || { count: 0 };

  return month.count;
};

const transformActivityOverTime = (data) => {
  const dates = makeLineDates();

  return {
    labels: dates,
    values: dates.map(date => getActivityOverTimeValue(date, data)),
    isEmpty: false,
  };
};

const billUrl = ({ type, id }) => {
  const baseUrl = {
    federal_bill: '/federal/bills',
    state_bill: '/state/bills',
  }[type];

  if (!baseUrl) {
    return null;
  }

  return `${baseUrl}/${id}`;
};

const regulationUrl = ({ type, id }) => {
  const baseUrl = {
    state_regulation: '/state/regulations',
  }[type];

  if (!baseUrl) {
    return null;
  }

  return `${baseUrl}/${id}`;
};

const legislatorUrl = ({ type, id }) => {
  const baseUrl = {
    federal_legislator: '/federal/legislators',
    state_legislator: '/state/legislators',
  }[type];

  if (!baseUrl) {
    return null;
  }

  return `${baseUrl}/${id}`;
};

const stafferUrl = ({ type, id }) => {
  const baseUrl = {
    federal_staffer: '/federal/congressional_staffers',
  }[type];

  if (!baseUrl) {
    return null;
  }

  return `${baseUrl}/${id}`;
};

const committeeUrl = ({ type, id }) => {
  const baseUrl = {
    federal_committee: '/federal/committees',
    state_committee: '/state/committees',
  }[type];

  if (!baseUrl) {
    return null;
  }

  return `${baseUrl}/${id}`;
};

const transformMostLobbiedBills = data => barTransformer(data, billUrl);
const transformMostLobbiedRegulations = data => barTransformer(data, regulationUrl);
const transformMostActiveColleagues = data => barTransformer(data);
const transformMostEngagedLegislators = data => barTransformer(data, legislatorUrl);
const transformMostEngagedStaffers = data => barTransformer(data, stafferUrl);
const transformMostReferencedCommittees = data => barTransformer(data, committeeUrl);

const getActivityValue = (activityType, data) => {
  const activity = data.find(({ type }) => type === activityType) || { count: 0 };

  return activity.count;
};

export const transformActivityTypeBreakdown = (data) => {
  const activities = {
    hill_day_attendance: 'HD Attendance',
    hill_day_meeting_attendance: 'HD Meetings',
    grassroots_legislation: 'GR Legislation',
    hill_day_note: 'HD Notes',
    note: 'Notes',
    task: 'Tasks',
  };

  return {
    labels: Object.values(activities),
    values: Object.keys(activities).map(activityType => getActivityValue(activityType, data)),
    isEmpty: data.reduce((acc, curr) => acc + curr.count, 0) === 0,
  };
};

const transformMostUsedTags = data => ({
  isEmpty: isEmpty(data),
  data: data.map(({ name, count }) => ({
    text: name,
    value: count,
  })),
});

export const transformAnalyticsToFE = data => ({
  activityOverTime: transformActivityOverTime(data.activity_over_time),
  mostLobbiedBills: transformMostLobbiedBills(data.top_linked_bills),
  mostLobbiedRegulations: transformMostLobbiedRegulations(data.top_linked_regulations),
  activityTypeBreakdown: transformActivityTypeBreakdown(data.top_activity_types),
  mostActiveColleagues: transformMostActiveColleagues(data.top_active_colleagues),
  mostEngagedLegislators: transformMostEngagedLegislators(data.top_linked_legislators),
  mostEngagedStaffers: transformMostEngagedStaffers(data.top_linked_staffers),
  mostReferencedCommittees: transformMostReferencedCommittees(data.top_linked_committees),
  mostUsedTags: transformMostUsedTags(data.top_tags),
});
