import React from 'react';
import PropTypes from 'prop-types';

import { createSrcForIcon } from 'components/Grm2/utils';

import styles from './styles.module.scss';

function Item({ label, color, iconName, isChecked }) {
  const iconColor = isChecked ? color : 'grey';

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <img alt={iconName} src={createSrcForIcon(iconName, iconColor)} />
      </div>
      <div className={styles.label}>{label}</div>
    </div>
  );
}

Item.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
};

export default Item;
