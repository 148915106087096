import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { CARD_SIZES } from 'components/Grm2/constants';
import { cardSizeShape } from 'components/Grm2/common/Card/shapes';

import styles from './styles.module.scss';

const sizeThemes = {
  large: styles.large,
  small: styles.small,
  medium: styles.medium,
};

function CreatedBy({ createdBy, size }) {
  if (isEmpty(createdBy)) {
    return null;
  }

  return (
    <div className={classNames(styles.createdBy, sizeThemes[size])}>
      <p className={classNames(styles.label, sizeThemes[size])}>Created by: </p>
      {createdBy}
    </div>
  );
}

CreatedBy.propTypes = {
  createdBy: PropTypes.string,
  size: cardSizeShape,
};

CreatedBy.defaultProps = {
  size: CARD_SIZES.large,
};

export default CreatedBy;
