import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';
import reduxConnect from 'components/Grm2/reduxConnect';

import styles from './styles.module.scss';

function RefreshButton({
  hasNewCard,
  hasEditedCard,
  fetchCards,
  filtersCount,
  page,
  hasKeyword,
}) {
  const ref = useRef();
  const isFiltered = filtersCount !== 0 || hasKeyword;
  const hasChanges = hasNewCard || hasEditedCard;
  const mayHaveInconsistence = (hasChanges && isFiltered) || (hasNewCard && page !== 1);

  const scrollToButton = () => {
    const buttonRef = ref.current;
    buttonRef && setTimeout(() => {
      buttonRef.scrollIntoView(false);
    }, 0);
  };

  useEffect(() => {
    mayHaveInconsistence && scrollToButton();
  }, [mayHaveInconsistence]);

  if (!mayHaveInconsistence) {
    return null;
  }

  return (
    <Button
      innerRef={ref}
      theme="light"
      size="small"
      className={styles.button}
      customIcon={<i className={classNames(styles.icon, 'far fa-redo-alt')} />}
      onClick={fetchCards}
    >
      Refresh results
    </Button>
  );
}

RefreshButton.defaultProps = {
  page: 1,
  hasKeyword: false,
};

RefreshButton.propTypes = {
  hasNewCard: PropTypes.bool.isRequired,
  hasEditedCard: PropTypes.bool.isRequired,
  fetchCards: PropTypes.func.isRequired,
  filtersCount: PropTypes.number.isRequired,
  page: PropTypes.number,
  hasKeyword: PropTypes.bool,
};

export default reduxConnect(RefreshButton);
export { RefreshButton as DisconnectedRefreshButton };
