import PropTypes from 'prop-types';

import { tagShape } from 'components/Grm2/shapes';
import { uploadShape } from 'components/Grm2/common/Card/shapes';

const tagInputPropTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
};

const cardValuesPropTypes = {
  bills: PropTypes.arrayOf(PropTypes.shape({ ...tagInputPropTypes })),
  committees: PropTypes.arrayOf(PropTypes.shape({
    ...tagInputPropTypes,
    state_and_chamber: PropTypes.string,
  })),
  customFieldsAnswers: PropTypes.objectOf(PropTypes.array),
  description: PropTypes.string,
  eventDate: PropTypes.string,
  hillDayActivity: PropTypes.arrayOf(PropTypes.shape({ ...tagInputPropTypes })),
  interactionType: PropTypes.string,
  people: PropTypes.arrayOf(PropTypes.shape({
    ...tagInputPropTypes,
    party_slug: PropTypes.string,
  })),
  regulations: PropTypes.arrayOf(PropTypes.shape({ ...tagInputPropTypes })),
  tags: PropTypes.arrayOf(tagShape),
  title: PropTypes.string,
  uploads: PropTypes.arrayOf(uploadShape),
};

export const selectOptionShape = PropTypes.shape({
  value: PropTypes.string,
  label: PropTypes.string,
});

export const newNoteValuesShape = PropTypes.shape({
  ...cardValuesPropTypes,
});

export const taskShape = PropTypes.shape({
  // eslint-disable-next-line
  time: PropTypes.object,
  dueDate: PropTypes.string,
  assignee: tagInputPropTypes,
  priority: PropTypes.string,
  status: PropTypes.string,
});

export const newTaskValuesShape = PropTypes.shape({
  ...cardValuesPropTypes,
  task: taskShape,
});

export const accordionStateShape = PropTypes.shape({
  isOpen: PropTypes.shape({
    [PropTypes.string]: PropTypes.bool,
  }).isRequired,
  closeAll: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
});
