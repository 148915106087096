import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { TransitionGroup } from 'react-transition-group';

import reduxConnect from 'components/Grm2/reduxConnect';
import { uiShape, cardShape, linksPreviewShape } from 'components/Grm2/shapes';
import CommentsDrawer from 'components/Grm2/common/CommentsDrawer';
import { sortCardsByFunction } from 'components/Grm2/utils';

import Card, { CardTransition } from '../Card';
import LoadingPlaceholder from './components/LoadingPlaceholder';
import EmptyPlaceholder from './components/EmptyPlaceholder';
import Paginator from './components/Paginator';
import useCommentsDrawer from './useCommentsDrawer';
import styles from './styles.module.scss';

function Feed({
  cards,
  cardsLinkPreview,
  className,
  filterCardsBy,
  cardsCompareFunction,
  isSingleCard,
  priorityIconOnly,
  showCheckbox,
  ui,
  isMobile,
  onSetFilterInCard,
  emptyMessage,
  showCommentsInDrawer,
  updateCard,
  updateCommentsCount,
  cardSize,
  withReversedLoadingAnimation,
}) {
  const { isLoading } = ui;

  const {
    commentsDrawerCardId,
    onCommentsDrawerClose,
    isCommentsDrawerOpen,
    onShowCommentsInDrawerClick,
  } = useCommentsDrawer();

  const renderCards = () => {
    if (isLoading) {
      return (
        <LoadingPlaceholder
          isMobile={isMobile}
          showCheckbox={showCheckbox}
          isSingleCard={isSingleCard}
          withReversedAnimation={withReversedLoadingAnimation}
        />
      );
    }

    const sortedCards = sortCardsByFunction(cards, cardsCompareFunction);
    const filteredCards = sortedCards.filter(filterCardsBy);

    if (isEmpty(filteredCards)) {
      return <EmptyPlaceholder message={emptyMessage} />;
    }

    return (
      <TransitionGroup>
        {filteredCards.map(card => (
          <CardTransition key={card.id} cardId={card.id}>
            <Card
              {...card}
              linkPreview={cardsLinkPreview[card.id]}
              size={cardSize}
              isMobile={isMobile}
              priorityIconOnly={priorityIconOnly}
              isSingleCard={isSingleCard}
              showCheckbox={showCheckbox}
              onSetFilterInCard={onSetFilterInCard}
              showCommentsInDrawer={showCommentsInDrawer}
              onShowCommentsInDrawerClick={onShowCommentsInDrawerClick}
            />
          </CardTransition>
        ))}
      </TransitionGroup>
    );
  };

  return (
    <div className={classNames(styles.feed, className)}>
      {renderCards()}
      <Paginator />
      <CommentsDrawer
        isOpen={isCommentsDrawerOpen}
        cardId={commentsDrawerCardId}
        onClose={onCommentsDrawerClose}
        updateCard={updateCard}
        updateCommentsCount={updateCommentsCount}
      />
    </div>
  );
}

Feed.defaultProps = {
  isSingleCard: false,
  isMobile: false,
  priorityIconOnly: false,
  showCheckbox: true,
  filterCardsBy: () => true,
  showCommentsInDrawer: false,
  withReversedLoadingAnimation: false,
};

Feed.propTypes = {
  isSingleCard: PropTypes.bool,
  priorityIconOnly: PropTypes.bool,
  cards: PropTypes.arrayOf(cardShape).isRequired,
  cardsLinkPreview: linksPreviewShape.isRequired,
  ui: uiShape.isRequired,
  className: PropTypes.string,
  showCheckbox: PropTypes.bool,
  isMobile: PropTypes.bool,
  cardSize: PropTypes.string,
  filterCardsBy: PropTypes.func,
  onSetFilterInCard: PropTypes.func,
  emptyMessage: PropTypes.node,
  cardsCompareFunction: PropTypes.func,
  showCommentsInDrawer: PropTypes.bool,
  updateCard: PropTypes.func.isRequired,
  updateCommentsCount: PropTypes.func.isRequired,
  withReversedLoadingAnimation: PropTypes.bool,
};

export default reduxConnect(Feed);
