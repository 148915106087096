import { size, every } from 'lodash';

import { removeCards } from 'components/Grm2/api';
import notifications from 'components/Grm2/notifications';
import useLoader from 'utils/useLoader';
import { isCardRemovable, getCardsByIds } from 'components/Grm2/utils';
import useModal from 'components/core/Modal/useModal';

function useRemoveCards({
  updateCardsById,
  ids,
  resetSelectedCards,
  cards,
}) {
  const cardsCount = size(ids);
  const areCardsRemovable = every(getCardsByIds(cards, ids), isCardRemovable);

  const { isLoading, startLoading, stopLoading } = useLoader();
  const { isOpenModal, toggleModal } = useModal();

  const handleRemove = () => {
    startLoading();

    return removeCards(ids)
      .then(() => {
        updateCardsById(ids, { disabled: true });
        notifications.bulkDeleteSuccess(cardsCount);
        resetSelectedCards();
        toggleModal();
      })
      .catch(notifications.generalFailNotification)
      .finally(stopLoading);
  };

  return {
    isLoading,
    cardsCount,
    handleRemove,
    areCardsRemovable,
    isOpenModal,
    toggleModal,
  };
}

export default useRemoveCards;
