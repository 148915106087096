import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import Label from 'components/core/Label';
import TagInput from 'components/core/TagInput';
import Asterisk from 'components/core/Asterisk';
import { getError } from 'utils/forms';

import styles from './styles.module.scss';

function AssigneeField({
  name,
  title,
  testClassName,
  isRequired,
  strategy,
}) {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const handleChange = colleague => field.onChange(name)(colleague);

        const selectedOption = field.value;

        return (
          <div className={styles.container}>
            <Label className={styles.label}>
              {title}
              <Asterisk show={isRequired} />
            </Label>
            <TagInput
              withRichOption
              noGroupClassName
              overrideValue={selectedOption}
              shouldOverrideValue
              strategy={strategy}
              onChange={handleChange}
              className={testClassName}
              multiple={false}
              errorMessage={getError(name, form)}
            />
          </div>
        );
      }}
    </Field>
  );
}

AssigneeField.defaultProps = {
  testClassName: '',
  isRequired: false,
};

AssigneeField.propTypes = {
  name: PropTypes.string.isRequired,
  testClassName: PropTypes.string,
  title: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  strategy: PropTypes.string.isRequired,
};

export default AssigneeField;
