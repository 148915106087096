import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import ApplyTagsButton from 'components/Grm2/common/ApplyTagsButton';
import ReAssignButton from 'components/Grm2/common/ReAssignButton';
import PriorityModalButton from 'components/Grm2/common/PriorityModalButton';
import RemoveButton from 'components/Grm2/common/RemoveButton';
import reduxConnect from 'components/Grm2/reduxConnect';
import ActionBar from 'components/core/ActionBar';
import Button from 'components/core/Button';
import { isCardEditable } from 'components/Grm2/utils';
import ExportActivityModal from 'components/Grm2/common/ExportActivityModal';
import { CARD_MODAL_COMPONENTS } from 'components/Grm2/common/NewCard/constants';
import { pluralize } from 'utils/string';
import { cardShape, customFieldShape } from 'components/Grm2/shapes';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import useRemoveCards from './useRemoveCards';
import useFooterActions from './useFooterActions';
import styles from './styles.module.scss';

function FooterActions({
  selectedCardIds,
  resetSelectedCards,
  cards,
  isFixed,
  bulkSaveTags,
  withChatOffset,
  customFields,
  updateTasks,
  updateCardsById,
}) {
  const { isMobile } = useMediaQuery();

  const {
    onReAssignSave,
    toggleExportModal,
    toggleEditModal,
    showEditModal,
    showExportModal,
    isTasksSelected,
    tasksId,
    isOneCardSelected,
    cardId,
    card,
  } = useFooterActions({
    cards,
    updateTasks,
    selectedCardIds,
  });

  const {
    handleRemove,
    cardsCount,
    areCardsRemovable,
    isOpenModal: isOpenRemoveModal,
    toggleModal: toggleRemoveModal,
    isLoading: isLoadingRemove,
  } = useRemoveCards({
    ids: selectedCardIds,
    cards,
    updateCardsById,
    resetSelectedCards,
  });

  const btnProps = {
    theme: 'light',
  };

  if (isEmpty(selectedCardIds)) {
    return null;
  }

  const renderEditButton = () => {
    if (!card || !isCardEditable(card.cardType, card.user)) {
      return null;
    }

    return (
      <Button
        onClick={toggleEditModal}
        className={styles.actionBtn}
        {...btnProps}
      >
        Edit
      </Button>
    );
  };

  const renderTaskButtons = () => {
    if (!isTasksSelected) {
      return null;
    }

    return (
      <>
        <ReAssignButton
          {...btnProps}
          onSave={onReAssignSave}
          className={styles.actionBtn}
          isBulk={!isOneCardSelected}
        >
          Re-assign
        </ReAssignButton>
        <PriorityModalButton
          {...btnProps}
          className={styles.actionBtn}
          ids={tasksId}
          onSave={updateTasks}
        />
      </>
    );
  };

  const renderDeleteButton = () => {
    if (!areCardsRemovable) {
      return null;
    }

    return (
      <RemoveButton
        {...btnProps}
        count={cardsCount}
        isLoading={isLoadingRemove}
        className={styles.actionBtn}
        isOpenModal={isOpenRemoveModal}
        toggleModal={toggleRemoveModal}
        onSave={handleRemove}
      />
    );
  };

  const activitiesWord = pluralize('activity', 'activities', selectedCardIds.length);
  const description = { default: `${selectedCardIds.length} ${activitiesWord} selected` };
  const isTimelineClassName = !isFixed && styles.isTimeline;

  const EditModal = card && CARD_MODAL_COMPONENTS[card.cardType];

  return (
    <>
      {!isMobile && (
        <ActionBar
          className={styles.container}
          contentClassName={classNames(styles.content, isTimelineClassName)}
          selectedItems={selectedCardIds}
          description={description}
          isFixed={isFixed}
          withChatOffset={withChatOffset}
        >
          <Button
            onClick={resetSelectedCards}
            {...btnProps}
          >
            Clear
          </Button>
          <ApplyTagsButton
            cardIds={selectedCardIds}
            buttonClassName={styles.actionBtn}
            onSave={bulkSaveTags}
            isMultipleItems={!isOneCardSelected}
            cardType={card && card.cardType}
            {...btnProps}
          />
          <Button
            onClick={toggleExportModal}
            className={styles.actionBtn}
            {...btnProps}
          >
            Export
          </Button>
          {renderTaskButtons()}
          <ExportActivityModal
            isOpenModal={showExportModal}
            toggleModal={toggleExportModal}
            selectedItemsIds={selectedCardIds}
            customFields={customFields}
          />
          {renderEditButton()}
          {renderDeleteButton()}
        </ActionBar>
      )}
      {showEditModal && (
        <EditModal id={cardId} onClose={toggleEditModal} />
      )}
    </>
  );
}

FooterActions.defaultProps = {
  isFixed: true,
};

FooterActions.propTypes = {
  selectedCardIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  resetSelectedCards: PropTypes.func.isRequired,
  cards: PropTypes.arrayOf(cardShape).isRequired,
  isFixed: PropTypes.bool,
  bulkSaveTags: PropTypes.func.isRequired,
  updateTasks: PropTypes.func.isRequired,
  updateCardsById: PropTypes.func.isRequired,
  withChatOffset: PropTypes.bool,
  customFields: PropTypes.arrayOf(customFieldShape),
};

export default reduxConnect(FooterActions);
