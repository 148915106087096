import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import DatePicker from 'components/core/DatePicker';

import useFormatValue from './useFormatValue';
import useDateFilter from './useDateFilter';
import styles from './styles.module.scss';

function DateFilter({ onChange, startDate, endDate }) {
  const datePickerProps = useDateFilter({ onChange, startDate, endDate });

  const value = useFormatValue(startDate, endDate);

  const customInput = () => (
    <span>
      <span className={styles.input}>
        <span className={styles.label}>Date of creation: </span>
        <span className={styles.value}>{value}</span>
        <i className={classNames(styles.icon, 'fas fa-sort-down')} />
      </span>
    </span>
  );

  return (
    <div className={styles.container}>
      <DatePicker
        customInput={customInput()}
        {...datePickerProps}
      />
    </div>
  );
}

DateFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

export default DateFilter;
