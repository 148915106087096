import PropTypes from 'prop-types';

import { workspaceShape } from 'redux/events/utils/shapes';
import { dateRangeShape } from 'utils/shapes/dateRange';

import { userShape, uploadShape } from './common/Card/shapes';
import {
  committeesShape,
  billsShape,
  documentsShape,
  interactionTypesShape,
  peopleShape,
  regulationsShape,
} from './common/Filters/shapes';

export const linkShape = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  })),
});

export const tagShape = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

export const customFieldDataShape = PropTypes.shape({
  label: PropTypes.string,
  token: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
});

export const customFieldShape = PropTypes.shape({
  action: PropTypes.shape({
    enabled: PropTypes.bool,
    token: PropTypes.string,
    editable: PropTypes.bool,
  }),
  createdAt: PropTypes.string,
  description: PropTypes.string,
  enabled: PropTypes.bool,
  fieldType: PropTypes.string,
  isEditable: PropTypes.bool,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  product_slugs: PropTypes.arrayOf(PropTypes.string),
  required: PropTypes.bool,
  token: PropTypes.string,
});

export const filtersShape = PropTypes.shape({
  activityTypes: PropTypes.arrayOf(PropTypes.string),
  attachments: PropTypes.bool,
  bills: PropTypes.arrayOf(billsShape),
  committees: PropTypes.arrayOf(committeesShape),
  documents: PropTypes.arrayOf(documentsShape),
  creationDate: dateRangeShape,
  creator: PropTypes.arrayOf(tagShape),
  eventDate: dateRangeShape,
  interactionTypes: PropTypes.arrayOf(interactionTypesShape),
  keywordLookup: PropTypes.string,
  people: PropTypes.arrayOf(peopleShape),
  regulations: PropTypes.arrayOf(regulationsShape),
  tags: PropTypes.arrayOf(tagShape),
});

export const cardShape = PropTypes.shape({
  cardType: PropTypes.string,
  commentsCount: PropTypes.number,
  createdBy: PropTypes.string,
  creationDate: PropTypes.string,
  customFieldsAnswers: PropTypes.arrayOf(customFieldShape),
  description: PropTypes.string.isRequired,
  eventDate: PropTypes.string,
  id: PropTypes.number.isRequired,
  interactionType: PropTypes.string,
  links: PropTypes.arrayOf(linkShape),
  tags: PropTypes.arrayOf(tagShape),
  title: PropTypes.string.isRequired,
  uploads: PropTypes.arrayOf(uploadShape),
  user: userShape.isRequired,
  documentType: PropTypes.string.isRequired,
  sharings: PropTypes.arrayOf(workspaceShape),
});

export const uiShape = PropTypes.shape({
  isDeletingComment: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoadingCustomFields: PropTypes.bool,
  isLoadingMyFeedDocuments: PropTypes.bool.isRequired,
});

export const paginationShape = PropTypes.shape({
  currentPage: PropTypes.number,
  perPage: PropTypes.number,
  totalCount: PropTypes.number,
  totalPages: PropTypes.number,
});

export const linksPreviewShape = PropTypes.shape({
  [PropTypes.string]: linkShape,
});

export const analyticsShape = PropTypes.shape({
  ui: PropTypes.shape({
    showDashboard: PropTypes.bool.isRequired,
  }),
});
