import React from 'react';
import PropTypes from 'prop-types';

import Select from 'components/core/Select';
import theme from 'components/core/TagInput/theme';
import { INTERACTION_TYPES } from 'components/Grm2/constants';

function InteractionType({ onChange, value }) {
  const handleChange = (newValue) => {
    onChange('interactionTypes', newValue);
  };

  return (
    <Select
      value={value}
      onChange={handleChange}
      options={INTERACTION_TYPES}
      customTheme={theme()}
      className="test__filter-interaction-type"
      multi
    />
  );
}

InteractionType.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
};

export default InteractionType;
