import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SearchBox from 'components/core/SearchBox';
import Button from 'components/core/Button';
import reduxConnect from 'components/Grm2/reduxConnect';
import { filtersShape } from 'components/Grm2/shapes';

import styles from './styles.module.scss';

function SearchButton({
  showSearchBox,
  setShowSearchBox,
  filters,
  setFilter,
}) {
  const [keywordLookup, setKeywordLookup] = useState(filters.keywordLookup);

  const handleChange = event => setKeywordLookup(event.target.value);

  const handleSubmit = () => {
    setFilter('keywordLookup', keywordLookup);
    setShowSearchBox(false);
  };

  const handleClick = () => setShowSearchBox(true);

  if (showSearchBox) {
    return (
      <SearchBox
        value={keywordLookup}
        onChange={handleChange}
        onSubmit={handleSubmit}
        withIcon
        withButton
      />
    );
  }

  return (
    <Button
      theme="primary"
      customIcon={<i className="far fa-search" />}
      onClick={handleClick}
      className={styles.button}
      iconClassName={styles.icon}
    />
  );
}

SearchButton.propTypes = {
  showSearchBox: PropTypes.bool.isRequired,
  setShowSearchBox: PropTypes.func.isRequired,
  filters: filtersShape.isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default reduxConnect(SearchButton);
