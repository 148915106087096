import { reduce, omitBy, isEmpty } from 'lodash';

import { getUrlParam } from 'utils/browser-history';

import { urlOptions } from '../constants';

export const parseOption = (option) => {
  // In case if JSON can't parse url
  try {
    return JSON.parse(getUrlParam(option)) || [];
  } catch {
    return [];
  }
};

export const getUrlParamsByOptions = () => omitBy(reduce(
  urlOptions,
  (urlParams, option) => ({ ...urlParams, [option]: parseOption(option) }),
  {}
), isEmpty);
