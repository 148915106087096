import React from 'react';
import PropTypes from 'prop-types';

import { dateRangeShape } from 'utils/shapes/dateRange';

import DateFilter from './components/DateFilter';
import useFilters from './useFilters';
import styles from './styles.module.scss';

function Filters({ setFilters, creationDate }) {
  const {
    handleDateFilterChange,
  } = useFilters({ setFilters });

  return (
    <div className={styles.filters}>
      <DateFilter onChange={handleDateFilterChange} {...creationDate} />
    </div>
  );
}

Filters.propTypes = {
  setFilters: PropTypes.func.isRequired,
  creationDate: dateRangeShape,
};

export default Filters;
