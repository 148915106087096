import React from 'react';

import { INTERACTION_TYPES } from 'components/Grm2/constants';
import theme from 'components/core/TagInput/theme';

import {
  DateField,
  TagInputField,
  SelectField,
  TagManagerField,
} from '../Fields';

function SystemFields() {
  return (
    <>
      <DateField
        name="eventDate"
        title="Date of event"
        testClassName="test__new-card-date-of-event"
      />
      <SelectField
        name="interactionType"
        title="Interaction type"
        options={INTERACTION_TYPES}
        customTheme={theme()}
        clearable
        isSearchable
        testClassName="test__new-card-interaction-type"
      />
      <TagInputField
        name="people"
        title="People"
        placeholder="Add any that are relevant to this task or interaction (e.g., Legislators, Staffers, Stakeholders, Colleagues)."
        strategy="people"
        testClassName="test__new-card-people"
        withRichOption
      />
      <TagInputField
        name="bills"
        title="Bills"
        strategy="bill"
        testClassName="test__new-card-bills"
        withRichOption
      />
      <TagInputField
        name="regulations"
        title="Regulations"
        strategy="regulation"
        testClassName="test__new-card-regulations"
        withRichOption
      />
      <TagManagerField
        name="tags"
        title="Tags"
        placeholder="Apply a tag to this task so you can easily identify it later (e.g., via lookups, profiles, and the filters therein)."
        strategy="tag"
        testClassName="test__new-card-tags"
        strategyInputs={{ create: true }}
        asyncCreatable
      />
      <TagInputField
        name="committees"
        title="Committees"
        strategy="committee"
        testClassName="test__new-card-committees"
        withRichOption
      />
      <TagInputField
        name="documents"
        title="Documents"
        strategy="documents"
        testClassName="test__new-card-documents"
        withRichOption
      />
      <TagInputField
        name="hillDayActivity"
        title="Hill Day activity"
        strategy="hill_day_activity"
        testClassName="test__new-card-hill-day-activity"
        withRichOption
      />
    </>
  );
}

export default SystemFields;
