import { useState } from 'react';

import { fetchCard } from 'components/Grm2/api';

function useComments({
  cardId,
  isNote,
  isTask,
  isSingleCard,
  onShowCommentsInDrawerClick,
  showCommentsInDrawer,
  updateCard,
}) {
  const [showComments, setShowComments] = useState(isSingleCard);
  const [showCommentLoader, setShowCommentLoad] = useState(false);
  const showCommentAction = isNote || isTask;

  const onCommentClick = () => {
    if (showCommentsInDrawer) {
      onShowCommentsInDrawerClick(cardId);
      return;
    }

    fetchCard(cardId).then(updateCard);
    setShowCommentLoad(!showComments);
    setShowComments(!showComments);
  };

  return {
    showComments,
    showCommentLoader,
    showCommentAction,
    onCommentClick,
    setShowCommentLoad,
  };
}

export default useComments;
