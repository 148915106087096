import { transformFiltersForBE } from 'redux/grm2/transformers';
import { transformAnalyticsToFE } from './transformers';

const fetchData = (filters) => {
  const url = '/api_web/grm_v2/analytics';
  const method = 'POST';
  const data = {
    search_form: transformFiltersForBE(filters),
  };

  const ajax = $.ajax({
    url,
    method,
    data: JSON.stringify(data),
    contentType: 'application/json',
  });

  return Promise.resolve(ajax)
    .then(transformAnalyticsToFE);
};

export default {
  fetchData,
};
