import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Drawer, { Header, Body } from 'components/core/Drawer';

import Comments from '../Comments';
import useCommentsDrawer from './useCommentsDrawer';
import CommentsPlaceholder from './components/CommentsPlaceholder';
import styles from './styles.module.scss';

function CommentsDrawer({
  cardId,
  onClose,
  isOpen,
  updateCard,
  updateCommentsCount,
}) {
  const {
    handleSetShowLoader,
    showComments,
    isLoading,
  } = useCommentsDrawer({ cardId });

  return (
    <Drawer isOpen={isOpen} data-test="comments-drawer">
      <Header
        title="Activity"
        onBackClick={onClose}
      />

      <Body>
        {isLoading && <CommentsPlaceholder />}

        {showComments && (
          <Comments
            loadAll
            cardId={cardId}
            setShowLoader={handleSetShowLoader}
            className={classNames(isLoading && styles.hide)}
            updateCard={updateCard}
            updateCommentsCount={updateCommentsCount}
          />
        )}
      </Body>
    </Drawer>
  );
}

CommentsDrawer.propTypes = {
  cardId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  updateCard: PropTypes.func.isRequired,
  updateCommentsCount: PropTypes.func.isRequired,
};

export default CommentsDrawer;
