import React from 'react';
import PropTypes from 'prop-types';

import Card from 'components/core/Card';

import styles from './styles.module.scss';

function DesktopView({ children }) {
  return (
    <Card className={styles.container}>
      {children}
    </Card>
  );
}

DesktopView.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DesktopView;
