import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { INTERACTION_TYPES, CARD_SIZES } from 'components/Grm2/constants';

import { cardSizeShape } from '../../shapes';
import styles from './styles.module.scss';

const sizeThemes = {
  large: styles.large,
  small: styles.small,
  medium: styles.medium,
};

function InteractionType({ interactionType, size }) {
  if (isEmpty(interactionType)) {
    return null;
  }

  const { label } = INTERACTION_TYPES.find(({ value }) => interactionType === value);

  return (
    <div className={classNames(styles.interactionType, sizeThemes[size])}>
      <p className={classNames(styles.label, sizeThemes[size])}>Interaction type: </p>
      {label}
    </div>
  );
}

InteractionType.propTypes = {
  interactionType: PropTypes.oneOf(INTERACTION_TYPES.map(({ value }) => value)),
  size: cardSizeShape,
};

InteractionType.defaultProps = {
  size: CARD_SIZES.large,
};

export default InteractionType;
