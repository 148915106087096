import { error, success } from 'components/core/Notification';
import { pluralize } from 'utils/string';

export default {
  createSuccess: type => success(`Activity ${type} added.`)(),
  updateSuccess: type => success(`Activity ${type} edited.`)(),
  deleteSuccess: type => success(`Activity ${type} deleted.`)(),
  bulkDeleteSuccess: count => success(`Deleted ${count} ${pluralize('activity', 'activities', count)}.`)(),
  undoSuccess: type => success(`Activity ${type} restored.`)(),
  updateCardTagSuccess: type => success(`Tags updated on activity ${type}.`)(),
  updateMultipleCardsTagSuccess: number => (
    success(`Tags updated on ${number} ${pluralize('item', 'items', number)}.`)()
  ),
  updateTaskStatusSuccess: success('Activity task status updated.'),
  taskReassignSuccess: success('Activity task reassigned.'),
  tasksReassignSuccess: success('Activity tasks reassigned.'),
  updateTaskPrioritySuccess: success('Activity task priority updated.'),
  updateTasksPrioritySuccess: success('Activity tasks priority updated.'),
  generalFailNotification: (caughtError) => {
    error('Something went wrong. Please try again.')();

    throw caughtError;
  },
};
