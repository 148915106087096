import isEmpty from 'lodash/isEmpty';

const makeGradient = ({ canvas, colors }) => {
  const ctx = canvas.getContext('2d');
  const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
  gradient.addColorStop(0, colors.top);
  gradient.addColorStop(1, colors.bottom);

  return gradient;
};

export const addGradient = ({ datasets, ...restProps }, colors) => canvas => ({
  ...restProps,
  datasets: datasets.map(dataset => ({
    ...dataset,
    fill: true,
    backgroundColor: makeGradient({ canvas, colors }),
    borderColor: colors.border,
    borderWidth: colors.border ? 1 : 0,
  })),
});

const breakStringIntoLines = (processedLines, currentLine, remainingWords, charsPerLine = 30) => {
  if (isEmpty(remainingWords)) {
    return [
      ...processedLines,
      currentLine,
    ];
  }

  const nextWord = remainingWords[0];

  if (currentLine.length + nextWord.length + 1 > charsPerLine) {
    return breakStringIntoLines(
      [
        ...processedLines,
        currentLine,
      ],
      nextWord,
      remainingWords.slice(1),
      charsPerLine
    );
  }

  return breakStringIntoLines(
    processedLines,
    [currentLine, nextWord].join(' ').trim(),
    remainingWords.slice(1),
    charsPerLine
  );
};

const limitLines = (lines, nrLines = 3) => {
  if (lines.length <= nrLines) {
    return lines;
  }

  const newLines = lines.slice(0, nrLines);
  const lastLine = newLines.slice(-1)[0];

  return [
    ...newLines.slice(0, nrLines - 1),
    `${lastLine}...`,
  ];
};

export const transformBigStrings = (string, charsPerLine) => {
  const tokens = string.split(' ');
  const lines = breakStringIntoLines([], '', tokens, charsPerLine);

  return limitLines(lines);
};

export const getSuggestedMax = values => (
  Math.max(...values) + 1
);
