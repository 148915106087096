import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { isEmpty, map } from 'lodash';

import Link from 'components/core/Link';
import joinComponents from 'utils/joinComponents';
import { RECORD_TYPES } from 'redux/grm2/recordTypes';
import { CARD_SIZES } from 'components/Grm2/constants';
import { cardSizeShape } from 'components/Grm2/common/Card/shapes';

import styles from './styles.module.scss';

const sizeThemes = {
  large: styles.large,
  small: styles.small,
  medium: styles.medium,
};

function ListLinks({ label, value, size }) {
  const { colleague } = RECORD_TYPES;

  const renderLink = ({
    label: linkLabel,
    value: linkValue,
    id,
    externalUrl,
  }) => {
    const isClickableLink = linkValue !== colleague.path() && !isEmpty(linkValue);
    const isOpenInNewTab = !isEmpty(externalUrl);
    const linkTarget = isOpenInNewTab ? '_blank' : '_self';

    if (!isClickableLink) {
      return (
        <span className={classNames(styles.text, sizeThemes[size])} key={id}>
          {linkLabel}
        </span>
      );
    }

    return (
      <Link
        key={id}
        href={linkValue}
        theme="default"
        target={linkTarget}
        className={classNames(styles.link, sizeThemes[size])}
      >
        {linkLabel}
      </Link>
    );
  };

  return (
    <div className={styles.container} key={label}>
      <p className={classNames(styles.label, sizeThemes[size])}>{label}: </p>
      {joinComponents(map(value, renderLink))}
    </div>
  );
}

ListLinks.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
      externalUrl: PropTypes.string,
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
    })
  ),
  size: cardSizeShape,
};

ListLinks.defualtProps = {
  size: CARD_SIZES.large,
};

export default ListLinks;
