import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';

import styles from './styles.module.scss';

function LabelButton({ onClick, className }) {
  return (
    <Button
      theme="secondary"
      size="medium"
      type="button"
      onClick={onClick}
      className={classNames(className, styles.button)}
    >
      <span className={styles.label}>Activity</span>
      <i className={classNames(styles.icon, 'fas fa-heart-rate')} />
    </Button>
  );
}

LabelButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default LabelButton;
