import { includes, isEqual } from 'lodash';

import useActivityEffect from 'components/Grm2/events/useActivityEffect';
import {
  CARD_UPDATED,
  CARDS_UPDATED,
  CARD_CREATED,
  ACTIVITY_PAGE_NAMESPACE,
  DATA_ITEM_UPDATED,
} from 'components/Grm2/events/constants';
import { isAlreadyUpdated, mergeCards } from 'components/Grm2/utils';

function shouldUpdateCard(cards, uiCard) {
  if (isAlreadyUpdated(cards, uiCard)) {
    return false;
  }

  return true;
}

function useUpdateActivityPage({ cards, updateCard, updateDataItem }) {
  const handleUpdateCards = (event, namespace, payload) => {
    const isNew = event === CARD_CREATED;
    const cardsUpdated = includes([CARD_UPDATED, CARD_CREATED], event)
      ? [payload]
      : mergeCards(payload); // CARDS_UPDATED

    cardsUpdated.forEach(({ reduxCard, uiCard }) => {
      // In case of having multiple ActivityEffect listeners
      // we must use setTimeout to make sure that the update was performed
      // Otherwise we can get a loop of infinite events published
      // Ex:
      // - Card in Activity page is updated
      // - Event is dispatched
      // - Drawer reveive event
      // - Drawer is updated
      // - Event is dispatched
      // - Activity page reveive event
      // - Card in Activity page is updated
      // - ... (loop)
      setTimeout(() => {
        shouldUpdateCard(cards, uiCard) && updateCard(reduxCard, isNew, false);
      }, 0);
    });
  };

  const handleUpdateDataItem = (namespace, payload) => {
    updateDataItem(payload, false);
  };

  useActivityEffect((event, namespace, payload) => {
    if (includes([CARD_UPDATED, CARD_CREATED, CARDS_UPDATED], event)) {
      handleUpdateCards(event, namespace, payload);
      return;
    }

    if (isEqual(event, DATA_ITEM_UPDATED)) {
      handleUpdateDataItem(namespace, payload);
    }
  }, [CARD_UPDATED, CARDS_UPDATED, CARD_CREATED, DATA_ITEM_UPDATED], [ACTIVITY_PAGE_NAMESPACE]);
}

export default useUpdateActivityPage;
