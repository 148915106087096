import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import reduxConnect from 'components/Grm2/reduxConnect';
import Filters from 'components/Grm2/common/Filters';
import FooterActions from 'components/Grm2/common/FooterActions';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';
import Header from 'components/Grm2/common/Header';
import Feed from 'components/Grm2/common/Feed';

import styles from './styles.module.scss';

const SingleCardView = ({ fetchSingleCard, className, match, history }) => {
  const { id: cardId } = match.params;
  const { isMobile } = useMediaQuery();

  useEffect(() => {
    fetchSingleCard(cardId);
  }, []);

  const handleSetFilterInCard = () => history.push('/grm');

  return (
    <div className={className}>
      <Header haveActions={false} />
      <div className={styles.container}>
        <div className={styles.content}>
          <Feed
            isSingleCard
            onSetFilterInCard={handleSetFilterInCard}
          />
        </div>
        {!isMobile && <Filters isDisabled />}
      </div>
      <FooterActions withChatOffset />
    </div>
  );
};

SingleCardView.propTypes = {
  fetchSingleCard: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  }).isRequired,
  className: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default withRouter(reduxConnect(SingleCardView));
