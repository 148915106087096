import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TagInput from 'components/core/TagInput';
import { tagShape } from 'components/Grm2/shapes';

import styles from '../styles.module.scss';

function Tags({ value, onChange }) {
  const handleChange = (newValue) => {
    onChange('tags', newValue);
  };

  return (
    <TagInput
      strategy="tag"
      onChange={handleChange}
      overrideValue={value}
      shouldOverrideValue
      groupClassName={classNames(
        'test__filter-tags-input',
        styles.item,
      )}
    />
  );
}

Tags.defaultProps = {
  value: [],
};

Tags.propTypes = {
  value: PropTypes.arrayOf(tagShape),
  onChange: PropTypes.func.isRequired,
};

export default Tags;
