import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import useModal from 'components/core/Modal/useModal';
import Button from 'components/core/Button';
import Modal from 'components/core/Modal';
import notifications from 'components/Grm2/notifications';
import BaseOption from 'components/core/AdvancedSearch/components/Options/components/BaseOption';

import { assigneeShape } from '../Card/shapes';

import styles from './styles.module.scss';

function ReAssignButton({
  className,
  defaultOption,
  onSave,
  children,
  isBulk,
  ...restProps
}) {
  const { isOpenModal, toggleModal } = useModal();
  const [option, setOption] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const isSubmitDisabled = isEmpty(option) || defaultOption.id === option.value;

  useEffect(() => {
    const selectedOption = {
      value: defaultOption.id,
      label: defaultOption.fullName,
    };

    setOption(selectedOption);
  }, [defaultOption, isOpenModal]);

  const handleChange = (selectedColleague) => {
    setOption(selectedColleague);
  };

  const successNotify = () => (
    isBulk
      ? notifications.tasksReassignSuccess()
      : notifications.taskReassignSuccess()
  );

  const handleSave = () => {
    setIsLoading(true);

    onSave(option.value)
      .then(successNotify)
      .catch(notifications.generalFailNotification)
      .finally(() => {
        setIsLoading(false);
        toggleModal();
      });
  };

  const buttons = (
    <>
      <Button
        theme="light"
        size="small"
        className={styles.cancelButton}
        onClick={toggleModal}
      >
        Cancel
      </Button>
      <Button
        size="small"
        isLoading={isLoading}
        disabled={isSubmitDisabled}
        onClick={handleSave}
      >
        Save
      </Button>
    </>
  );

  return (
    <>
      <Button
        {...restProps}
        className={className}
        disabled={isLoading}
        onClick={toggleModal}
      >
        {children}
      </Button>
      <Modal
        title="Re-assign task"
        isOpen={isOpenModal}
        className={styles.modal}
        footerClassName={styles.footer}
        toggleOpen={toggleModal}
        buttons={buttons}
      >
        <BaseOption
          title="Assignee"
          strategy="user"
          overrideValue={option}
          shouldOverrideValue
          withRichOption
          className="test__reassign-modal-assignee"
          onChange={handleChange}
          multiple={false}
        />
      </Modal>
    </>
  );
}

ReAssignButton.defaultProps = {
  defaultOption: {},
  isBulk: false,
};

ReAssignButton.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isBulk: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onSave: PropTypes.func,
  defaultOption: assigneeShape,
};

export default ReAssignButton;
