import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import DateRange from 'components/core/DateRange';
import { formatDate } from 'utils/date';
import { dateRangeShape } from 'utils/shapes/dateRange';

import styles from '../styles.module.scss';

function EventDate({ value, onChange }) {
  const { startDate, endDate } = value;

  const handleChange = (newValue) => {
    onChange('eventDate', newValue);
  };

  const handleChangeStart = (newStartDate) => {
    handleChange({
      startDate: formatDate(newStartDate),
      endDate,
    });
  };

  const handleChangeEnd = (newEndDate) => {
    handleChange({
      startDate,
      endDate: formatDate(newEndDate),
    });
  };

  const getDate = date => (isEmpty(date) ? date : moment(date));

  return (
    <DateRange
      startDate={getDate(startDate)}
      endDate={getDate(endDate)}
      handleChangeStart={handleChangeStart}
      handleChangeEnd={handleChangeEnd}
      className={styles.item}
      startDateClassName="test__filter-event-start-date"
      endDateClassName="test__filter-event-end-date"
    />
  );
}

EventDate.defaultProps = {
  value: {},
};

EventDate.propTypes = {
  value: dateRangeShape,
  onChange: PropTypes.func.isRequired,
};

export default EventDate;
