import isEmpty from 'lodash/isEmpty';

import { transformFiltersForBE } from 'redux/grm2/transformers';

import { FILTERS_TO_STRINGIFY } from './constants';

export function transformStrategyInputs(selectedItemsIds, filters = {}) {
  const strategyInputs = {
    ...(!isEmpty(filters) && transformFiltersForBE(filters)),
    ids: selectedItemsIds,
  };

  let customApiProps = {};

  Object.keys(strategyInputs).forEach((key) => {
    const data = FILTERS_TO_STRINGIFY.includes(key)
      ? JSON.stringify(strategyInputs[key])
      : strategyInputs[key];

    customApiProps = {
      ...customApiProps,
      [`strategy_inputs[${key}]`]: data,
    };
  });

  return customApiProps;
}

export function transformCustomFields(customFields = []) {
  return customFields.map(({ token, name }) => ({ id: token, title: name }));
}
