import React from 'react';
import PropTypes from 'prop-types';

import constants from 'redux/grm2/constants';
import { XLSX_ID, CSV_ID } from 'utils/constants/export';
import { currentDate } from 'utils/date';
import ExportLookupModal from 'components/core/PageLookup/components/Export/components/ExportLookupModal';
import { filtersShape, customFieldShape } from 'components/Grm2/shapes';

import { EXPORT_TAB_COLUMNS, DEFAULT_COLUMN_ID } from './constants';
import { transformStrategyInputs, transformCustomFields } from './utils';

function ExportActivityModal({
  filters,
  isOpenModal,
  toggleModal,
  customFields,
  selectedItemsIds,
}) {
  const fileName = `Activity report - ${currentDate()}`;
  const customApiProps = transformStrategyInputs(selectedItemsIds, filters);
  const exportTableColumns = [
    ...EXPORT_TAB_COLUMNS,
    ...transformCustomFields(customFields),
  ];

  return (
    <ExportLookupModal
      documentTitle="Activity report"
      fileName={fileName}
      isOpenModal={isOpenModal}
      toggleModal={toggleModal}
      selectedItems={selectedItemsIds}
      customApiProps={customApiProps}
      defaultColumnsIds={[DEFAULT_COLUMN_ID]}
      exportTableColumns={exportTableColumns}
      slugSingleExport={constants.EXPORT_STRATEGY_SLUG}
      customFileFormats={[XLSX_ID, CSV_ID]}
      defaultFileFormat={[XLSX_ID]}
      withDocxCustomization
    />
  );
}

ExportActivityModal.propTypes = {
  filters: filtersShape,
  isOpenModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  selectedItemsIds: PropTypes.arrayOf(PropTypes.number),
  customFields: PropTypes.arrayOf(customFieldShape),
};

export default ExportActivityModal;
