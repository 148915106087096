import get from 'lodash/get';

import { STATUS_TYPES } from 'components/Grm2/constants';

const PROPERTIES = {
  STATUS: 'status',
  ASSIGNEE: 'assignee',
};

const hasStatusChanged = (oldCard, newCard) => (
  get(oldCard, 'task.status') !== get(newCard, 'task.status')
);

const hasAssigneeChanged = (oldCard, newCard) => (
  get(oldCard, 'task.assignee.id') !== get(newCard, 'task.assignee.id')
);

const getStatusMessage = (newCard) => {
  const newStatus = get(newCard, 'task.status');
  const { label: statusLabel } = STATUS_TYPES[newStatus];

  return `Task status has been updated to ${statusLabel}`;
};

const getAssigneeMessage = (newCard) => {
  const newAssigneeName = get(newCard, 'task.assignee.fullName');

  return `Task has been reassigned to ${newAssigneeName}`;
};

export const getPropertyChanged = (oldCard, newCard) => {
  if (hasStatusChanged(oldCard, newCard)) {
    return PROPERTIES.STATUS;
  }

  if (hasAssigneeChanged(oldCard, newCard)) {
    return PROPERTIES.ASSIGNEE;
  }

  return null;
};

export const getUndoMessage = (property, card) => {
  if (property === PROPERTIES.STATUS) {
    return getStatusMessage(card);
  }

  if (property === PROPERTIES.ASSIGNEE) {
    return getAssigneeMessage(card);
  }

  return '';
};

export const getUndoPayload = (propertyChanged, card) => {
  if (propertyChanged === PROPERTIES.STATUS) {
    return {
      id: get(card, 'task.id'),
      status: get(card, 'task.status'),
    };
  }

  if (propertyChanged === PROPERTIES.ASSIGNEE) {
    return {
      id: get(card, 'task.id'),
      assignee_id: get(card, 'task.assignee.id'),
    };
  }

  return {};
};
