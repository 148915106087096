import React from 'react';
import PropTypes from 'prop-types';

import {
  Bar,
  Line,
  Radar,
  WordCloud,
} from '../Charts';
import ChartContainer from '../ChartContainer';
import {
  ACTIVITY_OVER_TIME,
  MOST_LOBBIED_BILLS,
  MOST_LOBBIED_REGULATIONS,
  ACTIVITY_TYPE_BREAKDOWN,
  MOST_ACTIVE_COLLEAGUES,
  MOST_ENGAGED_LEGISLATORS,
  MOST_ENGAGED_STAFFERS,
  MOST_REFERENCED_COMMITTEES,
  MOST_USED_TAGS,
} from '../../constants';
import styles from './styles.module.scss';

function Container({ showDashboard, isLoading, data }) {
  if (!showDashboard) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <ChartContainer
          isLoading={isLoading}
          isEmpty={data.activityOverTime.isEmpty}
          title={ACTIVITY_OVER_TIME.title}
          questionText={ACTIVITY_OVER_TIME.questionText}
        >
          <Line
            data={data.activityOverTime}
            colors={ACTIVITY_OVER_TIME.colors}
            tooltip={ACTIVITY_OVER_TIME.tooltip}
          />
        </ChartContainer>
      </div>
      <div className={styles.row}>
        <ChartContainer
          isLoading={isLoading}
          isEmpty={data.mostLobbiedBills.isEmpty}
          title={MOST_LOBBIED_BILLS.title}
        >
          <Bar
            data={data.mostLobbiedBills}
            colors={MOST_LOBBIED_BILLS.colors}
            tooltip={MOST_LOBBIED_BILLS.tooltip}
          />
        </ChartContainer>
        <ChartContainer
          isLoading={isLoading}
          isEmpty={data.mostLobbiedRegulations.isEmpty}
          title={MOST_LOBBIED_REGULATIONS.title}
        >
          <Bar
            data={data.mostLobbiedRegulations}
            colors={MOST_LOBBIED_REGULATIONS.colors}
            tooltip={MOST_LOBBIED_REGULATIONS.tooltip}
          />
        </ChartContainer>
      </div>
      <div className={styles.row}>
        <ChartContainer
          isLoading={isLoading}
          isEmpty={data.activityTypeBreakdown.isEmpty}
          title={ACTIVITY_TYPE_BREAKDOWN.title}
        >
          <Radar
            data={data.activityTypeBreakdown}
            colors={ACTIVITY_TYPE_BREAKDOWN.colors}
            tooltip={ACTIVITY_TYPE_BREAKDOWN.tooltip}
          />
        </ChartContainer>
        <ChartContainer
          isLoading={isLoading}
          isEmpty={data.mostActiveColleagues.isEmpty}
          title={MOST_ACTIVE_COLLEAGUES.title}
        >
          <Bar
            data={data.mostActiveColleagues}
            colors={MOST_ACTIVE_COLLEAGUES.colors}
            tooltip={MOST_ACTIVE_COLLEAGUES.tooltip}
          />
        </ChartContainer>
      </div>
      <div className={styles.row}>
        <ChartContainer
          isLoading={isLoading}
          isEmpty={data.mostEngagedLegislators.isEmpty}
          title={MOST_ENGAGED_LEGISLATORS.title}
        >
          <Bar
            data={data.mostEngagedLegislators}
            colors={MOST_ENGAGED_LEGISLATORS.colors}
            tooltip={MOST_ENGAGED_LEGISLATORS.tooltip}
          />
        </ChartContainer>
        <ChartContainer
          isLoading={isLoading}
          isEmpty={data.mostEngagedStaffers.isEmpty}
          title={MOST_ENGAGED_STAFFERS.title}
        >
          <Bar
            data={data.mostEngagedStaffers}
            colors={MOST_ENGAGED_STAFFERS.colors}
            tooltip={MOST_ENGAGED_STAFFERS.tooltip}
          />
        </ChartContainer>
      </div>
      <div className={styles.row}>
        <ChartContainer
          isLoading={isLoading}
          isEmpty={data.mostReferencedCommittees.isEmpty}
          title={MOST_REFERENCED_COMMITTEES.title}
        >
          <Bar
            data={data.mostReferencedCommittees}
            colors={MOST_REFERENCED_COMMITTEES.colors}
            tooltip={MOST_REFERENCED_COMMITTEES.tooltip}
          />
        </ChartContainer>
        <ChartContainer
          isLoading={isLoading}
          isEmpty={data.mostUsedTags.isEmpty}
          title={MOST_USED_TAGS.title}
        >
          <WordCloud
            data={data.mostUsedTags.data}
          />
        </ChartContainer>
      </div>
    </div>
  );
}

const dataShape = PropTypes.shape({
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  isEmpty: PropTypes.bool.isRequired,
});

const tagShape = PropTypes.shape({
  text: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
});

Container.propTypes = {
  showDashboard: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    activityOverTime: dataShape,
    mostLobbiedBills: dataShape,
    mostLobbiedRegulations: dataShape,
    activityTypeBreakdown: dataShape,
    mostActiveColleagues: dataShape,
    mostEngagedLegislators: dataShape,
    mostEngagedStaffers: dataShape,
    mostReferencedCommittees: dataShape,
    mostUsedTags: PropTypes.shape({
      isEmpty: PropTypes.bool.isRequired,
      data: PropTypes.arrayOf(tagShape),
    }),
  }),
};

export default Container;
