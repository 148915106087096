import React from 'react';
import PropTypes from 'prop-types';
import { Line as LineChart } from 'react-chartjs-2';
import moment from 'moment';

import {
  BASE_GRID_LINES,
  BASE_TICK,
  BASE_BORDER_TOP_GRID,
  BASE_BORDER_RIGHT_GRID,
} from 'utils/constants/charts';

import { addGradient, getSuggestedMax } from './utils';

function Line({ data, colors, tooltip }) {
  const chartData = {
    labels: data.labels,
    datasets: [{
      data: data.values,
      pointRadius: 0,
      tension: 0,
    }],
  };

  return (
    <LineChart
      data={addGradient(chartData, colors)}
      options={{
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                ...BASE_TICK,
                beginAtZero: true,
                padding: 0,
                fontSize: 12,
                userCallback(label) {
                  return moment(label).format('MMM');
                },
              },
              barPercentage: 0.7,
              categoryPercentage: 0.7,
              gridLines: {
                display: false,
              },
            },
            BASE_BORDER_TOP_GRID,
          ],
          yAxes: [
            {
              ticks: {
                ...BASE_TICK,
                beginAtZero: true,
                maxTicksLimit: 5,
                padding: 10,
                fontSize: 12,
                stepSize: 1,
                suggestedMax: getSuggestedMax(data.values),
              },
              gridLines: BASE_GRID_LINES,
            },
            BASE_BORDER_RIGHT_GRID,
          ],
        },
        tooltips: {
          displayColors: false,
          titleAlign: 'center',
          titleFontStyle: 'bold',
          titleFontFamily: 'Roboto',
          bodyAlign: 'center',
          bodyFontStyle: 'normal',
          bodyFontFamily: 'Roboto',
          yPadding: 8,
          xPadding: 12,
          backgroundColor: '#FFFFFF',
          titleFontColor: '#000000',
          bodyFontColor: '#000000',
          footerFontColor: '#000000',
          intersect: false,
          callbacks: {
            title(tooltipItem, { labels }) {
              const { value, index } = tooltipItem[0];
              const label = moment(labels[index]).format('MMM YYYY');

              return tooltip.title({ value, label });
            },
            label(tooltipItem) {
              const { value, label } = tooltipItem;

              return tooltip.label({ value, label });
            },
          },
        },
      }}
    />
  );
}

Line.defaultProps = {
  data: {
    labels: [],
    values: [],
  },
  colors: {
    top: '#FCAE72',
    bottom: '#F3F11F',
    border: '#E6B566',
  },
  tooltip: {
    title: ({ label }) => label,
    label: ({ value }) => value,
  },
};

Line.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    values: PropTypes.arrayOf(PropTypes.number).isRequired,
  }),
  colors: PropTypes.shape({
    top: PropTypes.string.isRequired,
    bottom: PropTypes.string.isRequired,
    border: PropTypes.string.isRequired,
  }),
  tooltip: PropTypes.shape({
    title: PropTypes.func.isRequired,
    label: PropTypes.func.isRequired,
  }),
};

export default Line;
