import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function Item({ label, color, icon, isChecked }) {
  const iconColor = isChecked ? styles[color] : styles.grey;

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <i className={classNames(icon, iconColor)} />
      </div>
      <div className={styles.label}>{label}</div>
    </div>
  );
}

Item.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
};

export default Item;
