import { formatGlobalDate } from 'utils/date';

function useFormatValue(startDate, endDate) {
  if (startDate && endDate) {
    return `${formatGlobalDate(startDate)} - ${formatGlobalDate(endDate)}`;
  }

  if (startDate) {
    return `from ${formatGlobalDate(startDate)}`;
  }

  if (endDate) {
    return `until ${formatGlobalDate(endDate)}`;
  }

  return 'All dates';
}

export default useFormatValue;
