import { useState, useEffect } from 'react';

function useCommentsDrawer({ cardId }) {
  const [isLoading, setIsLoading] = useState(true);

  const handleSetShowLoader = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
  }, [cardId]);

  return {
    isLoading,
    handleSetShowLoader,
    showComments: !!cardId,
  };
}

export default useCommentsDrawer;
