import React from 'react';
import PropTypes from 'prop-types';

import Avatar from 'components/core/Avatar';

import styles from './styles.module.scss';

function AssignContent({ fullName, photoUrl }) {
  return (
    <>
      {fullName}
      <Avatar
        name={fullName}
        className={styles.avatar}
        photoUrl={photoUrl}
      />
    </>
  );
}

AssignContent.propTypes = {
  fullName: PropTypes.string.isRequired,
  photoUrl: PropTypes.string,
};

AssignContent.defaultProps = {
  photoUrl: '',
};

export default AssignContent;
