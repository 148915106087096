import { includes } from 'lodash';

import useActivityEffect from 'components/Grm2/events/useActivityEffect';
import {
  CARD_UPDATED,
  CARDS_UPDATED,
  CARD_CREATED,
  TASK_BOARD_NAMESPACE,
} from 'components/Grm2/events/constants';
import { mergeCards, isAlreadyUpdated } from 'components/Grm2/utils';

function shouldUpdateTask(cards, uiCard) {
  if (isAlreadyUpdated(cards, uiCard)) {
    return false;
  }

  return true;
}

function useUpdateTask({ cards, updateCard }) {
  useActivityEffect((event, namespace, payload) => {
    const isNew = event === CARD_CREATED;
    const tasksUpdated = includes([CARD_UPDATED, CARD_CREATED], event)
      ? [payload]
      : mergeCards(payload); // CARDS_UPDATED

    tasksUpdated.forEach(({ reduxCard, uiCard }) => {
      shouldUpdateTask(cards, uiCard) && updateCard(reduxCard, isNew, false);
    });
  }, [CARD_UPDATED, CARDS_UPDATED, CARD_CREATED], [TASK_BOARD_NAMESPACE]);
}

export default useUpdateTask;
