import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ButtonBadge } from 'components/core/Badge';
import { NewTask } from 'components/Grm2/common/NewCard';
import { cardShape, filtersShape, uiShape } from 'components/Grm2/shapes';
import { CARD_SIZES } from 'components/Grm2/constants';
import Button from 'components/core/Button';
import Drawer, { Header, Body, Footer } from 'components/core/Drawer';
import Feed from 'components/Grm2/common/Feed';
import LockBanner from 'components/Grm2/common/LockBanner';
import reduxConnect from 'components/Grm2/reduxConnect';
import AppliedFiltersLabel from 'components/Grm2/common/AppliedFiltersLabel';
import { taskCompareFunction } from 'components/Grm2/utils';
import initialState from 'redux/grm2/initialState';

import useBadge from '../../useBadge';
import useSetupTasks from '../../useSetupTasks';
import useTasksButton from '../../useTasksButton';
import useTasksContent from '../../useTasksContent';
import useEmptyMessage from '../../useEmptyMessage';
import useUpdateTasks from '../../useUpdateTasks';
import useTaskFilters from '../../useTaskFilters';
import Filters from '../Filters';
import TaskBoardLink from '../TaskBoardLink';
import Transition from '../Transition';
import Undo, { useUndo } from '../Undo';
import styles from './styles.module.scss';

function Container({
  cards,
  ui,
  filters,
  className,
  setFilters,
  setPerPage,
  setIsTasksContext,
  fetchCards,
  setSort,
  setNamespace,
  updateCard,
  updateDataItem,
  removeCardById,
  onClick,
}) {
  const {
    newTaskInitialValues,
  } = useSetupTasks({
    setFilters,
    setPerPage,
    setIsTasksContext,
    fetchCards,
    setSort,
    setNamespace,
  });

  const {
    showBadge,
    numberOfTasks,
  } = useBadge({ cards });

  const {
    filterCardsBy,
    onStatusChange,
    status,
  } = useTasksContent();

  const {
    onNewTaskClick,
    toggleDrawer,
    isDrawerOpen,
    toggleNewTaskModalOpen,
    isNewTaskModalOpen,
    isBannerEnabled,
    onTasksButtonClick,
  } = useTasksButton(onClick);

  const emptyMessage = useEmptyMessage(status);

  useUpdateTasks({
    cards,
    fetchCards,
    updateCard,
    removeCardById,
    updateDataItem,
  });

  const {
    showUndo,
    undoDisabled,
    onUndoClick,
    undoMessage,
  } = useUndo({ updateCard });
  const {
    resetTaskFilters,
    searchFilters,
    resultsCount,
  } = useTaskFilters({ filters, setFilters, initialState, cards, status });
  const { isLoading } = ui;

  const showV2NavigationTasksButton = gon.isNavigationV2Enabled;

  return (
    <>
      <div className={className} data-test="my-tasks-button">
        <LockBanner enabled={isBannerEnabled}>
          <Button
            theme="secondary"
            size="medium"
            type="button"
            onClick={onTasksButtonClick}
            className={classNames({ [styles.button]: !showV2NavigationTasksButton, [styles.v2Button]: showV2NavigationTasksButton })}
          >
            {showV2NavigationTasksButton ? (
              <i className={classNames('fas', 'fa-check-circle', styles.tasksIcon)} />
            ) : (
              <span>Tasks</span>
            )}
          </Button>
        </LockBanner>
        {showBadge && (
          <ButtonBadge theme="red">
            {numberOfTasks}
          </ButtonBadge>
        )}
      </div>

      <Drawer isOpen={isDrawerOpen} data-test="my-tasks-drawer">
        <Header
          title="My tasks"
          onCloseClick={toggleDrawer}
        />

        <Body className={styles.body}>
          <Filters status={status} onStatusChange={onStatusChange} />
          <AppliedFiltersLabel
            isLoading={isLoading}
            totalCount={resultsCount}
            singularActivityWord="task"
            pluralActivitiesWord="tasks"
            filters={searchFilters}
            resetFilters={resetTaskFilters}
            wrapperClassName={styles.filterLabelWrapper}
          />

          <Transition status={status}>
            <Feed
              cardSize={CARD_SIZES.medium}
              priorityIconOnly
              showCommentsInDrawer
              showCheckbox={false}
              emptyMessage={emptyMessage}
              filterCardsBy={filterCardsBy}
              cardsCompareFunction={taskCompareFunction}
              className={styles.feed}
            />
          </Transition>

          {showUndo && (
            <Undo
              onClick={onUndoClick}
              message={undoMessage}
              disabled={undoDisabled}
            />
          )}
        </Body>

        <Footer className={styles.footer}>
          <Button
            theme="tertiary"
            className={styles.newTaskButton}
            onClick={onNewTaskClick}
          >
            + Task
          </Button>
          <TaskBoardLink closeDrawer={toggleDrawer} />
        </Footer>
      </Drawer>

      {isNewTaskModalOpen && (
        <NewTask
          onClose={toggleNewTaskModalOpen}
          initialValues={newTaskInitialValues}
        />
      )}
    </>
  );
}

Container.propTypes = {
  cards: PropTypes.arrayOf(cardShape),
  ui: uiShape.isRequired,
  filters: filtersShape.isRequired,
  className: PropTypes.string,
  setFilters: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  updateDataItem: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  setIsTasksContext: PropTypes.func.isRequired,
  fetchCards: PropTypes.func.isRequired,
  setNamespace: PropTypes.func.isRequired,
  updateCard: PropTypes.func.isRequired,
  removeCardById: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

export default reduxConnect(Container);
