import { useState, useEffect } from 'react';

import { getSystemFields, findPage } from 'components/Grm2/NewNoteForNavigation/utils';
import { isGrmEnabled } from 'components/Grm2/utils';
import { getCardLinkType } from 'redux/grm2/transformers/toBE/cardLinks';

export function getTimelineDefaultFilters(recordId) {
  const { page, id } = findPage();
  const { recordType } = page || {};

  const timelineFilters = [{ type: getCardLinkType(recordType), id: recordId || id }];

  return timelineFilters;
}

function useActivityButton({ pagination, setTimelineDefaultFilters, recordId = '' }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isNewNoteModalOpen, setIsNewNoteModalOpen] = useState(false);

  useEffect(() => {
    const timelineDefaultFilters = getTimelineDefaultFilters(recordId);

    setTimelineDefaultFilters(timelineDefaultFilters);
  }, [recordId]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleNewNoteModalOpen = () => {
    setIsNewNoteModalOpen(!isNewNoteModalOpen);
  };

  const handleNewNoteClick = () => {
    setIsDrawerOpen(false);
    setIsNewNoteModalOpen(true);
  };

  const showFeed = isGrmEnabled;
  const showFooter = isGrmEnabled;
  const showContactCSX = !isGrmEnabled;
  const numberOfActivities = pagination.totalCount;
  const showBadge = isGrmEnabled && !!numberOfActivities;

  const initialValues = {
    ...getSystemFields(recordId),
  };

  return {
    isDrawerOpen,
    toggleDrawer,
    isNewNoteModalOpen,
    toggleNewNoteModalOpen,
    showBadge,
    showFeed,
    showContactCSX,
    showFooter,
    numberOfActivities,
    initialValues,
    handleNewNoteClick,
  };
}

export default useActivityButton;
