import React from 'react';
import PropTypes from 'prop-types';
import { Bar as BarChart } from 'react-chartjs-2';
import { isEmpty } from 'lodash';

import {
  BASE_GRID_LINES,
  BASE_TICK,
} from 'utils/constants/charts';

import {
  addGradient,
  transformBigStrings,
  getSuggestedMax,
} from './utils';

function Bar({ data, colors, tooltip }) {
  const chartData = {
    labels: data.labels,
    datasets: [{
      data: data.values,
    }],
  };

  return (
    <BarChart
      data={addGradient(chartData, colors)}
      options={{
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              display: false,
              barPercentage: 0.7,
              categoryPercentage: 0.7,
            },
            {
              position: 'top',
              ticks: {
                display: false,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                ...BASE_TICK,
                beginAtZero: true,
                maxTicksLimit: 4,
                padding: 10,
                fontSize: 12,
                stepSize: 1,
                suggestedMax: getSuggestedMax(data.values),
              },
              gridLines: BASE_GRID_LINES,
            },
            {
              position: 'right',
              ticks: {
                display: false,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        tooltips: {
          displayColors: false,
          titleAlign: 'center',
          titleFontStyle: 'normal',
          titleFontFamily: 'Roboto',
          bodyAlign: 'center',
          bodyFontStyle: 'bold',
          bodyFontFamily: 'Roboto',
          yPadding: 8,
          xPadding: 12,
          backgroundColor: '#FFFFFF',
          titleFontColor: '#000000',
          bodyFontColor: '#000000',
          footerFontColor: '#000000',
          intersect: false,
          mode: 'index',
          callbacks: {
            title(tooltipItem) {
              const { value, label } = tooltipItem[0];

              return tooltip.title({ value, label });
            },
            label(tooltipItem) {
              const { value, label } = tooltipItem;

              return transformBigStrings(tooltip.label({ value, label }));
            },
          },
        },
        onClick(event, activeElements) {
          if (isEmpty(activeElements)) {
            return;
          }

          if (isEmpty(data.urls)) {
            return;
          }

          const index = activeElements[0]._index;
          const url = data.urls[index];

          if (!url) {
            return;
          }

          window.location.href = url;
        },
        onHover(event, activeElements) {
          if (isEmpty(data.urls)) {
            return;
          }

          if (isEmpty(activeElements)) {
            event.currentTarget.style.cursor = '';
          } else {
            event.currentTarget.style.cursor = 'pointer';
          }
        },
      }}
    />
  );
}

Bar.defaultProps = {
  data: {
    labels: [],
    values: [],
    urls: [],
  },
  colors: {
    top: '#FCAE72',
    bottom: '#F3F11F',
    border: '#E6B566',
  },
  tooltip: {
    title: ({ label }) => label,
    label: ({ value }) => value,
  },
};

Bar.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    values: PropTypes.arrayOf(PropTypes.number).isRequired,
    urls: PropTypes.arrayOf(PropTypes.string),
  }),
  colors: PropTypes.shape({
    top: PropTypes.string.isRequired,
    bottom: PropTypes.string.isRequired,
    border: PropTypes.string.isRequired,
  }),
  tooltip: PropTypes.shape({
    title: PropTypes.func.isRequired,
    label: PropTypes.func.isRequired,
  }),
};

export default Bar;
