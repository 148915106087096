import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import classNames from 'classnames';

import Input from 'components/core/Input';
import Label from 'components/core/Label';
import Asterisk from 'components/core/Asterisk';
import { getError } from 'utils/forms';

import Tooltip from './Tooltip';
import styles from './styles.module.scss';

function InputField({
  name,
  title,
  testClassName,
  placeholder,
  isRequired,
}) {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <div className={styles.container}>
          <div className={styles.field}>
            <Label className={styles.label}>
              {title}
              <Asterisk show={isRequired} />
              <Tooltip content={placeholder} theme="light" />
            </Label>

            <Input
              {...field}
              className={classNames(
                testClassName,
                styles.input,
              )}
              errorMessage={getError(name, form)}
            />
          </div>
        </div>
      )}
    </Field>
  );
}

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  testClassName: PropTypes.string,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
};

export default InputField;
