import { useState } from 'react';

function useNewTask() {
  const [showNewTaskModal, setShowNewTaskModal] = useState(false);

  const handleClick = () => {
    setShowNewTaskModal(true);
  };

  const handleClose = () => {
    setShowNewTaskModal(false);
  };

  return {
    showNewTaskModal,
    onNewTaskClick: handleClick,
    onNewTaskClose: handleClose,
  };
}

export default useNewTask;
