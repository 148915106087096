import { isEmpty } from 'lodash';

import { PAGES } from './constants';

function getPage(pathName) {
  const page = PAGES.find(({ path }) => pathName.toLowerCase().indexOf(path) > -1);

  return page;
}

function getPageId(page, pathName) {
  if (!page || !page.idPositionInUrl) {
    return '';
  }

  return pathName.split('/')[page.idPositionInUrl];
}

export function findPage() {
  const { pathname: pathName } = window.location;

  const page = getPage(pathName);
  const id = getPageId(page, pathName);

  return { page, id };
}

export function getSystemFields(recordId) {
  const { page, id } = findPage();
  if (isEmpty(id)) {
    return {};
  }

  const { fieldName, recordType } = page;

  return {
    [fieldName]: [{
      record_id: recordId || id,
      record_type: recordType,
    }],
  };
}
