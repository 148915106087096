import React from 'react';

export const TaskBoardContext = React.createContext();

function withTaskBoard(Component) {
  return function TaskBoardComponent(props) {
    return (
      <TaskBoardContext.Consumer>
        {contexts => <Component {...props} {...contexts} />}
      </TaskBoardContext.Consumer>
    );
  };
}

export default withTaskBoard;
