import React from 'react';
import PropTypes from 'prop-types';
import jump from 'jump.js';

import CorePaginator from 'components/core/Paginator';
import reduxConnect from 'components/Grm2/reduxConnect';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';
import { uiShape, paginationShape } from 'components/Grm2/shapes';

import usePaginator from './usePaginator';
import styles from './styles.module.scss';

function Paginator({ pagination, ui, setPage }) {
  const { paginatorProps, isPaginatorPresent } = usePaginator(pagination);
  const { isMobile } = useMediaQuery();

  if (!isPaginatorPresent) {
    return null;
  }

  const handleChange = (page) => {
    jump('body', { duration: 500 });
    setPage(page);
  };

  return (
    <div className={styles.container}>
      <CorePaginator
        {...paginatorProps}
        isLoading={ui.isLoading}
        isMobile={isMobile}
        onChange={handleChange}
        className={styles.paginator}
      />
    </div>
  );
}

Paginator.propTypes = {
  pagination: paginationShape.isRequired,
  ui: uiShape.isRequired,
  setPage: PropTypes.func.isRequired,
};

export default reduxConnect(Paginator);
