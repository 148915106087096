import { useState } from 'react';

function useCommentsDrawer() {
  const [cardId, setCardId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const onShowCommentsInDrawerClick = (newCardId) => {
    setCardId(newCardId);
    setIsOpen(true);
  };

  const onCommentsDrawerClose = () => {
    setIsOpen(false);
    setCardId(null);
  };

  return {
    commentsDrawerCardId: cardId,
    onCommentsDrawerClose,
    isCommentsDrawerOpen: isOpen,
    onShowCommentsInDrawerClick,
  };
}

export default useCommentsDrawer;
