const titles = {
  grm: {
    title: 'Activity',
    customBreadcrumb: 'Intelligence / Activity',
    id: 'grm',
  },
  tasks: {
    title: 'My tasks',
    customBreadcrumb: 'Intelligence / Activity / My Tasks',
    id: 'tasks',
  },
};

export default titles;
