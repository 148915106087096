import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TagInput from 'components/core/TagInput';
import { getAutosuggesterOptionValue } from 'components/core/Select/utils';

import styles from '../styles.module.scss';

function Regulations({ value, onChange }) {
  const handleChange = (newValue) => {
    onChange('regulations', newValue);
  };

  return (
    <TagInput
      strategy="regulation"
      onChange={handleChange}
      overrideValue={value}
      shouldOverrideValue
      getOptionValue={getAutosuggesterOptionValue}
      groupClassName={classNames(
        'test__filter-regulations-input',
        styles.item,
      )}
      withRichOption
    />
  );
}

Regulations.defaultProps = {
  value: [],
};

Regulations.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
  })),
  onChange: PropTypes.func.isRequired,
};

export default Regulations;
