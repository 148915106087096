import isEmpty from 'lodash/isEmpty';

import useModal from 'components/core/Modal/useModal';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';

function useBulkExport({ cards, isExportOpen }) {
  const { isMobile } = useMediaQuery();
  const { isOpenModal, toggleModal } = useModal(isExportOpen);

  const showBulkExport = !isMobile;

  const isBulkExportDisabled = isEmpty(cards);

  return {
    showBulkExport,
    isBulkExportDisabled,
    isOpenBulkExportModal: isOpenModal,
    onBulkExportClick: toggleModal,
  };
}

export default useBulkExport;
