import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import CoreComments from 'components/core/Comments';

import { applyCardLinks } from './api';
import styles from './styles.module.scss';

function Comments({
  cardId,
  updateCommentsCount,
  highlightedCommentId,
  highlightedParentId,
  updateCard,
  loadAll,
  setShowLoader,
  className,
}) {
  const handleDelete = ({ replies_count }) => {
    const deletedRecords = -1 - (replies_count || 0);
    updateCommentsCount(cardId, deletedRecords);
  };

  const updateLinks = (mentions) => {
    if (!isEmpty(mentions)) {
      applyCardLinks(cardId, mentions)
        .then(card => updateCard(card));
    }
  };
  const handleSave = (response, mentions) => {
    updateLinks(mentions);
    updateCommentsCount(cardId, 1);
  };
  const handleUpdate = (response, mentions) => updateLinks(mentions);

  const hideLoader = () => setShowLoader(false);

  return (
    <CoreComments
      className={classNames(styles.commentFeed, className)}
      parentId={cardId}
      parentType="grm_v2_card"
      onLoadComments={hideLoader}
      onSaveComment={handleSave}
      onUpdateComment={handleUpdate}
      onDeleteComment={handleDelete}
      onSaveReply={handleSave}
      onUpdateReply={handleUpdate}
      onDeleteReply={handleDelete}
      highlightedCommentId={highlightedCommentId}
      highlightedParentId={highlightedParentId}
      shouldLoadAllComments={loadAll}
    />
  );
}

Comments.defaultProps = {
  loadAll: false,
};

Comments.propTypes = {
  cardId: PropTypes.number.isRequired,
  loadAll: PropTypes.bool,
  highlightedCommentId: PropTypes.string,
  updateCommentsCount: PropTypes.func.isRequired,
  highlightedParentId: PropTypes.string,
  updateCard: PropTypes.func.isRequired,
  setShowLoader: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default Comments;
