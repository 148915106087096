import React from 'react';
import { filter } from 'lodash';

import Button from 'components/core/Button';
import { taskCompareFunction } from 'components/Grm2/utils';

import styles from '../styles.module.scss';
import {
  OPEN,
  IN_PROGRESS,
  CLOSED,
  COLUMN_STATUS,
  COLUMN_HEADER_PROPS,
  COLUMN_FOOTER_PROPS,
  COLUMN_EXTRA_PROPS,
} from './constants';

const buildFooterProps = ({ column, status, buildFooterUrl }) => (
  COLUMN_FOOTER_PROPS[column] && {
    href: buildFooterUrl(status),
    ...COLUMN_FOOTER_PROPS[column],
  }
);

const renderOpenEmptyPlaceholderButton = (status, createHandleAddClick) => (
  <Button theme="tableLink" onClick={createHandleAddClick(status)} className={styles.emptyPlaceholderButton}>
    Click here
  </Button>
);

function getEmptyPlaceholderContent({ column, status, createHandleAddClick }) {
  switch (column) {
    case OPEN:
      return (
        <>There are no open tasks assigned to you. {renderOpenEmptyPlaceholderButton(status, createHandleAddClick)} to create one.</>
      );
    case IN_PROGRESS:
      return (
        'There are no in-progress tasks assigned to you. Drag a task from the open column to indicate it is in-progress.'
      );
    case CLOSED:
      return (
        'There are no recent closed tasks assigned to you. Drag a task from the in-progress column to indicate that work on it has been completed.'
      );
    default:
      return null;
  }
}

export function buildColumnData({ column, displayedTasks, buildFooterUrl, createHandleAddClick }) {
  const status = COLUMN_STATUS[column];

  return {
    status,
    tasks: displayedTasks[status],
    headerProps: {
      count: displayedTasks[status].length,
      onAddClick: createHandleAddClick(status),
      ...COLUMN_HEADER_PROPS[column],
    },
    footerProps: buildFooterProps({ column, status, buildFooterUrl }),
    emptyPlaceholderContent: getEmptyPlaceholderContent({ column, status, createHandleAddClick }),
    ...COLUMN_EXTRA_PROPS[column],
  };
}

export {
  OPEN,
  IN_PROGRESS,
  CLOSED,
};

const filterTasks = (tasks, status) => (
  filter(tasks, ['task.status', status])
    .sort(taskCompareFunction)
);

export const splitCards = tasks => ({
  [OPEN]: filterTasks(tasks, OPEN),
  [IN_PROGRESS]: filterTasks(tasks, IN_PROGRESS),
  [CLOSED]: filterTasks(tasks, CLOSED),
});
