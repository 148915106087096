import { useState, useRef } from 'react';

function useUndoView() {
  const [isShowingUndo, setIsShowingUndo] = useState(false);
  const timerId = useRef(0);

  const hideUndo = () => {
    setIsShowingUndo(false);

    timerId.current && clearTimeout(timerId.current);
    timerId.current = 0;
  };

  const showUndo = () => {
    // Clear state in case there is already an undo being displayed
    hideUndo();

    setIsShowingUndo(true);

    timerId.current = setTimeout(() => {
      setIsShowingUndo(false);
    }, 10000);
  };

  return {
    isShowingUndo,
    showUndo,
    hideUndo,
  };
}

export default useUndoView;
