import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TagInput from 'components/core/TagInput';
import { getAutosuggesterOptionValue } from 'components/core/Select/utils';

import { documentsShape } from '../../shapes';
import styles from '../styles.module.scss';

function Documents({ value, onChange }) {
  const handleChange = (newValue) => {
    onChange('documents', newValue);
  };

  return (
    <TagInput
      strategy="documents"
      onChange={handleChange}
      overrideValue={value}
      shouldOverrideValue
      withRichOption
      getOptionValue={getAutosuggesterOptionValue}
      groupClassName={classNames(
        'test__filter-documents-input',
        styles.item,
      )}
    />
  );
}

Documents.defaultProps = {
  value: [],
};

Documents.propTypes = {
  value: PropTypes.arrayOf(documentsShape),
  onChange: PropTypes.func.isRequired,
};

export default Documents;
