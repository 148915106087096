import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Drawer, { Header, Body, Footer } from 'components/core/Drawer';
import Button from 'components/core/Button';
import { ButtonBadge } from 'components/core/Badge';
import reduxConnect from 'components/Grm2/reduxConnect';
import { NewNote } from 'components/Grm2/common/NewCard';
import { CARD_SIZES } from 'components/Grm2/constants';
import { paginationShape, cardShape, filtersShape, uiShape } from 'components/Grm2/shapes';
import Feed from 'components/Grm2/common/Feed';
import Banner from 'components/core/Banner';
import AppliedFiltersLabel from 'components/Grm2/common/AppliedFiltersLabel';
import { stringNumberType } from 'utils/shapes';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import { BANNER_CSX_MESSAGE } from '../../constants';
import useActivityButton from '../../useActivityButton';
import useUpdateActivity from '../../useUpdateActivity';
import useSetupActivity from '../../useSetupActivity';
import Filters from '../Filters';
import LabelButton from '../LabelButton';
import IconButton from '../IconButton';
import styles from './styles.module.scss';

function ActivityButton({
  cards,
  pagination,
  setTimelineDefaultFilters,
  setFilters,
  setNamespace,
  updateCard,
  className,
  filters,
  isIconButton,
  ui,
  resetFilters,
  recordId,
  hasArrowBack,
}) {
  const { creationDate } = filters;

  const {
    isDrawerOpen,
    toggleDrawer,
    isNewNoteModalOpen,
    toggleNewNoteModalOpen,
    showBadge,
    showFeed,
    showContactCSX,
    showFooter,
    numberOfActivities,
    handleNewNoteClick,
    initialValues,
  } = useActivityButton({ pagination, setTimelineDefaultFilters, recordId });

  const { isLoading } = ui;
  const { totalCount, currentPage } = pagination;

  const { isMobile } = useMediaQuery();

  const handleNavigateBack = () => {
    window.history.back();
  };

  const isFederalRegulations = window.location.href.includes('/federal/regulations/');
  const isMobileV2 = gon.isNavigationV2Enabled && isMobile;

  const buttonComponent = isIconButton
    ? <IconButton active={showBadge} onClick={toggleDrawer} />
    : <LabelButton onClick={toggleDrawer} />;

  useSetupActivity({ setNamespace });

  useUpdateActivity({ cards, updateCard });

  return (
    <>
      <div className={className}>
        { hasArrowBack && !isFederalRegulations && isMobileV2 && (
          <Button
            theme="light"
            data-spec="nav-back-button"
            onClick={handleNavigateBack}
            className={classNames(styles.buttonBackWrapper)}
          >
            <i className={classNames(styles.icon, 'fas fa-arrow-left')} />
          </Button>
        )}

        {buttonComponent}
        {showBadge && (
          <ButtonBadge
            className={classNames(styles.buttonBadge, isIconButton && styles.iconButtonBadge)}
            theme="primary"
          >
            {numberOfActivities}
          </ButtonBadge>
        )}
      </div>

      <Drawer isOpen={isDrawerOpen} closeDrawer={toggleDrawer}>
        <Header
          title="Activity"
          onCloseClick={toggleDrawer}
        />

        <Body>
          {showFeed && (
            <>
              <Filters setFilters={setFilters} creationDate={creationDate} />
              <AppliedFiltersLabel
                wrapperClassName={styles.filterLabelWrapper}
                isLoading={isLoading}
                totalCount={totalCount}
                currentPage={currentPage}
                filters={filters}
                resetFilters={resetFilters}
              />
              <Feed
                cardSize={CARD_SIZES.medium}
                priorityIconOnly
                showCommentsInDrawer
                showCheckbox={false}
              />
            </>
          )}

          {showContactCSX && <Banner message={BANNER_CSX_MESSAGE} />}
        </Body>

        {showFooter && (
          <Footer className={styles.footer}>
            <Button
              theme="tertiary"
              className={styles.newNoteButton}
              onClick={handleNewNoteClick}
            >
              + Note
            </Button>
          </Footer>
        )}
      </Drawer>

      {isNewNoteModalOpen && (
        <NewNote
          onClose={toggleNewNoteModalOpen}
          initialValues={initialValues}
        />
      )}
    </>
  );
}

ActivityButton.defaultProps = {
  isIconButton: false,
};

ActivityButton.propTypes = {
  pagination: paginationShape,
  className: PropTypes.string,
  setTimelineDefaultFilters: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  updateCard: PropTypes.func.isRequired,
  setNamespace: PropTypes.func.isRequired,
  isIconButton: PropTypes.bool,
  cards: PropTypes.arrayOf(cardShape),
  filters: filtersShape,
  ui: uiShape.isRequired,
  resetFilters: PropTypes.func.isRequired,
  recordId: stringNumberType,
  hasArrowBack: PropTypes.bool,
};

export default reduxConnect(ActivityButton);
