import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import Select from 'components/core/Select';
import Label from 'components/core/Label';

import { selectOptionShape } from '../shapes';
import Tooltip from './Tooltip';
import styles from './styles.module.scss';

function SelectField({
  name,
  title,
  placeholder,
  testClassName,
  options,
  defaultOption,
  ...restProps
}) {
  return (
    <Field name={name}>
      {({ field }) => {
        const handleChange = (option) => {
          const value = !isEmpty(option) ? option.value : '';

          field.onChange(name)(value);
        };

        return (
          <div className={styles.container}>
            <div className={styles.field}>
              <Label className={styles.label}>
                {title}
                <Tooltip content={placeholder} theme="light" />
              </Label>

              <Select
                {...field}
                className={classNames(
                  testClassName,
                  styles.select,
                )}
                onChange={handleChange}
                options={options}
                defaultValue={defaultOption}
                {...restProps}
              />
            </div>
          </div>
        );
      }}
    </Field>
  );
}

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  testClassName: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(selectOptionShape),
  defaultOption: selectOptionShape,
};

export default SelectField;
