import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import size from 'lodash/size';

import useMediaQuery from 'utils/breakpoints/useMediaQuery';
import { TasksHeader } from 'components/Grm2/common/Header';
import { uiShape, cardShape, filtersShape } from 'components/Grm2/shapes';
import AppliedFiltersLabel from 'components/Grm2/common/AppliedFiltersLabel';

import { defaultFilters } from '../TaskBoard/utils/constants';
import TaskBoard from '../TaskBoard';
import styles from './styles.module.scss';

function Tasks({
  boardUrl,
  listUrl,
  ui,
  cards,
  filters,
  resetFilters,
}) {
  const { isMobile } = useMediaQuery();

  if (isMobile) {
    return <Redirect to={listUrl} />;
  }

  const { isLoading } = ui;
  const totalCount = size(cards);

  return (
    <>
      <TasksHeader boardUrl={boardUrl} listUrl={listUrl} />
      <div className={styles.container}>
        <AppliedFiltersLabel
          isLoading={isLoading}
          totalCount={totalCount}
          singularActivityWord="task"
          pluralActivitiesWord="tasks"
          filters={filters}
          defaultFilters={defaultFilters}
          resetFilters={resetFilters}
        />
        <TaskBoard isMobile={isMobile} />
      </div>
    </>
  );
}

Tasks.propTypes = {
  boardUrl: PropTypes.string.isRequired,
  listUrl: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(cardShape).isRequired,
  ui: uiShape.isRequired,
  filters: filtersShape.isRequired,
  resetFilters: PropTypes.func.isRequired,
};

export default Tasks;
