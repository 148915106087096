import React from 'react';

import reduxConnect from 'components/Grm2/reduxConnect';
import { filtersShape, analyticsShape } from 'components/Grm2/shapes';

import Container from './components/Container';
import useDashboard from './useDashboard';

function Dashboard({ filters, analytics }) {
  const { showDashboard } = analytics.ui;
  const {
    data,
    isLoading,
  } = useDashboard({ filters, showDashboard });

  return (
    <Container
      data={data}
      isLoading={isLoading}
      showDashboard={showDashboard}
    />
  );
}

Dashboard.propTypes = {
  filters: filtersShape,
  analytics: analyticsShape,
};

export default reduxConnect(Dashboard);
