import React from 'react';
import PropTypes from 'prop-types';
import { includes } from 'lodash';

import Link from 'components/core/Link';
import Button from 'components/core/Button';
import { TASK_BOARD_URL } from 'components/Grm2/Tasks/constants';

import styles from './styles.module.scss';

// Update this in case dashboard_v2 is renamed to dashboard

function TaskBoardLink({ closeDrawer }) {
  const isInTaskBoard = includes(window.location.pathname, TASK_BOARD_URL);

  const commonProps = {
    theme: 'link',
    className: styles.taskBoardLink,
  };

  if (isInTaskBoard) {
    return (
      <Button {...commonProps} onClick={closeDrawer}>
        View all in task board
      </Button>
    );
  }

  return (
    <Link {...commonProps} href={TASK_BOARD_URL}>
      View all in task board
    </Link>
  );
}

TaskBoardLink.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
};

export default TaskBoardLink;
