import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/core/Button';

import styles from './styles.module.scss';

function IconButton({ onClick, className, active }) {
  return (
    <Button
      theme="none"
      size="none"
      className={classNames(className, styles.button)}
      onClick={onClick}
    >
      <i className={classNames(
        styles.icon,
        active && styles.active,
        'fas fa-heart-rate'
      )}
      />
    </Button>
  );
}

IconButton.defaultProps = {
  active: false,
};

IconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  active: PropTypes.bool,
};

export default IconButton;
