import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import TimePicker from 'components/core/TimePicker';
import Label from 'components/core/Label';
import Asterisk from 'components/core/Asterisk';
import { DAY_YEAR_DATE_FORMAT } from 'utils/constants/date';
import { currentDate, formatTime } from 'utils/date';

import styles from './styles.module.scss';

function TimeField({
  name,
  title,
  testClassName,
  isDisabled,
  placeholder,
}) {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const handleChange = time => field.onChange(name)(formatTime(time));

        const selectedDate = isEmpty(field.value)
          ? null
          : moment(currentDate(DAY_YEAR_DATE_FORMAT).concat(' ', field.value)).toDate();

        const isDisabledInput = isDisabled(form);

        return (
          <div className={styles.container}>
            <div className={styles.field}>
              <Label className={styles.label}>
                {title}
                <Asterisk show={!isDisabledInput} />
              </Label>

              <TimePicker
                value={selectedDate}
                onChange={handleChange}
                className={classNames(
                  testClassName,
                  styles.timePicker,
                )}
                isDisabled={isDisabledInput}
                placeholder={placeholder}
              />
            </div>
          </div>
        );
      }}
    </Field>
  );
}
TimeField.defaultProps = {
  testClassName: '',
  isDisabled: () => false,
};

TimeField.propTypes = {
  name: PropTypes.string.isRequired,
  testClassName: PropTypes.string,
  placeholder: PropTypes.string,
  title: PropTypes.string.isRequired,
  isDisabled: PropTypes.func,
};

export default TimeField;
