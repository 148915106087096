import React from 'react';
import PropTypes from 'prop-types';
import TippyTooltip from '@tippyjs/react';

import Banner from 'components/core/Banner';

import { BANNER_CSX_MESSAGE } from './constants';
import useLockBanner from './useLockBanner';
import styles from './styles.module.scss';

function LockBanner({ children, enabled, wrapperClassName }) {
  const {
    visible,
    onClickOutside,
    onWrapperClickCapture,
  } = useLockBanner();

  if (!enabled) {
    return children;
  }

  return (
    <TippyTooltip
      content={<Banner message={BANNER_CSX_MESSAGE} />}
      theme="light"
      placement="bottom"
      className={styles.tooltip}
      interactive
      visible={visible}
      onClickOutside={onClickOutside}
      appendTo={document.body}
    >
      <span className={wrapperClassName} onClickCapture={onWrapperClickCapture}>
        {children}
      </span>
    </TippyTooltip>
  );
}

LockBanner.propTypes = {
  children: PropTypes.node,
  enabled: PropTypes.bool.isRequired,
  wrapperClassName: PropTypes.string,
};

export default LockBanner;
