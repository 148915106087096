import { useRef, useState } from 'react';

import { updateTaskCard } from 'components/Grm2/api';
import useActivityEffect from 'components/Grm2/events/useActivityEffect';
import { CARD_CHANGED, ACITIVITY_FOR_NAVIGATION_NAMESPACE } from 'components/Grm2/events/constants';

import useUndoView from './useUndoView';

import {
  getPropertyChanged,
  getUndoMessage,
  getUndoPayload,
} from './utils';

function useUndo({ updateCard }) {
  const {
    isShowingUndo,
    showUndo,
    hideUndo,
  } = useUndoView();

  const undoMessage = useRef('');
  const undoPayload = useRef({});
  const [undoDisabled, setUndoDisabled] = useState(false);

  const handleUndoClick = () => {
    setUndoDisabled(true);

    updateTaskCard(undoPayload.current)
      .then(task => updateCard(task, true))
      .finally(() => {
        hideUndo();
        setUndoDisabled(false);
      });
  };

  useActivityEffect((event, namespace, payload) => {
    if (namespace !== ACITIVITY_FOR_NAVIGATION_NAMESPACE) {
      return;
    }

    const { oldCard, newCard } = payload;

    const propertyChanged = getPropertyChanged(oldCard, newCard);

    if (propertyChanged) {
      undoMessage.current = getUndoMessage(propertyChanged, newCard);
      undoPayload.current = getUndoPayload(propertyChanged, oldCard);
      showUndo();
    }
  }, [CARD_CHANGED]);

  return {
    undoDisabled,
    showUndo: isShowingUndo,
    onUndoClick: handleUndoClick,
    undoMessage: undoMessage.current,
  };
}

export default useUndo;
