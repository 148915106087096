import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { map, concat, join } from 'lodash';

import LabelGroup from 'components/core/LabelGroup';
import { CARD_SIZES } from 'components/Grm2/constants';

import { cardSizeShape } from '../../shapes';
import styles from './styles.module.scss';

const sizeThemes = {
  large: styles.large,
  small: styles.small,
  medium: styles.medium,
};

function CustomFields({ customFields, size }) {
  return (
    <LabelGroup className={styles.item} containerClassName={styles.container}>
      {map(customFields, ({ label, value, token }) => (
        <div key={token} className={styles.customField}>
          <p className={classNames(styles.label, sizeThemes[size])}>
            {concat(label, ' ')}
          </p>
          <span className={classNames(styles.value, sizeThemes[size])}>
            {join(value, ', ')}
          </span>
        </div>
      ))}
    </LabelGroup>
  );
}

CustomFields.propTypes = {
  customFields: PropTypes.arrayOf(
    PropTypes.shape({
      token: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ),
  size: cardSizeShape,
};

CustomFields.defaultProps = {
  size: CARD_SIZES.large,
};

export default CustomFields;
