import { useState } from 'react';
import { size } from 'lodash';

import notification from 'components/Grm2/notifications';
import { CARD_TYPES } from 'components/Grm2/constants';

function useApplyTags({ cardIds, onSave, cardType, isMultipleItems }) {
  const [isLoading, setIsLoading] = useState(false);
  const { label = '' } = CARD_TYPES[cardType] || {};

  const handleSave = ({ tags }) => {
    setIsLoading(true);

    const onSuccess = (response) => {
      isMultipleItems
        ? notification.updateMultipleCardsTagSuccess(size(cardIds))
        : notification.updateCardTagSuccess(label);

      return response;
    };

    return onSave({ cardIds, tags })
      .then(onSuccess)
      .catch(notification.generalFailNotification)
      .finally(() => setIsLoading(false));
  };

  return {
    isLoading,
    handleSave,
  };
}

export default useApplyTags;
