import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { isEmpty, map, every, includes } from 'lodash';

import SearchBox from 'components/core/SearchBox';
import { CheckboxButton } from 'components/core/Checkbox';
import reduxConnect from 'components/Grm2/reduxConnect';
import { filtersShape, cardShape, customFieldShape } from 'components/Grm2/shapes';
import { getEnabledCards } from 'components/Grm2/utils';

import VisualizationButton from '../VisualizationButton';
import ActionsButton from '../ActionsButton';
import styles from './styles.module.scss';

function SearchHeaderDesktop({
  filters,
  setFilter,
  selectCards,
  unselectCards,
  cards,
  selectedCardIds,
  submitExport,
  resetSelectedCards,
  customFields,
  isExportOpen,
}) {
  const [keywordLookup, setKeywordLookup] = useState(filters.keywordLookup);
  const enabledCards = getEnabledCards(cards);
  const isAllCardsSelected = every(enabledCards, card => includes(selectedCardIds, card.id));
  const isSelectAllChecked = !isEmpty(enabledCards) && isAllCardsSelected;

  useEffect(() => {
    setKeywordLookup(filters.keywordLookup);
  }, [filters.keywordLookup]);

  const handleChange = ({ target } = {}) => {
    const { value } = target || {};
    setKeywordLookup(value);
  };

  const handleSubmit = () => {
    resetSelectedCards();
    setFilter('keywordLookup', keywordLookup);
  };

  const handleSelectAll = () => {
    const cardIds = map(enabledCards, 'id');

    return isSelectAllChecked ? unselectCards(cardIds) : selectCards(cardIds);
  };

  return (
    <div className={styles.container}>
      <div className={styles.groupSearch}>
        <SearchBox
          value={keywordLookup}
          onChange={handleChange}
          onSubmit={handleSubmit}
          buttonLabel="Search"
          className={styles.searchBox}
          inputClassName={styles.searchInput}
          withIcon
          withButton
        />
      </div>

      <VisualizationButton />
      <div className={styles.rightButtonGroup}>
        <CheckboxButton
          label="Select all"
          onChange={handleSelectAll}
          isChecked={isSelectAllChecked}
          isDisabled={isEmpty(enabledCards)}
          className={classNames(styles.checkboxButton, 'test__select-all-checkbox')}
        />
        <ActionsButton
          cards={enabledCards}
          filters={filters}
          submitExport={submitExport}
          customFields={customFields}
          isExportOpen={isExportOpen}
        />
      </div>
    </div>
  );
}

SearchHeaderDesktop.propTypes = {
  filters: filtersShape.isRequired,
  setFilter: PropTypes.func.isRequired,
  selectCards: PropTypes.func.isRequired,
  unselectCards: PropTypes.func.isRequired,
  cards: PropTypes.arrayOf(cardShape).isRequired,
  selectedCardIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  submitExport: PropTypes.func.isRequired,
  resetSelectedCards: PropTypes.func.isRequired,
  customFields: PropTypes.arrayOf(customFieldShape),
  isExportOpen: PropTypes.bool.isRequired,
};

export default reduxConnect(SearchHeaderDesktop);
