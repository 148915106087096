import React from 'react';
import PropTypes from 'prop-types';

import { Container as Card, useCard } from 'components/Grm2/common/Card';
import { cardShape, linksPreviewShape } from 'components/Grm2/shapes';
import { CARD_SIZES } from 'components/Grm2/constants';

import withTaskBoard from '../../utils/withTaskBoard';

function Task({
  task,
  setFilter,
  updateCard,
  updateCardById,
  onSaveTags,
  updateCommentsCount,
  onOpenTaskModal,
  updateDataItem,
  isLoadingMyFeedDocuments,
  onShowCommentsInDrawerClick,
  cardsLinkPreview,
}) {
  const { id: taskId } = task;
  const handleEditClick = () => onOpenTaskModal({ id: taskId });

  const cardProps = useCard({
    id: task.id,
    selectedCardIds: [],
    showCheckbox: false,
    setFilter,
    onSaveTags,
    updateCard,
  });

  return (
    <Card
      {...cardProps}
      onEditClick={handleEditClick}
      updateCommentsCount={updateCommentsCount}
      isSingleCard={false}
      priorityIconOnly
      size={CARD_SIZES.small}
      showStatusButton={false}
      updateDataItem={updateDataItem}
      updateCardById={updateCardById}
      isLoadingMyFeedDocuments={isLoadingMyFeedDocuments}
      showCommentsInDrawer
      onShowCommentsInDrawerClick={onShowCommentsInDrawerClick}
      linkPreview={cardsLinkPreview[taskId]}
      {...task}
    />
  );
}

Task.propTypes = {
  task: cardShape.isRequired,
  setFilter: PropTypes.func.isRequired,
  updateCard: PropTypes.func.isRequired,
  onSaveTags: PropTypes.func.isRequired,
  updateCommentsCount: PropTypes.func.isRequired,
  onOpenTaskModal: PropTypes.func.isRequired,
  updateDataItem: PropTypes.func.isRequired,
  updateCardById: PropTypes.func.isRequired,
  isLoadingMyFeedDocuments: PropTypes.bool.isRequired,
  onShowCommentsInDrawerClick: PropTypes.func.isRequired,
  cardsLinkPreview: linksPreviewShape.isRequired,
};

export default withTaskBoard(Task);
