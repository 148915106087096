import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { TextPlaceholder, TagsPlaceholder } from 'components/core/Placeholder';
import CardGroup from 'components/core/CardGroup';

import styles from './styles.module.scss';

function CardPlaceholder({ className, contentClassName, isCheckboxPresent }) {
  const emptyFunction = () => {};

  return (
    <CardGroup
      className={classNames(styles.card, className)}
      headerContentClassName={styles.headerContent}
      contentClassName={contentClassName}
      isChecked={false}
      isCheckboxPresent={isCheckboxPresent}
      isCheckboxDisabled
      toggleCheckbox={emptyFunction}
    >
      <div className={styles.row}>
        <div className={styles.left}>
          <TextPlaceholder className={styles.title} />
          <TextPlaceholder className={styles.date} />
        </div>
        <div className={styles.avatar} />
      </div>
      <div className={styles.description}>
        <TextPlaceholder className={styles.line1} />
        <TextPlaceholder className={styles.line2} />
      </div>
      <TagsPlaceholder itemsNumber={3} tagClassName={styles.tag} />
    </CardGroup>
  );
}

CardPlaceholder.defaultProps = {
  isCheckboxPresent: true,
};

CardPlaceholder.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  isCheckboxPresent: PropTypes.bool,
};

export default CardPlaceholder;
