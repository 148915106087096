function usePaginator(pagination) {
  const paginatorProps = {
    current: pagination.currentPage,
    total: pagination.totalPages,
    totalItems: pagination.totalCount,
    pageSize: pagination.perPage,
  };

  const isPaginatorPresent = pagination.totalPages > 1;

  return {
    paginatorProps,
    isPaginatorPresent,
  };
}

export default usePaginator;
