import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TagInput from 'components/core/TagInput';
import { getAutosuggesterOptionValue } from 'components/core/Select/utils';

import styles from '../styles.module.scss';

function Bills({ value, onChange }) {
  const handleChange = (newValue) => {
    onChange('bills', newValue);
  };

  return (
    <TagInput
      strategy="bill"
      onChange={handleChange}
      overrideValue={value}
      shouldOverrideValue
      withRichOption
      getOptionValue={getAutosuggesterOptionValue}
      groupClassName={classNames(
        'test__filter-bills-input',
        styles.item,
      )}
    />
  );
}

Bills.defaultProps = {
  value: [],
};

Bills.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
  })),
  onChange: PropTypes.func.isRequired,
};

export default Bills;
