import { useState, useEffect } from 'react';

function useTaskFilters({ filters, setFilters, initialState, cards, status }) {
  const resetTaskFilters = () => (
    setFilters({
      ...initialState.filters,
      assignee: filters.assignee,
      activityTypes: filters.activityTypes,
      statusTypes: filters.statusTypes,
    })
  );

  const [searchFilters, setSearchFilters] = useState({
    ...initialState.filters,
    creator: filters.creator,
    tags: filters.tags,
  });

  useEffect(() => {
    setSearchFilters(previousState => ({
      ...previousState,
      creator: filters.creator,
      tags: filters.tags,
    }));
  }, [filters]);

  const resultsCount = cards.reduce((acc, card) => (
    acc + (card.task.status === status ? 1 : 0)
  ), 0);

  return {
    resetTaskFilters,
    searchFilters,
    resultsCount,
  };
}

export default useTaskFilters;
