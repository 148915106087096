import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CoreModal, { CloseConfirmation } from 'components/core/Modal';
import Loader from 'components/core/Loader';
import Button from 'components/core/Button';

import styles from './styles.module.scss';

function Modal({
  onClose,
  onSubmit,
  isSubmitting,
  areFilesUploading,
  children,
  hasChanged,
  title,
}) {
  const customIcon = isSubmitting && <Loader size="small" theme="light" onlyIcon />;
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const handleCancel = () => (hasChanged ? handleOpen : onClose);

  const buttons = (
    <>
      <Button
        type="small"
        theme="light"
        size="small"
        onClick={handleCancel()}
      >
        Cancel
      </Button>

      <Button
        type="small"
        size="small"
        onClick={onSubmit}
        disabled={isSubmitting || areFilesUploading}
        customIcon={customIcon}
      >
        Submit
      </Button>
    </>
  );

  return (
    <>
      <CoreModal
        title={title}
        theme="scrollable"
        buttons={buttons}
        toggleOpen={handleCancel()}
        className={styles.modal}
        footerClassName={styles.footer}
        bodyClassName={styles.bodyModal}
        isOpen
      >
        {children}
      </CoreModal>
      {hasChanged && (
        <CloseConfirmation
          isOpen={isOpen}
          toggleOpen={onClose}
          onReturn={handleClose}
          onRequestClose={handleClose}
          onCrossBtnClick={handleClose}
          // WARNING: Dont remove next line, it's a workaround for a react-mentions bug
          // the bug consists in breaking the textarea(by duplicating the whole text)
          // if its focus is triggered by some other component
          shouldReturnFocusAfterClose={false}
        />
      )}
    </>
  );
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  areFilesUploading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  hasChanged: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default Modal;
