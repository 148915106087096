import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';
import useFilters from 'components/Grm2/common/Filters/useFilters';
import reduxConnect from 'components/Grm2/reduxConnect';
import { filtersShape } from 'components/Grm2/shapes';

import styles from './styles.module.scss';

function FilterButton({ onClick, filters, setFilter }) {
  const { appliedFilters } = useFilters({ filters, setFilter });

  return (
    <Button
      theme="tertiary"
      customIcon={<i className="fas fa-filter" />}
      className={styles.button}
      iconClassName={styles.icon}
      onClick={onClick}
    >
      {!!appliedFilters && (
        <span className={styles.badge}>
          {appliedFilters}
        </span>
      )}
    </Button>
  );
}

FilterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  filters: filtersShape.isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default reduxConnect(FilterButton);
