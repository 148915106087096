import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CARD_SIZES } from 'components/Grm2/constants';
import { cardSizeShape } from 'components/Grm2/common/Card/shapes';

import styles from './styles.module.scss';

const sizeThemes = {
  large: styles.large,
  small: styles.small,
  medium: styles.medium,
};

function Description({ children, size }) {
  return (
    <div className={classNames(styles.description, sizeThemes[size])}>
      {children}
    </div>
  );
}

Description.propTypes = {
  children: PropTypes.node.isRequired,
  size: cardSizeShape,
};

Description.defaultProps = {
  size: CARD_SIZES.large,
};

export default Description;
