import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CARD_SIZES } from 'components/Grm2/constants';

import { cardSizeShape } from '../../shapes';
import styles from './styles.module.scss';

const sizeThemes = {
  large: styles.large,
  small: styles.small,
};

function Title({ children, size }) {
  return (
    <div className={classNames(styles.title, sizeThemes[size])}>
      {children}
    </div>
  );
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
  size: cardSizeShape,
};

Title.defaultProps = {
  size: CARD_SIZES.large,
};

export default Title;
