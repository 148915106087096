import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Filters from 'components/Grm2/common/Filters';
import reduxConnect from 'components/Grm2/reduxConnect';
import { filtersShape, cardShape, customFieldShape } from 'components/Grm2/shapes';

import SearchButton from './components/SearchButton';
import FilterButton from './components/FilterButton';
import ActionsButton from '../ActionsButton';
import VisualizationButton from '../VisualizationButton';
import styles from './styles.module.scss';

function SearchHeaderMobile({
  cards,
  filters,
  submitExport,
  customFields,
}) {
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const handleShowFiltersClick = value => () => setShowFilters(value);

  if (showSearchBox) {
    return (
      <div className={styles.container}>
        <SearchButton
          showSearchBox={showSearchBox}
          setShowSearchBox={setShowSearchBox}
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <ActionsButton
        cards={cards}
        filters={filters}
        submitExport={submitExport}
        customFields={customFields}
      />
      <div>
        <VisualizationButton />
        <FilterButton
          onClick={handleShowFiltersClick(true)}
        />
        <SearchButton
          showSearchBox={showSearchBox}
          setShowSearchBox={setShowSearchBox}
        />
      </div>

      {showFilters && (
        <Filters onClose={handleShowFiltersClick(false)} />
      )}
    </div>
  );
}

SearchHeaderMobile.propTypes = {
  filters: filtersShape.isRequired,
  cards: PropTypes.arrayOf(cardShape).isRequired,
  submitExport: PropTypes.func.isRequired,
  customFields: PropTypes.arrayOf(customFieldShape),
};

export default reduxConnect(SearchHeaderMobile);
