import { useState, useEffect } from 'react';
import { isEqual } from 'lodash';

function useFilters({ filters, setFilter, resetSelectedCards }) {
  const [values, setValues] = useState(filters);
  useEffect(() => {
    setValues(filters);
  }, [filters]);
  const handleChange = (field, value) => {
    const isEqualState = isEqual(values[field], value);
    if (isEqualState) return;

    resetSelectedCards();
    setValues(state => ({
      ...state,
      [field]: value,
    }));
    setFilter(field, value);
  };
  const badges = {
    people: values.people.length,
    creator: values.creator.length,
    bills: values.bills.length,
    regulations: values.regulations.length,
    activityTypes: values.activityTypes.length,
    interactionTypes: values.interactionTypes.length,
    committees: values.committees.length,
    documents: values.documents.length,
    tags: values.tags.length,
    eventDate: !!values.eventDate.startDate + !!values.eventDate.endDate,
    dueDate: !!values.dueDate.startDate + !!values.dueDate.endDate,
    statusTypes: values.statusTypes.length,
    priorityTypes: values.priorityTypes.length,
    assignee: values.assignee.length,
    creationDate: !!values.creationDate.startDate + !!values.creationDate.endDate,
    attachments: values.attachments ? 1 : 0,
  };
  const appliedFilters = Object.values(badges).reduce((prev, curr) => prev + curr, 0);
  return {
    handleChange,
    values,
    badges,
    appliedFilters,
  };
}
export default useFilters;
