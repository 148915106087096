export function applyCardLinks(cardId, mentions) {
  const url = `/api_web/grm_v2/cards/${cardId}/card_links/bulk_create`;
  const method = 'POST';

  const data = {
    card_links: mentions.map(({ id, type }) => ({
      record_type: type,
      record_id: id,
    })),
  };

  const ajax = $.ajax({
    url,
    method,
    data: JSON.stringify(data),
    contentType: 'application/json',
  });

  return Promise.resolve(ajax);
}
