import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './styles.module.scss';

const CustomSelectValue = ({ data: { label, icon, value } }) => (
  <div>
    <i className={classNames(styles.selectValueIcon, icon, styles[value])} />
    {label}
  </div>
);

CustomSelectValue.propTypes = {
  data: PropTypes.shape({
    icon: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
  }),
};
export default CustomSelectValue;
