import React from 'react';
import PropTypes from 'prop-types';

import DataCard from 'components/core/Profile/components/DataCard';
import { LoadingPlaceholder } from 'components/core/Profile/components/Placeholders';

import styles from './styles.module.scss';

function ChartContainer({ isLoading, isEmpty, title, children, questionText }) {
  const emptyDescription = `
  You have no related activity, or you filtered out all results.
  Create a new note or select the clear all button to remove any filters.`;

  if (isLoading) {
    return (
      <LoadingPlaceholder
        title={title}
        questionText={questionText}
        className={styles.card}
        placeholderClassName={styles.placeholderClassName}
      />
    );
  }

  return (
    <DataCard
      title={title}
      questionText={questionText}
      questionTooltipTheme="light"
      showPlaceholder={isEmpty}
      emptyDescription={emptyDescription}
      className={styles.card}
      placeholderClassName={styles.placeholderClassName}
    >
      <div className={styles.scrollable}>
        <div className={styles.chartContainer}>
          {children}
        </div>
      </div>
    </DataCard>
  );
}

ChartContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  questionText: PropTypes.string,
};

export default ChartContainer;
