import React from 'react';
import PropTypes from 'prop-types';

import ButtonSlideGroup from 'components/core/ButtonSlideGroup';
import { STATUS_TYPES, DEFAULT_STATUS } from 'components/Grm2/constants';
import { updateTaskCard } from 'components/Grm2/api';
import notifications from 'components/Grm2/notifications';

import styles from './styles.module.scss';

function StatusButton({
  id,
  defaultOption,
  onClick,
  isVisible,
}) {
  function handleUpdateStatus({
    currentOption,
    baseParams,
    setIsLoading,
    rollbackOption,
  }) {
    setIsLoading(true);

    return updateTaskCard({ ...baseParams, status: currentOption })
      .then((response) => {
        onClick(response);
        notifications.updateTaskStatusSuccess();

        return response;
      })
      .catch(notifications.generalFailNotification)
      .catch(rollbackOption)
      .finally(() => setIsLoading(false));
  }

  const buttonProps = {
    baseParams: { id },
    initialOption: defaultOption,
    options: STATUS_TYPES,
    handleUpdateOption: handleUpdateStatus,
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.container}>
      <ButtonSlideGroup {...buttonProps} />
    </div>
  );
}

StatusButton.defaultProps = {
  isVisible: true,
  defaultOption: DEFAULT_STATUS,
};

StatusButton.propTypes = {
  id: PropTypes.number,
  defaultOption: PropTypes.string,
  onClick: PropTypes.func,
  isVisible: PropTypes.bool,
};

export default StatusButton;
