import React from 'react';
import PropTypes from 'prop-types';

import Accordion, { Item, CaretTitle } from 'components/core/Accordion';

import { accordionStateShape } from '../../../common/shapes';
import { SYSTEM_FIELDS_KEY, CUSTOM_FIELDS_KEY } from '../../../common/accordion';
import { SystemFields, TopFields, CustomFields } from '../../../common';
import styles from '../../../common/Form/styles.module.scss';

// WARNING
// If you add/remove or change any of the Form Fields,
//
// Please update front/components/Grm2/common/NewCard/common/useNewCard.js -> emptyCard
// Otherwise the CloseConfirmation modal won't work
//
// And also update front/components/Grm2/common/NewCard/utils.js -> systemFields
// Otherwise the SystemFields section won't open by default.

function Form({ hasChanged, setFieldTouched, accordionState }) {
  return (
    <>
      <TopFields />
      <Accordion
        renderTitle={CaretTitle}
        className={styles.accordion}
        isOpen={accordionState.isOpen}
        onToggle={accordionState.handleToggle}
      >
        <Item
          key={SYSTEM_FIELDS_KEY}
          title="System fields"
          className={styles.item}
        >
          <SystemFields />
        </Item>
        <Item
          key={CUSTOM_FIELDS_KEY}
          title="Custom fields"
          className={styles.item}
        >
          <CustomFields hasChanged={hasChanged} setFieldTouched={setFieldTouched} />
        </Item>
      </Accordion>
    </>
  );
}

Form.propTypes = {
  hasChanged: PropTypes.bool.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  accordionState: accordionStateShape.isRequired,
};

export default Form;
