import { getCurrentUser } from 'utils/currentUser';
import { transformTaskStatusForBE } from 'redux/grm2/transformers';

export function updateTaskCard({ id, status, ...task }) {
  const url = `/api_web/grm_v2/tasks/${id}`;

  const data = {
    task: {
      ...(status && { status: transformTaskStatusForBE(status) }),
      ...task,
    },
  };

  const ajax = $.ajax({
    method: 'PUT',
    url,
    data,
  });

  return Promise.resolve(ajax);
}

export function updateTaskCards(state) {
  const url = '/api_web/grm_v2/tasks/bulk_update';
  const { companyId } = getCurrentUser();

  const data = {
    ...state,
    company_id: companyId,
  };

  const ajax = $.ajax({
    method: 'POST',
    url,
    data,
  });

  return Promise.resolve(ajax);
}

export function fetchCard(id) {
  const ajax = $.get(`/api_web/grm_v2/cards/${id}`);

  return Promise.resolve(ajax);
}

export function removeCard(id) {
  const url = `/api_web/grm_v2/cards/${id}/disable`;

  const ajax = $.ajax({
    method: 'POST',
    url,
  });

  return Promise.resolve(ajax);
}

export function removeCards(ids) {
  const url = '/api_web/grm_v2/cards/bulk_disable';
  const data = {
    card_ids: ids,
  };

  const ajax = $.ajax({
    method: 'POST',
    url,
    data,
  });

  return Promise.resolve(ajax);
}

export function undoCard(id) {
  const url = `/api_web/grm_v2/cards/${id}/enable`;

  const ajax = $.ajax({
    method: 'POST',
    url,
  });

  return Promise.resolve(ajax);
}
