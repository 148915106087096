const TITLE_SECTION_ID = 'title';
const DESCRIPTION_COLUMN_ID = 'description';
const TASK_ASSIGNEE_COLUMN_ID = 'task_assignee';
const TASK_DUE_COLUMN_ID = 'task_due';
const TASK_STATUS_COLUMN_ID = 'task_status';
const TASK_PRIORITY_COLUMN_ID = 'task_priority';
const CREATED_AT_ET_COLUMN_ID = 'created_at_et';
const DATE_COLUMN_ID = 'date';
const INTERACTION_TYPE_COLUMN_ID = 'interaction_type';
const TAG_NAMES_COLUMN_ID = 'tag_names';
const COLLEAGUES_COLUMN_ID = 'colleagues';
const FEDERAL_BILLS_COLUMN_ID = 'federal_bills';
const FEDERAL_COMMITTEES_COLUMN_ID = 'federal_committees';
const HILL_DAY_MEETINGS_COLUMN_ID = 'hill_day_meetings';
const HILL_DAYS_COLUMN_ID = 'hill_days';
const GRASSROOTS_CAMPAIGNS_COLUMN_ID = 'grassroots_campaigns';
const FEDERAL_LEGISLATORS_COLUMN_ID = 'federal_legislators';
const FEDERAL_STAFFERS_COLUMN_ID = 'federal_staffers';
const STAKEHOLDERS_COLUMN_ID = 'stakeholders';
const STATE_BILLS_COLUMN_ID = 'state_bills';
const STATE_STAFFERS_COLUMN_ID = 'state_staffers';
const STATE_COMMITEES_COLUMN_ID = 'state_commitees';
const STATE_LEGISLATORS_COLUMN_ID = 'state_legislators';
const STATE_REGULATIONS_COLUMN_ID = 'state_regulations';
const FEDERAL_REGULATIONS_COLUMN_ID = 'federal_regulations';
const CREATED_BY_COLUMN_ID = 'created_by';
const FEDERAL_DOCUMENTS_ID = 'federal_documents';
const STATE_DOCUMENTS_ID = 'state_documents';
const AGENCY_PRESS_RELEASES_ID = 'agency_press_releases';
const CBO_COST_ESTIMATES_ID = 'cbo_cost_estimates';
const COMMITTEE_REPORTS_ID = 'committee_reports';
const CONGRESSIONAL_PRESS_RELEASES_ID = 'congressional_press_releases';
const CONGRESSIONAL_RECORD_ITEMS_ID = 'congressional_record_items';
const CONTRACTS_ID = 'contracts';
const CRS_REPORTS_ID = 'crs_reports';
const DEAR_COLLEAGUE_LETTERS_ID = 'dear_colleague_letters';
const EXECUTIVE_ORDERS_ID = 'executive_orders';
const GAO_REPORTS_ID = 'gao_reports';
const GRANTS_ID = 'grants';
const LIVE_HEARING_TRANSCRIPTS_ID = 'live_hearing_transcripts';
const OFFICIAL_HEARING_TRANSCRIPTS_ID = 'official_hearing_transcripts';
const SAP_REPORTS_ID = 'sap_reports';
const STATE_OF_THE_UNION_ADDRESSES_ID = 'state_of_the_union_addresses';
const TREATY_DOCUMENTS_ID = 'treaty_documents';
const THIRD_PARTY_REPORTS_ID = 'third_party_reports';
const WHITE_HOUSE_PRESS_BRIEFINGS_ID = 'white_house_press_briefings';
const GOVERNOR_ORDERS_ID = 'governor_orders';
const GOVERNOR_PRESS_RELEASES_ID = 'governor_press_releases';
const LOCAL_EXECUTIVES_ID = 'local_executives';

const FEDERAL_DOCUMENTS_VALUES = [
  { id: AGENCY_PRESS_RELEASES_ID, title: 'Agency press releases' },
  { id: CBO_COST_ESTIMATES_ID, title: 'CBO cost estimates' },
  { id: COMMITTEE_REPORTS_ID, title: 'Committee reports' },
  { id: CONGRESSIONAL_PRESS_RELEASES_ID, title: 'Congressional press releases' },
  { id: CONGRESSIONAL_RECORD_ITEMS_ID, title: 'Congressional record items' },
  { id: CONTRACTS_ID, title: 'Contracts' },
  { id: CRS_REPORTS_ID, title: 'CRS reports' },
  { id: DEAR_COLLEAGUE_LETTERS_ID, title: 'Dear Colleague letters' },
  { id: EXECUTIVE_ORDERS_ID, title: 'Executive orders' },
  { id: GAO_REPORTS_ID, title: 'GAO reports' },
  { id: GRANTS_ID, title: 'Grants' },
  { id: LIVE_HEARING_TRANSCRIPTS_ID, title: 'Live hearing transcripts' },
  { id: OFFICIAL_HEARING_TRANSCRIPTS_ID, title: 'Official hearing transcripts' },
  { id: SAP_REPORTS_ID, title: 'SAP reports' },
  { id: STATE_OF_THE_UNION_ADDRESSES_ID, title: 'State of the Union addresses' },
  { id: TREATY_DOCUMENTS_ID, title: 'Treaty documents' },
  { id: THIRD_PARTY_REPORTS_ID, title: 'Third party reports' },
  { id: WHITE_HOUSE_PRESS_BRIEFINGS_ID, title: 'White House press briefings' },
];

const STATE_DOCUMENTS_VALUES = [
  { id: GOVERNOR_ORDERS_ID, title: 'Governor orders' },
  { id: GOVERNOR_PRESS_RELEASES_ID, title: 'Governor press releases' },
];

export const DEFAULT_COLUMN_ID = TITLE_SECTION_ID;
export const FILTERS_TO_STRINGIFY = ['card_links', 'timeline_default_card_links'];

export const EXPORT_TAB_COLUMNS = [
  { id: 'select_all', title: 'Select/Deselect all', isSelectAll: true },
  { id: DEFAULT_COLUMN_ID, title: 'Title', isDisabled: true },
  { id: DESCRIPTION_COLUMN_ID, title: 'Description' },
  { id: TASK_ASSIGNEE_COLUMN_ID, title: 'Task assignee' },
  { id: TASK_DUE_COLUMN_ID, title: 'Task due' },
  { id: TASK_STATUS_COLUMN_ID, title: 'Task status' },
  { id: TASK_PRIORITY_COLUMN_ID, title: 'Task priority' },
  { id: CREATED_AT_ET_COLUMN_ID, title: 'Date of creation' },
  { id: DATE_COLUMN_ID, title: 'Date of event' },
  { id: INTERACTION_TYPE_COLUMN_ID, title: 'Interaction type' },
  { id: TAG_NAMES_COLUMN_ID, title: 'Tags' },
  { id: COLLEAGUES_COLUMN_ID, title: 'Colleagues' },
  { id: FEDERAL_BILLS_COLUMN_ID, title: 'Federal Bills' },
  { id: FEDERAL_COMMITTEES_COLUMN_ID, title: 'Federal Committees' },
  { id: FEDERAL_DOCUMENTS_ID, title: 'Federal Documents', values: FEDERAL_DOCUMENTS_VALUES },
  { id: HILL_DAY_MEETINGS_COLUMN_ID, title: 'Hill Day Meetings' },
  { id: HILL_DAYS_COLUMN_ID, title: 'Hill Days' },
  { id: GRASSROOTS_CAMPAIGNS_COLUMN_ID, title: 'Grassroots Campaigns' },
  { id: FEDERAL_LEGISLATORS_COLUMN_ID, title: 'Federal Legislators' },
  { id: FEDERAL_STAFFERS_COLUMN_ID, title: 'Federal Staffers' },
  { id: FEDERAL_REGULATIONS_COLUMN_ID, title: 'Federal Regulations' },
  { id: STAKEHOLDERS_COLUMN_ID, title: 'Stakeholders' },
  { id: STATE_STAFFERS_COLUMN_ID, title: 'State Staffers' },
  { id: STATE_BILLS_COLUMN_ID, title: 'State Bills' },
  { id: STATE_COMMITEES_COLUMN_ID, title: 'State Committees' },
  { id: STATE_DOCUMENTS_ID, title: 'State Documents', values: STATE_DOCUMENTS_VALUES },
  { id: STATE_LEGISLATORS_COLUMN_ID, title: 'State Legislators' },
  { id: STATE_REGULATIONS_COLUMN_ID, title: 'State Regulations' },
  { id: LOCAL_EXECUTIVES_ID, title: 'Local Executives' },
  { id: CREATED_BY_COLUMN_ID, title: 'Created by' },
];
