import People from './components/People';
import Creator from './components/Creator';
import Bills from './components/Bills';
import Regulations from './components/Regulations';
import ActivityTypes from './components/ActivityTypes';
import PriorityTypes from './components/PriorityTypes';
import InteractionType from './components/InteractionType';
import Committees from './components/Committees';
import Documents from './components/Documents';
import Tags from './components/Tags';
import EventDate from './components/EventDate';
import DueDate from './components/DueDate';
import CreationDate from './components/CreationDate';
import Attachments from './components/Attachments';
import Assignee from './components/Assignee';
import StatusTypes from './components/StatusTypes';

export const FIELDS = {
  people: {
    component: People,
    placeholder: 'Filter your activity by association with individuals (e.g., stakeholders, legislators, staffers)',
    name: 'People',
  },
  creator: {
    component: Creator,
    name: 'Creator',
  },
  bills: {
    component: Bills,
    name: 'Bills',
  },
  regulations: {
    component: Regulations,
    name: 'Regulations',
  },
  committees: {
    component: Committees,
    name: 'Committees',
  },
  documents: {
    component: Documents,
    name: 'Documents',
  },
  activityTypes: {
    component: ActivityTypes,
    name: 'Activity type',
  },
  interactionTypes: {
    component: InteractionType,
    name: 'Interaction type',
  },
  tags: {
    component: Tags,
    placeholder: 'Filter your activity by tag (e.g., show me all activity tagged with “legislation”)',
    name: 'Tags',
  },
  eventDate: {
    component: EventDate,
    name: 'Date of event',
  },
  creationDate: {
    component: CreationDate,
    name: 'Date of creation',
  },
  dueDate: {
    component: DueDate,
    name: 'Due date',
  },
  attachments: {
    component: Attachments,
    name: 'Attachments',
  },
  priorityTypes: {
    component: PriorityTypes,
    name: 'Priority',
  },
  statusTypes: {
    component: StatusTypes,
    name: 'Status',
  },
  assignee: {
    component: Assignee,
    name: 'Assignee',
  },
};

export const urlOptions = ['assignee', 'statusTypes'];
