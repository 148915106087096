export const ACTIVITY_CUSTOM_EVENT_TYPE = 'ACTIVITY';

// Events
// About CARD_CHANGED vs CARD_UPDATED
// - CARD_CHANGED: contains previous and new version of the card in the payload
// - CARD_UPDATED: contains only new version of the card in the payload
export const CARD_CHANGED = 'CARD_CHANGED';
export const CARD_UPDATED = 'CARD_UPDATED';
export const CARDS_UPDATED = 'CARDS_UPDATED';
export const CARD_CREATED = 'CARD_CREATED';
export const DATA_ITEM_UPDATED = 'DATA_ITEM_UPDATED';

// Namespaces
export const ACTIVITY_PAGE_NAMESPACE = 'ACTIVITY_PAGE_NAMESPACE';
export const ACITIVITY_FOR_NAVIGATION_NAMESPACE = 'ACITIVITY_FOR_NAVIGATION_NAMESPACE';
export const ACTIVITIES_DRAWER_NAMESPACE = 'ACTIVITIES_DRAWER_NAMESPACE';
export const TASK_BOARD_NAMESPACE = 'TASK_BOARD_NAMESPACE';
