import { useState, useEffect } from 'react';
import { isNil } from 'lodash';

import { fetchCard } from 'components/Grm2/api';
import notifications from 'components/Grm2/notifications';

const emptyCard = {
  title: '',
  description: '',
  assignee: null,
  uploads: [],
  eventDate: '',
  interactionType: '',
  people: [],
  bills: [],
  regulations: [],
  tags: [],
  committees: [],
  documents: [],
  hillDayActivity: [],
  customFieldsAnswers: {},
};

function useNewCard({ id, customFields, transformCardForBE, transformCardForForm }) {
  const [isLoading, setIsLoading] = useState(true);
  const [card, setCard] = useState(emptyCard);

  const getCard = () => fetchCard(id).then(newCard => transformCardForForm(newCard, customFields));

  const saveCard = (updatedCard) => {
    const data = transformCardForBE(updatedCard);

    const createRequest = ['POST', '/api_web/grm_v2/cards', notifications.createSuccess];
    const updateRequest = ['PUT', `/api_web/grm_v2/cards/${id}`, notifications.updateSuccess];
    const [method, url, successNotification] = updatedCard.id ? updateRequest : createRequest;

    const ajax = $.ajax({
      url,
      method,
      data: JSON.stringify(data),
      contentType: 'application/json',
    });

    return Promise.resolve(ajax)
      .then((response) => {
        successNotification(response.card_type);
        return response;
      })
      .catch(notifications.generalFailNotification);
  };

  useEffect(() => {
    setIsLoading(true);

    if (isNil(id)) {
      setIsLoading(false);
      return;
    }

    getCard(id)
      .then(updatedCard => setCard(updatedCard))
      .finally(() => setIsLoading(false));
  }, [id]);

  return {
    card,
    saveCard,
    isLoading,
  };
}

export default useNewCard;
