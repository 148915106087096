import React from 'react';
import PropTypes from 'prop-types';

import CoreEmptyPlaceholder from 'components/core/EmptyPlaceholder';
import Card from 'components/core/Card';

import styles from './styles.module.scss';

function EmptyPlaceholder({ message }) {
  return (
    <Card className={styles.card}>
      <CoreEmptyPlaceholder
        title="Nothing to show here"
        description={message}
      />
    </Card>
  );
}

const defaultMessage = (
  `You may have no notes or tasks, or you filtered out all your results.
  Create a new note or task or select the clear all button to remove any filters.`
);

EmptyPlaceholder.defaultProps = {
  message: defaultMessage,
};

EmptyPlaceholder.propTypes = {
  message: PropTypes.node,
};

export default EmptyPlaceholder;
