import { useState, useEffect } from 'react';
import { find } from 'lodash';

import { queryStringify } from 'utils/query-string';
import { getCurrentUser } from 'utils/currentUser';
import { taskCompareFunction } from 'components/Grm2/utils';
import { updateTaskCard } from 'components/Grm2/api';
import useAdvancedSearch from 'components/Grm2/useAdvancedSearch';
import notifications from 'components/Grm2/notifications';

import {
  buildColumnData,
  splitCards,
} from './index';
import {
  defaultSort,
  tasksLimit,
  defaultFilters,
  OPEN,
  IN_PROGRESS,
  CLOSED,
} from './constants';

function useTaskBoard({
  cards,
  setIsTasksContext,
  setSort,
  setPerPage,
  setFilters,
  updateCard,
  onOpenTaskModal,
  setAdvancedSearch,
  lastSearchId,
}) {
  const { userId, fullNameWithEmail } = getCurrentUser();

  const fetchCards = () => {
    setIsTasksContext();
    setSort(defaultSort);
    setPerPage(tasksLimit);
    setFilters(defaultFilters)
      .then(() => $(window).scrollTop(0))
      .catch(notifications.generalFailNotification);
  };

  useAdvancedSearch({
    fetchCards,
    setAdvancedSearch,
    lastSearchId,
  });

  const [isLoadingMove, setIsLoadingMove] = useState(false);
  const [displayedTasks, setDisplayedTasks] = useState(splitCards(cards));
  useEffect(() => {
    setDisplayedTasks(splitCards(cards));
  }, [cards]);

  const getTaskById = taskId => find(cards, ({ id }) => id === Number(taskId));
  const placeholderCompareFunction = ({ cardId, draggingCardId }) => (
    taskCompareFunction(getTaskById(draggingCardId), getTaskById(cardId)) <= 0
  );

  const handleMoveCard = ({
    destinationColumnId,
    sourceColumnId,
    cardId,
  }) => {
    const task = getTaskById(cardId);
    setDisplayedTasks(prevProps => ({
      ...prevProps,
      [destinationColumnId]: [task, ...prevProps[destinationColumnId]].sort(taskCompareFunction),
      [sourceColumnId]: prevProps[sourceColumnId].filter(({ id }) => id !== Number(cardId)),
    }));

    setIsLoadingMove(true);
    updateTaskCard({ id: task.task.id, status: destinationColumnId })
      .then(updatedTask => updateCard(updatedTask))
      .finally(() => setIsLoadingMove(false));
  };

  const createHandleAddClick = columnId => () => onOpenTaskModal({ status: columnId });
  const buildFooterUrl = (value) => {
    const currentUserValue = JSON.stringify([{
      label: fullNameWithEmail,
      value: userId,
    }]);
    const params = queryStringify({
      assignee: currentUserValue,
      statusTypes: `["${value}"]`,
    });
    return `/grm?${params}`;
  };

  const columnsCommonProps = {
    displayedTasks,
    createHandleAddClick,
    buildFooterUrl,
  };
  const columnsData = [
    buildColumnData({ column: OPEN, ...columnsCommonProps }),
    buildColumnData({ column: IN_PROGRESS, ...columnsCommonProps }),
    buildColumnData({ column: CLOSED, ...columnsCommonProps }),
  ];

  return {
    columnsData,
    handleMoveCard,
    placeholderCompareFunction,
    isLoadingMove,
  };
}

export default useTaskBoard;
