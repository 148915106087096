import { useState, useEffect } from 'react';

import api from './api';
import { initialDataState } from './constants';

function useDashboard({ filters, showDashboard }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(initialDataState);

  const fetchData = () => {
    setIsLoading(true);

    api.fetchData(filters)
      .then(setData)
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    showDashboard && fetchData();
  }, [filters, showDashboard]);

  return {
    data,
    isLoading,
  };
}

export default useDashboard;
