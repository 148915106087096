import React from 'react';
import PropTypes from 'prop-types';

import ListLinks from './components/ListLinks';

function Links({ links, size }) {
  return (
    links.map(({ label, value }) => (
      <ListLinks
        key={label}
        label={label}
        value={value}
        size={size}
      />
    ))
  );
}

Links.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string,
          id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
          ]).isRequired,
        })
      ),
    })
  ),
};

export default Links;
