import React from 'react';
import PropTypes from 'prop-types';

import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import SearchHeaderDesktop from '../SearchHeaderDesktop';
import SearchHeaderMobile from '../SearchHeaderMobile';

function SearchHeader({ isExportOpen }) {
  const { isMobile } = useMediaQuery();

  return isMobile
    ? <SearchHeaderMobile />
    : <SearchHeaderDesktop isExportOpen={isExportOpen} />;
}

SearchHeader.propTypes = {
  isExportOpen: PropTypes.bool.isRequired,
};

export default SearchHeader;
