import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty, uniqueId } from 'lodash';

import { ListField } from 'components/core/Profile/components/KeyDetailsFields';
import { assigneeShape, cardSizeShape } from 'components/Grm2/common/Card/shapes';
import { CARD_SIZES } from 'components/Grm2/constants';

import AssignContent from '../AssignContent';
import styles from './styles.module.scss';

const sizeThemes = {
  large: styles.large,
  small: styles.small,
  medium: styles.medium,
};

function TaskFields({
  assignee,
  assigner,
  dueDateTime,
  size,
}) {
  const showDueDateTime = !isEmpty(dueDateTime);

  const dueDateFieldItem = [{ id: 'dueDate', label: 'Due Date', content: dueDateTime }];
  const assignFieldItems = [
    {
      id: uniqueId('assigner'),
      label: 'Assigner',
      content: <AssignContent {...assigner} />,
    },
    {
      id: uniqueId('assignee'),
      label: 'Assignee',
      content: <AssignContent {...assignee} />,
    },
  ];

  return (
    <div className={styles.taskFields}>
      <ListField
        isVisible={showDueDateTime}
        withStripedLines
        isLoading={false}
        placeholderItemsCount={1}
        listContentClassName={classNames(
          styles.contentItem,
          sizeThemes[size],
        )}
        className={styles.dueDateField}
        listItemClassName={styles.listItem}
        listLabelClassName={styles.labelItem}
        items={dueDateFieldItem}
      />
      <ListField
        withStripedLines
        isLoading={false}
        placeholderItemsCount={2}
        listContentClassName={classNames(
          styles.contentItem,
          sizeThemes[size],
        )}
        listItemClassName={styles.listItem}
        listLabelClassName={styles.labelItem}
        items={assignFieldItems}
      />
    </div>
  );
}

TaskFields.propTypes = {
  dueDateTime: PropTypes.string,
  assignee: assigneeShape.isRequired,
  assigner: assigneeShape.isRequired,
  size: cardSizeShape,
};

TaskFields.defaultProps = {
  size: CARD_SIZES.large,
};

export default TaskFields;
