import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@tippyjs/react';

import reduxConnect from 'components/Grm2/reduxConnect';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';
import Accordion, { Item, Caret } from 'components/core/Accordion';
import { QuestionMark } from 'components/core/Icons';
import { TOOLTIP_OFFSET } from 'utils/constants';
import { filtersShape } from 'components/Grm2/shapes';
import { getCurrentUserTag } from 'components/Grm2/utils';

import DesktopView from './components/DesktopView';
import MobileView from './components/MobileView';
import { FIELDS } from './constants';
import useFilters from './useFilters';
import useAccordion from './useAccordion';
import styles from './styles.module.scss';

function Filters({
  onClose,
  filters,
  setFilter,
  resetFilters,
  resetSelectedCards,
  isDisabled,
}) {
  const {
    badges,
    handleChange,
    values: fieldValues,
  } = useFilters({
    filters,
    setFilter,
    resetSelectedCards,
  });
  const { isMobile } = useMediaQuery();

  const currentUserTag = getCurrentUserTag();
  const defaultSearchText = {
    label: 'Type to search',
    isDisabled: true,
    showIcon: false,
    optionClassName: styles.optionClassname,
  };

  const defaultOptions = [currentUserTag, defaultSearchText];

  const renderBadge = (field) => {
    if (!badges[field]) {
      return null;
    }

    return ` (${badges[field]})`;
  };

  const renderTooltip = (tooltipContent) => {
    if (!tooltipContent) {
      return null;
    }

    return (
      <div className={styles.tooltip}>
        <Tooltip
          content={tooltipContent}
          theme="light"
          placement="bottom"
          offset={TOOLTIP_OFFSET}
        >
          <QuestionMark className={styles.tooltipIcon} />
        </Tooltip>
      </div>
    );
  };

  const renderTitle = ({ field, isOpen, toggle, isVisible = true }) => (isVisible ? (
    // eslint-disable-next-line
    <div onClick={toggle} className={styles.itemTitle}>
      <Caret isOpen={isOpen} />
      {FIELDS[field].name} {!isDisabled && renderTooltip(FIELDS[field].placeholder)} {renderBadge(field)}
    </div>
  ) : null);

  // eslint-disable-next-line
  const renderContent = ({ field, isVisible = true, ...restProps }) => {
    const fieldValue = fieldValues[field];
    const FieldComponent = FIELDS[field].component;

    if (!isVisible) {
      return null;
    }

    return (
      <div className={styles.contentContainer}>
        <FieldComponent
          value={fieldValue}
          onChange={handleChange}
          {...restProps}
        />
      </div>
    );
  };

  const handleClearFilters = () => {
    resetFilters();
    onClose && onClose();
  };

  const handleApplyFilters = () => {
    onClose && onClose();
  };

  const View = isMobile ? MobileView : DesktopView;
  const { isOpen, handleToggle } = useAccordion({ filters });

  return (
    <View
      onClearFilters={handleClearFilters}
      onApplyFilters={handleApplyFilters}
    >
      <div className={styles.title}>FILTERS</div>
      <Accordion
        renderTitle={renderTitle}
        renderContent={renderContent}
        onToggle={handleToggle}
        isDisabled={isDisabled}
        isOpen={isOpen}
      >
        <Item key="people" field="people" className={styles.item} />
        <Item key="creator" field="creator" defaultOptions={defaultOptions} className={styles.item} />
        <Item key="bills" field="bills" className={styles.item} />
        <Item key="regulations" field="regulations" className={styles.item} />
        <Item key="committees" field="committees" className={styles.item} />
        <Item key="documents" field="documents" className={styles.item} />
        <Item key="activityTypes" field="activityTypes" className={styles.item} />
        <Item key="interactionTypes" field="interactionTypes" className={styles.item} />
        <Item key="tags" field="tags" className={styles.item} />
        <Item key="eventDate" field="eventDate" className={styles.item} />
        <Item key="creationDate" field="creationDate" className={styles.item} />
        <Item key="dueDate" field="dueDate" className={styles.item} />
        <Item key="attachments" field="attachments" className={styles.item} />
        <Item key="statusTypes" field="statusTypes" className={styles.item} />
        <Item key="priorityTypes" field="priorityTypes" className={styles.item} />
        <Item key="assignee" field="assignee" defaultOptions={defaultOptions} className={styles.item} />
      </Accordion>
    </View>
  );
}

Filters.defaultProps = {
  isDisabled: false,
};

Filters.propTypes = {
  onClose: PropTypes.func,
  filters: filtersShape.isRequired,
  setFilter: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  resetSelectedCards: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export default reduxConnect(Filters);
