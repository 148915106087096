import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import SearchBox from 'components/core/SearchBox';
import reduxConnect from 'components/Grm2/reduxConnect';
import { filtersShape } from 'components/Grm2/shapes';

import ToggleButton from '../ToggleButton';
import styles from '../SearchHeaderDesktop/styles.module.scss';

function TasksSearchHeader({ boardUrl, listUrl, filters, setFilter }) {
  const { keywordLookup: defaultKeywordLookup } = filters || {};
  const [keywordLookup, setKeywordLookup] = useState(defaultKeywordLookup);

  useEffect(() => {
    setKeywordLookup(defaultKeywordLookup);
  }, [defaultKeywordLookup]);

  const handleChange = ({ target } = {}) => {
    const { value } = target || {};
    setKeywordLookup(value);
  };

  const handleSubmit = () => {
    setFilter('keywordLookup', keywordLookup, true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.groupSearch}>
        <SearchBox
          value={keywordLookup}
          onChange={handleChange}
          onSubmit={handleSubmit}
          buttonLabel="Search"
          className={styles.searchBox}
          inputClassName={styles.searchInput}
          withIcon
          withButton
        />
      </div>
      <div className={styles.rightButtonGroup}>
        <ToggleButton boardUrl={boardUrl} listUrl={listUrl} />
      </div>
    </div>
  );
}

TasksSearchHeader.propTypes = {
  setFilter: PropTypes.func.isRequired,
  filters: filtersShape.isRequired,
  boardUrl: PropTypes.string.isRequired,
  listUrl: PropTypes.string.isRequired,
};

export default reduxConnect(TasksSearchHeader);
