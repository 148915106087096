import { useEffect, useRef } from 'react';

import { ACTIVITY_CUSTOM_EVENT_TYPE } from './constants';

function useActivityEffect(handler, eventsToListen = [], namespacesToSkip = []) {
  const savedHandler = useRef();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const eventHandler = ({ detail }) => {
      const { event, namespace, payload } = detail;

      if (namespacesToSkip.includes(namespace)) {
        return;
      }

      if (!eventsToListen.includes(event)) {
        return;
      }

      savedHandler.current(event, namespace, payload);
    };

    document.addEventListener(ACTIVITY_CUSTOM_EVENT_TYPE, eventHandler);

    return () => {
      document.removeEventListener(ACTIVITY_CUSTOM_EVENT_TYPE, eventHandler);
    };
  }, []);
}

export default useActivityEffect;
