import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Card from 'components/core/Card';
import Button from 'components/core/Button';
import { CARD_TYPES } from 'components/Grm2/constants';

import styles from './styles.module.scss';

const sizeThemes = {
  large: styles.large,
  small: styles.small,
  medium: styles.medium,
};

function Undo({ onUndo, cardType, isLoading, size }) {
  const typeLabel = CARD_TYPES[cardType].label;
  const text = `${typeLabel} deleted.`;

  return (
    <Card className={classNames(styles.container, sizeThemes[size])}>
      <p className={styles.content}>
        {text}
      </p>
      <Button
        theme="light"
        size="small"
        className={styles.button}
        onClick={onUndo}
        disabled={isLoading}
      >
        Undo
      </Button>
    </Card>
  );
}

Undo.defaultProps = {
  isLoading: false,
  size: 'large',
};

Undo.propTypes = {
  onUndo: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  cardType: PropTypes.string,
  size: PropTypes.string,
};

export default Undo;
