import { includes, isEqual } from 'lodash';

import useActivityEffect from 'components/Grm2/events/useActivityEffect';
import {
  CARD_UPDATED,
  CARDS_UPDATED,
  CARD_CREATED,
  DATA_ITEM_UPDATED,
} from 'components/Grm2/events/constants';
import {
  isAlreadyUpdated,
  isCardPresent,
  isUserTask,
  mergeCards,
} from 'components/Grm2/utils';

function shouldUpdateCard(uiCards, uiCard) {
  if (isAlreadyUpdated(uiCards, uiCard)) {
    return false;
  }

  if (isUserTask(uiCard)) {
    return true;
  }

  return false;
}

function shouldRemoveCard(uiCards, uiCard) {
  const isUserTaskReassignedToAnotherUser = isCardPresent(uiCards, uiCard) && !isUserTask(uiCard);

  if (isUserTaskReassignedToAnotherUser) {
    return true;
  }

  return false;
}

function useUpdateTasks({ cards, updateCard, removeCardById, updateDataItem }) {
  const handleUpdateCards = (event, namespace, payload) => {
    const isNew = event === CARD_CREATED;
    const cardsUpdated = includes([CARD_UPDATED, CARD_CREATED], event)
      ? [payload]
      : mergeCards(payload); // CARDS_UPDATED

    cardsUpdated.forEach(({ reduxCard, uiCard }) => {
      setTimeout(() => {
        shouldUpdateCard(cards, uiCard) && updateCard(reduxCard, isNew, false);
        shouldRemoveCard(cards, uiCard) && removeCardById(reduxCard.id, false);
      }, 0);
    });
  };

  const handleUpdateDataItem = (namespace, payload) => {
    updateDataItem(payload, false);
  };

  useActivityEffect((event, namespace, payload) => {
    if (includes([CARD_UPDATED, CARD_CREATED, CARDS_UPDATED], event)) {
      handleUpdateCards(event, namespace, payload);
      return;
    }

    if (isEqual(event, DATA_ITEM_UPDATED)) {
      handleUpdateDataItem(namespace, payload);
    }
  }, [CARD_UPDATED, CARDS_UPDATED, CARD_CREATED, DATA_ITEM_UPDATED]);
}

export default useUpdateTasks;
