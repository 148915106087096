import { STATUS_TYPES } from 'components/Grm2/constants';

function useEmptyMessage(status) {
  const openEmptyMessage = (
    `You have no open tasks currently assigned to you, good job!
    Create a new task or check the in-progress tab.`
  );

  const inProgressEmptyMessage = (
    `You have no in-progress tasks currently assigned to you.
    Check the open tab or create a new task.`
  );

  const defaultEmptyMessage = (
    'You have no tasks currently assigned to you.'
  );

  const emptyMessage = {
    [STATUS_TYPES.open.value]: openEmptyMessage,
    [STATUS_TYPES.inProgress.value]: inProgressEmptyMessage,
  }[status] || defaultEmptyMessage;

  return emptyMessage;
}

export default useEmptyMessage;
