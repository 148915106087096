import { pluralize } from 'utils/string';

const activity = value => pluralize('activity', 'activities', Number(value));

export const ACTIVITY_OVER_TIME = {
  colors: {
    top: '#FF00007F',
    bottom: '#FFFFFF00',
    border: '#FF0000',
  },
  tooltip: {
    title: ({ label }) => label,
    label: ({ value }) => `${value} ${activity(value)}`,
  },
  title: 'Activity over time',
  questionText: 'GRM activity over time, based on the event date where possible, and creation date where not.',
};

export const MOST_LOBBIED_BILLS = {
  colors: {
    top: '#FCAE72',
    bottom: '#F3F11F',
    border: '#E6B566',
  },
  tooltip: {
    title: ({ value }) => `${value} ${activity(value)} reference`,
    label: ({ label }) => label,
  },
  title: 'Most lobbied bills',
};

export const MOST_LOBBIED_REGULATIONS = {
  colors: {
    top: '#59CCEC',
    bottom: '#2DBED5',
    border: '#0EAFD6',
  },
  tooltip: {
    title: ({ value }) => `${value} ${activity(value)} reference`,
    label: ({ label }) => label,
  },
  title: 'Most lobbied regulations',
};

export const ACTIVITY_TYPE_BREAKDOWN = {
  colors: {
    background: '#1992FFB2',
    border: '#0080F4',
  },
  tooltip: {
    title: ({ value, label }) => `${value} ${label}`,
    label: () => null,
  },
  title: 'Activity type breakdown',
};

export const MOST_ACTIVE_COLLEAGUES = {
  colors: {
    top: '#B581F6',
    bottom: '#7F4AEA',
    border: '#723AE6',
  },
  tooltip: {
    title: ({ value }) => `${value} ${activity(value)} by`,
    label: ({ label }) => label,
  },
  title: 'Most active colleagues',
};

export const MOST_ENGAGED_LEGISLATORS = {
  colors: {
    top: '#1992FF',
    bottom: '#0080F4',
    border: '#005AAD',
  },
  tooltip: {
    title: ({ value }) => `${value} ${activity(value)} w/`,
    label: ({ label }) => label,
  },
  title: 'Most engaged legislators',
};

export const MOST_ENGAGED_STAFFERS = {
  colors: {
    top: '#59ECCA',
    bottom: '#2DD599',
    border: '#29C38D',
  },
  tooltip: {
    title: ({ value }) => `${value} ${activity(value)} w/`,
    label: ({ label }) => label,
  },
  title: 'Most engaged staffers',
};

export const MOST_REFERENCED_COMMITTEES = {
  colors: {
    top: '#FF7231',
    bottom: '#F8390E',
    border: '#FF0000',
  },
  tooltip: {
    title: ({ value }) => `${value} ${activity(value)} reference`,
    label: ({ label }) => label,
  },
  title: 'Most referenced committees',
};

export const MOST_USED_TAGS = {
  colors: {
    top: '#FFC379',
    bottom: '#FFE3B1',
    border: '#FE9F29',
  },
  tooltip: {
    title: ({ value }) => `${value} ${activity(value)} tagged w/`,
    label: ({ label }) => label,
  },
  title: 'Most used tags',
};

const emptyData = {
  labels: [],
  values: [],
  isEmpty: true,
};

const emptyTags = {
  isEmpty: true,
  data: [],
};

export const initialDataState = {
  activityOverTime: emptyData,
  mostLobbiedBills: emptyData,
  mostLobbiedRegulations: emptyData,
  activityTypeBreakdown: emptyData,
  mostActiveColleagues: emptyData,
  mostEngagedLegislators: emptyData,
  mostEngagedStaffers: emptyData,
  mostReferencedCommittees: emptyData,
  mostUsedTags: emptyTags,
};
