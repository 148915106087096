import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { formatBytes } from 'components/core/FileUpload/utils';
import { uploadShape } from 'components/Grm2/common/Card/shapes';

import styles from './styles.module.scss';

function Attachments({ uploads }) {
  if (isEmpty(uploads)) {
    return null;
  }

  return (
    <div className={styles.attachments}>
      {uploads.map(({ file_upload_id: id, file_upload_url: url, name, size }) => (
        <div key={id} className={styles.file}>
          <div className={styles.name}>
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {name}
            </a>
          </div>
          <div className={styles.size}>
            &nbsp;({formatBytes(size)})
          </div>
        </div>
      )).reverse()}
    </div>
  );
}

Attachments.propTypes = {
  uploads: PropTypes.arrayOf(uploadShape),
};

export default Attachments;
