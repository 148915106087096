import { useState } from 'react';

import { isGrmEnabled } from 'components/Grm2/utils';

function useTasksButton(onClick) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isNewTaskModalOpen, setIsNewTaskModalOpen] = useState(false);

  const toggleNewTaskModalOpen = () => {
    setIsNewTaskModalOpen(!isNewTaskModalOpen);
  };

  const handleNewTaskClick = () => {
    setIsDrawerOpen(false);
    setIsNewTaskModalOpen(true);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const isBannerEnabled = !isGrmEnabled;

  const onTasksButtonClick = () => {
    toggleDrawer();
    onClick && onClick();
  };

  return {
    isDrawerOpen,
    toggleDrawer,
    isNewTaskModalOpen,
    toggleNewTaskModalOpen,
    onNewTaskClick: handleNewTaskClick,
    isBannerEnabled,
    onTasksButtonClick,
  };
}

export default useTasksButton;
