import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';
import reduxConnect from 'components/Grm2/reduxConnect';
import { customFieldShape } from 'components/Grm2/shapes';
import useNewActivityCard from 'components/Grm2/useNewActivityCard';
import LockBanner from 'components/Grm2/common/LockBanner';
import withLazy from 'utils/hoc/withLazy';
import { lazyRetry } from 'utils/lazyLoading';

const NewTask = withLazy(
  lazyRetry(() => import('components/Grm2/common/NewCard/Task'))
);

function Container({
  fetchCustomFields,
  customFields,
  trackButtonClicked,
  setNamespace,
  className,
  content,
  onSuccess,
}) {
  const { modal, initialValues, onNewCardClick, isBannerEnabled } = useNewActivityCard({
    customFields,
    fetchCustomFields,
    trackButtonClicked,
    setNamespace,
  });

  return (
    <>
      <LockBanner enabled={isBannerEnabled}>
        <Button
          theme="tertiary"
          className={className}
          onClick={onNewCardClick}
        >
          {content}
        </Button>
      </LockBanner>

      {modal.isOpenModal && (
        <NewTask
          initialValues={initialValues}
          onClose={modal.closeModal}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
}

Container.defaultProps = {
  content: 'Task',
};

Container.propTypes = {
  fetchCustomFields: PropTypes.func.isRequired,
  customFields: PropTypes.arrayOf(customFieldShape).isRequired,
  trackButtonClicked: PropTypes.func,
  setNamespace: PropTypes.func.isRequired,
  className: PropTypes.string,
  content: PropTypes.node,
  onSuccess: PropTypes.func,
};

export default reduxConnect(Container);
