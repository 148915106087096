import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TagInput from 'components/core/TagInput';
import { getAutosuggesterOptionValue } from 'components/core/Select/utils';

import styles from '../styles.module.scss';

function People({ value, onChange }) {
  const handleChange = (newValue) => {
    onChange('people', newValue);
  };

  return (
    <TagInput
      strategy="people"
      onChange={handleChange}
      overrideValue={value}
      shouldOverrideValue
      withRichOption
      getOptionValue={getAutosuggesterOptionValue}
      groupClassName={classNames(
        'test__filter-people-input',
        styles.item,
      )}
    />
  );
}

People.defaultProps = {
  value: [],
};

People.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    party_slug: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
  })),
  onChange: PropTypes.func.isRequired,
};

export default People;
