import { useState } from 'react';
import { includes } from 'lodash';

import useMediaQuery from 'utils/breakpoints/useMediaQuery';

function useCard({
  id,
  selectedCardIds,
  setFilter,
  selectCard,
  unselectCard,
  onSaveTags,
  updateCard,
  showCheckbox,
  onSetFilterInCard,
}) {
  const [showLinks, setShowLinks] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const { isMobile } = useMediaQuery();
  const isCheckboxPresent = !isMobile && showCheckbox;
  const isChecked = includes(selectedCardIds, id);

  const handleAvatarClick = ({ name, id: userId, email }) => {
    onSetFilterInCard && onSetFilterInCard();
    setFilter('creator', [{
      value: userId,
      label: `${name} (${email})`,
    }]);
  };

  const handleTagClick = (tag) => {
    onSetFilterInCard && onSetFilterInCard();
    setFilter('tags', [tag]);
  };

  const handleShowLinksClick = () => {
    setShowLinks(!showLinks);
  };

  const handleEditClick = () => {
    setShowEditModal(true);
  };

  const handleCloseNewNoteModal = () => {
    setShowEditModal(false);
  };

  const handleToggleCheckbox = ({ target = {} } = {}) => {
    const { checked } = target;

    checked ? selectCard(id) : unselectCard(id);
  };

  const handleUpdateCard = card => updateCard(card, false);

  return {
    showLinks,
    showEditModal,
    isCheckboxPresent,
    isChecked,
    onAvatarClick: handleAvatarClick,
    onTagClick: handleTagClick,
    onShowLinksClick: handleShowLinksClick,
    onEditClick: handleEditClick,
    onCloseNewNoteModal: handleCloseNewNoteModal,
    onSaveTagsModal: onSaveTags,
    onToggleCheckbox: handleToggleCheckbox,
    updateCard: handleUpdateCard,
  };
}

export default useCard;
