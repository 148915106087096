import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { map } from 'lodash';

import TagInput from 'components/core/TagInput';
import Label from 'components/core/Label';
import { formatCreateLabel, getNewOptionData, transformTag } from 'components/core/ButtonTagManager/utils.js';

import Tooltip from './Tooltip';
import styles from './styles.module.scss';

function TagManagerField({
  name,
  title,
  strategy,
  placeholder,
  testClassName,
  ...restProps
}) {
  return (
    <Field name={name}>
      {({ field }) => {
        const handleChange = tags => field.onChange(name)(map(tags, transformTag));

        return (
          <div className={styles.container}>
            <div key={title}>
              <Label className={styles.label}>
                {title}
                <Tooltip content={placeholder} theme="light" />
              </Label>

              <TagInput
                strategy={strategy}
                onChange={handleChange}
                defaultRawValues={field.value}
                overrideValue={field.value}
                shouldOverrideValue
                className={testClassName}
                formatCreateLabel={formatCreateLabel}
                getNewOptionData={getNewOptionData}
                {...restProps}
              />
            </div>
          </div>
        );
      }}
    </Field>
  );
}

TagManagerField.propTypes = {
  name: PropTypes.string.isRequired,
  testClassName: PropTypes.string,
  title: PropTypes.string.isRequired,
  strategy: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  strategyInputs: PropTypes.objectOf(PropTypes.bool),
};

export default TagManagerField;
