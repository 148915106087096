import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { createSrcForIcon } from 'components/Grm2/utils';
import { ICONS } from 'components/Grm2/constants';

import styles from './styles.module.scss';

function NewNoteIcon({ color, className, ...rest }) {
  const newNoteIcon = ICONS.newNote;

  return (
    <img
      alt={newNoteIcon.iconName}
      className={classNames(styles.noteImg, className)}
      src={createSrcForIcon(newNoteIcon.iconName, color)}
      {...rest}
    />
  );
}

NewNoteIcon.defaultProps = {
  color: 'blue',
};

NewNoteIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default NewNoteIcon;
