import { useEffect } from 'react';

import { TASK_BOARD_NAMESPACE } from 'components/Grm2/events/constants';

function useSetupTaskBoard({ setNamespace, resetData }) {
  useEffect(() => {
    setNamespace(TASK_BOARD_NAMESPACE);

    return resetData;
  }, []);
}

export default useSetupTaskBoard;
