import { DOCUMENTS } from 'components/core/Select/components/RichOption/constants.js';
import { EXECUTIVE_PERSON_TYPES } from 'redux/grm2/utils/constants';

const {
  AGENCY_PRESS_RELEASE,
  CBO_COST_ESTIMATE,
  COMMITTEE_REPORT,
  CONGRESSIONAL_PRESS_RELEASE,
  CONGRESSIONAL_RECORD_ITEM,
  CONTRACT,
  CRS_REPORT,
  DEAR_COLLEAGUE_LETTER,
  EXECUTIVE_ORDER,
  GAO_REPORT,
  GRANT,
  LIVE_HEARING_TRANSCRIPT,
  OFFICIAL_HEARING_TRANSCRIPT,
  SAP_REPORT,
  STATE_OF_THE_UNION_ADDRESS,
  TREATY_DOCUMENT,
  THIRD_PARTY_REPORT,
  WHITE_HOUSE_PRESS_BRIEFING,
  GOVERNOR_ORDER,
  GOVERNOR_PRESS_RELEASE,
} = DOCUMENTS;

export const PAGES = [
  {
    path: '/federal/congressional_staffers',
    fieldName: 'people',
    recordType: 'federal_staffer',
    idPositionInUrl: 3,
  }, {
    path: '/federal/agency_staffers',
    fieldName: 'people',
    recordType: 'federal_agency_staffer',
    idPositionInUrl: 3,
  }, {
    path: '/federal/legislators',
    fieldName: 'people',
    recordType: 'federal_legislator',
    idPositionInUrl: 3,
  }, {
    path: '/federal/bills',
    fieldName: 'bills',
    recordType: 'federal_bill',
    idPositionInUrl: 3,
  }, {
    path: '/federal/committees',
    fieldName: 'committees',
    recordType: 'federal_committee',
    idPositionInUrl: 3,
  }, {
    path: '/state/staffers',
    fieldName: 'people',
    recordType: 'state_staffer',
    idPositionInUrl: 3,
  }, {
    path: '/state/legislators',
    fieldName: 'people',
    recordType: 'state_legislator',
    idPositionInUrl: 3,
  }, {
    path: '/state/bills',
    fieldName: 'bills',
    recordType: 'state_bill',
    idPositionInUrl: 3,
  }, {
    path: '/state/committees',
    fieldName: 'committees',
    recordType: 'state_committee',
    idPositionInUrl: 3,
  }, {
    path: '/hill_day/hill_days',
    fieldName: 'hillDayActivity',
    recordType: 'hill_day',
    idPositionInUrl: 3,
  }, {
    path: '/stakeholder_management/stakeholder',
    fieldName: 'people',
    recordType: 'stakeholder',
    idPositionInUrl: 3,
  }, {
    path: '/advocates',
    fieldName: 'people',
    recordType: 'stakeholder',
    idPositionInUrl: 2,
  }, {
    path: '/state/regulations',
    fieldName: 'regulations',
    recordType: 'state_regulation',
    idPositionInUrl: 3,
  }, {
    path: '/federal/regulations',
    fieldName: 'regulations',
    recordType: 'federal_regulation',
    idPositionInUrl: 3,
  }, {
    path: '/federal/agency_press_releases',
    fieldName: 'documents',
    recordType: AGENCY_PRESS_RELEASE,
  }, {
    path: '/federal/cbo_reports',
    fieldName: 'documents',
    recordType: CBO_COST_ESTIMATE,
    idPositionInUrl: 5,
  }, {
    path: '/federal/committee_reports',
    fieldName: 'documents',
    recordType: COMMITTEE_REPORT,
    idPositionInUrl: 5,
  }, {
    path: '/federal/congressional_press_releases',
    fieldName: 'documents',
    recordType: CONGRESSIONAL_PRESS_RELEASE,
  }, {
    path: '/federal/congressional_record',
    fieldName: 'documents',
    recordType: CONGRESSIONAL_RECORD_ITEM,
    idPositionInUrl: 5,
  }, {
    path: '/federal/contracts',
    fieldName: 'documents',
    recordType: CONTRACT,
    idPositionInUrl: 3,
  }, {
    path: '/federal/crs_reports',
    fieldName: 'documents',
    recordType: CRS_REPORT,
    idPositionInUrl: 5,
  }, {
    path: '/federal/dear_colleague_letters',
    fieldName: 'documents',
    recordType: DEAR_COLLEAGUE_LETTER,
    idPositionInUrl: 3,
  }, {
    path: '/federal/executive_orders',
    fieldName: 'documents',
    recordType: EXECUTIVE_ORDER,
    idPositionInUrl: 5,
  }, {
    path: '/federal/gao_reports',
    fieldName: 'documents',
    recordType: GAO_REPORT,
    idPositionInUrl: 5,
  }, {
    path: '/federal/grants',
    fieldName: 'documents',
    recordType: GRANT,
    idPositionInUrl: 3,
  }, {
    path: '/federal/live_hearing_transcripts',
    fieldName: 'documents',
    recordType: LIVE_HEARING_TRANSCRIPT,
  }, {
    path: '/federal/official_hearing_transcripts',
    fieldName: 'documents',
    recordType: OFFICIAL_HEARING_TRANSCRIPT,
    idPositionInUrl: 5,
  }, {
    path: '/federal/sap_reports',
    fieldName: 'documents',
    recordType: SAP_REPORT,
    idPositionInUrl: 5,
  }, {
    path: '/federal/state_of_the_union_addresses',
    fieldName: 'documents',
    recordType: STATE_OF_THE_UNION_ADDRESS,
    idPositionInUrl: 5,
  }, {
    path: '/federal/treaties',
    fieldName: 'documents',
    recordType: TREATY_DOCUMENT,
    idPositionInUrl: 5,
  }, {
    path: '/federal/third_party_reports',
    fieldName: 'documents',
    recordType: THIRD_PARTY_REPORT,
    idPositionInUrl: 5,
  }, {
    path: '/federal/white_house_press_briefings',
    fieldName: 'documents',
    recordType: WHITE_HOUSE_PRESS_BRIEFING,
    idPositionInUrl: 5,
  }, {
    path: '/state/governor_orders',
    fieldName: 'documents',
    recordType: GOVERNOR_ORDER,
  }, {
    path: '/state/governor_press_releases',
    fieldName: 'documents',
    recordType: GOVERNOR_PRESS_RELEASE,
  },
  {
    path: '/state/governor_orders',
    fieldName: 'documents',
    recordType: GOVERNOR_ORDER,
  },
  {
    path: '/state/governor_press_releases',
    fieldName: 'documents',
    recordType: GOVERNOR_PRESS_RELEASE,
  },
  {
    path: '/state/executives',
    fieldName: 'people',
    recordType: EXECUTIVE_PERSON_TYPES.STATE_EXECUTIVE,
    idPositionInUrl: 3,
  },
  {
    path: '/local/executives',
    fieldName: 'people',
    recordType: EXECUTIVE_PERSON_TYPES.LOCAL_EXECUTIVE,
    idPositionInUrl: 3,
  },
  {
    path: '/federal/executives',
    fieldName: 'people',
    recordType: EXECUTIVE_PERSON_TYPES.FEDERAL_EXECUTIVE,
    idPositionInUrl: 3,
  },
  {
    path: '/state/agency_staffers',
    fieldName: 'people',
    recordType: EXECUTIVE_PERSON_TYPES.STATE_AGENCY_STAFFER,
    idPositionInUrl: 3,
  },
];

export const NEW_NOTE_FOR_NAVIGATION_DOM_ID = 'new-note-for-nav-container';
