import React from 'react';
import PropTypes from 'prop-types';

import { customFieldDataShape } from 'components/Grm2/shapes';
import { transformCardForForm, transformCardForBE } from 'redux/grm2/transformers';
import reduxConnect from 'components/Grm2/reduxConnect';

import { newNoteValuesShape } from '../common/shapes';
import Container from './components/Container';
import useNewCard from '../common/useNewCard';

function NewNote({ id, onClose, updateCard, customFields, initialValues, onSuccess }) {
  const {
    card,
    saveCard,
    isLoading,
  } = useNewCard({ id, customFields, transformCardForBE, transformCardForForm });

  const handleSuccess = () => {
    if (onSuccess) {
      return onSuccess();
    }

    return onClose();
  };

  const handleSubmit = (updatedNote) => {
    const isNew = !updatedNote.id;

    return saveCard(updatedNote)
      .then(note => updateCard(note, isNew))
      .then(handleSuccess);
  };

  return (
    <Container
      isLoading={isLoading}
      initialValues={{
        ...card,
        ...initialValues,
      }}
      onSubmit={handleSubmit}
      onClose={onClose}
      customFields={customFields}
    />
  );
}

NewNote.defaultProps = {
  initialValues: {},
};

NewNote.propTypes = {
  id: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  updateCard: PropTypes.func.isRequired,
  initialValues: newNoteValuesShape,
  customFields: PropTypes.arrayOf(customFieldDataShape).isRequired,
  onSuccess: PropTypes.func,
};

export default reduxConnect(NewNote);
