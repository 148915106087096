import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { OPEN, IN_PROGRESS } from 'components/Grm2/constants.js';

import { animationDuration } from './settings.module.scss';
import styles from './styles.module.scss';

function Transition({ children, status }) {
  const transitionClassName = status === OPEN ? styles.open : styles.inProgress;
  const timeout = parseInt(animationDuration, 10);

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={status}
        timeout={timeout}
        classNames={{
          exit: classNames(styles.exit, transitionClassName),
          exitActive: classNames(styles.exitActive, transitionClassName),
          enter: classNames(styles.enter, transitionClassName),
          enterActive: classNames(styles.enterActive, transitionClassName),
        }}
      >
        {children}
      </CSSTransition>
    </SwitchTransition>
  );
}

Transition.propTypes = {
  status: PropTypes.oneOf([OPEN, IN_PROGRESS]),
  children: PropTypes.element,
};

export default Transition;
