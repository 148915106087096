// Scale values between a range [a, b]
const scale = (value, min, max, a = 10, b = 100) => (
  ((b - a) * (value - min) / (max - min)) + a
);

// This will guarantee that we are going to have words
// between 16px and 32px independent of
// the value that they can have
export const scaleValues = (data) => {
  const values = data.map(({ value }) => value);
  const min = Math.min(...values);
  const max = Math.max(...values);

  // All tags have same size
  if (max === min) {
    return data.map(props => ({
      ...props,
      value: 50,
    }));
  }

  return data.map(({ value, ...restProps }) => ({
    ...restProps,
    value: scale(value, min, max),
  }));
};
