import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Checkbox from 'components/core/Checkbox';
import LabelGroup from 'components/core/LabelGroup';
import { STATUS_TYPES } from 'components/Grm2/constants';

import Item from './Item';
import { handleCheckboxChange } from '../../utils';
import styles from './styles.module.scss';
import filterComponentStyles from '../styles.module.scss';

function StatusTypes({ value, onChange }) {
  const handleChange = (event) => {
    const newValues = handleCheckboxChange(value, event);

    onChange('statusTypes', newValues);
  };

  // eslint-disable-next-line
  const renderLabel = (props) => (
    <Item {...props} />
  );

  const options = Object.keys(STATUS_TYPES).map(type => ({
    ...STATUS_TYPES[type],
    optionValue: type,
  }));

  const isChecked = optionValue => value.includes(optionValue);

  return (
    <LabelGroup containerClassName={filterComponentStyles.item}>
      {options.map(({ label, optionValue, color, icon }) => (
        <Checkbox
          key={optionValue}
          value={optionValue}
          label={renderLabel({
            label,
            isChecked: isChecked(optionValue),
            color,
            icon,
          })}
          onChange={handleChange}
          isChecked={isChecked(optionValue)}
          className={classNames(
            `test__filter-status-type-${optionValue.replace(/_/g, '-')}`,
            styles.checkbox,
          )}
        />
      ))}
    </LabelGroup>
  );
}

StatusTypes.defaultProps = {
  value: [],
};

StatusTypes.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};

export default StatusTypes;
