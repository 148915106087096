import React from 'react';

import { CommentPlaceholder } from 'components/core/Comments/components/Comment';

import styles from './styles.module.scss';

function CommentsDrawer() {
  return (
    <div className={styles.container}>
      <CommentPlaceholder />
      <CommentPlaceholder />
      <CommentPlaceholder />
      <CommentPlaceholder />
      <CommentPlaceholder />
    </div>
  );
}

export default CommentsDrawer;
