import { useEffect } from 'react';

import { ACTIVITIES_DRAWER_NAMESPACE } from 'components/Grm2/events/constants';

function useSetupActivity({ setNamespace }) {
  useEffect(() => {
    setNamespace(ACTIVITIES_DRAWER_NAMESPACE);
  }, []);
}

export default useSetupActivity;
