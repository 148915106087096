import React from 'react';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';

import Actions from 'components/core/Profile/components/Actions';
import Button from 'components/core/Button';
import { NewNote, NewTask } from 'components/Grm2/common/NewCard';
import { filtersShape, cardShape, customFieldShape } from 'components/Grm2/shapes';
import ExportActivityModal from 'components/Grm2/common/ExportActivityModal';

import useNewNote from './useNewNote';
import useNewTask from './useNewTask';
import useCustomFields from './useCustomFields';
import useBulkExport from './useBulkExport';

function ActionsButton({
  cards,
  filters,
  customFields,
  isExportOpen,
}) {
  const {
    showNewNoteModal,
    onNewNoteClick,
    onNewNoteClose,
  } = useNewNote();

  const {
    showNewTaskModal,
    onNewTaskClick,
    onNewTaskClose,
  } = useNewTask();

  const {
    showBulkExport,
    onBulkExportClick,
    isBulkExportDisabled,
    isOpenBulkExportModal,
  } = useBulkExport({ cards, isExportOpen });

  const {
    showCustomFields,
    onCustomFieldsClick,
  } = useCustomFields();

  const options = filter([
    {
      id: 'newNote',
      component: (
        <Button
          theme="light"
          onClick={onNewNoteClick}
        >
          Create note
        </Button>
      ),
    },
    {
      id: 'newTask',
      component: (
        <Button
          theme="light"
          onClick={onNewTaskClick}
        >
          Create task
        </Button>
      ),
    },
    showBulkExport && {
      id: 'bulkExport',
      component: (
        <>
          <Button
            theme="light"
            onClick={onBulkExportClick}
            disabled={isBulkExportDisabled}
          >
            Bulk export
          </Button>
          <ExportActivityModal
            isOpenModal={isOpenBulkExportModal}
            toggleModal={onBulkExportClick}
            filters={filters}
            customFields={customFields}
          />
        </>
      ),
    },
    showCustomFields && {
      id: 'manageCustomFields',
      component: (
        <Button
          theme="light"
          onClick={onCustomFieldsClick}
        >
          Manage Custom Fields
        </Button>
      ),
    },
  ]);

  return (
    <>
      <Actions
        theme="secondary"
        options={options}
        large
      />

      {showNewNoteModal && (
        <NewNote onClose={onNewNoteClose} />
      )}

      {showNewTaskModal && (
        <NewTask onClose={onNewTaskClose} />
      )}
    </>
  );
}

ActionsButton.defaultProps = {
  isExportOpen: false,
};

ActionsButton.propTypes = {
  filters: filtersShape.isRequired,
  cards: PropTypes.arrayOf(cardShape).isRequired,
  customFields: PropTypes.arrayOf(customFieldShape),
  isExportOpen: PropTypes.bool,
};

export default ActionsButton;
