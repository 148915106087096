import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import FileUpload from 'components/core/FileUpload';
import reduxConnect from 'components/Grm2/reduxConnect';

import styles from './styles.module.scss';

function AttachmentField({ name, testClassName }) {
  return (
    <Field name={name}>
      {({ field }) => (
        <div className={styles.container}>
          <FileUpload
            alreadyAddedFiles={field.value}
            filesUploaded={field.value}
            onChange={field.onChange(name)}
            className={testClassName}
            btnClassName={styles.attachmentBtn}
            listClassName={styles.attachmentList}
          />
        </div>
      )}
    </Field>
  );
}

AttachmentField.propTypes = {
  testClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default reduxConnect(AttachmentField);
