import { useEffect } from 'react';

import { getCurrentUser } from 'utils/currentUser';
import { STATUS_TYPES, OPEN, IN_PROGRESS, TASK_CARD_TYPE } from 'components/Grm2/constants';
import { getSystemFields } from 'components/Grm2/NewNoteForNavigation/utils';
import sortDirections from 'utils/constants/sortDirections';
import { isGrmEnabled } from 'components/Grm2/utils';
import { ACITIVITY_FOR_NAVIGATION_NAMESPACE } from 'components/Grm2/events/constants';

function useSetupTasks({
  setFilters,
  setPerPage,
  setIsTasksContext,
  fetchCards,
  setSort,
  setNamespace,
}) {
  const { userId, fullName, email } = getCurrentUser();

  const tasksLimit = 100;

  const defaultFilters = {
    assignee: [
      { value: userId },
    ],
    activityTypes: [
      TASK_CARD_TYPE,
    ],
    statusTypes: [
      STATUS_TYPES.open.value,
      STATUS_TYPES.inProgress.value,
    ],
  };

  const defaultSort = {
    sort: 'task.priority_created_at',
    direction: sortDirections.DESC,
  };

  useEffect(() => {
    if (!isGrmEnabled) {
      return;
    }

    setIsTasksContext();
    setSort(defaultSort);
    setPerPage(tasksLimit);
    setFilters(defaultFilters, { avoidDispatchFetch: true });
    fetchCards({ statuses: [OPEN, IN_PROGRESS] });
  }, []);

  useEffect(() => {
    setNamespace(ACITIVITY_FOR_NAVIGATION_NAMESPACE);
  }, []);

  const assignee = {
    value: userId,
    label: `${fullName} (${email})`,
  };

  const newTaskInitialValues = {
    ...getSystemFields(),
    assignee,
  };

  return {
    newTaskInitialValues,
  };
}

export default useSetupTasks;
