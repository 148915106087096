import React from 'react';
import PropTypes from 'prop-types';

import reduxConnect from 'components/Grm2/reduxConnect';
import { tagShape, customFieldDataShape, uiShape, linkShape } from 'components/Grm2/shapes';
import { CARD_SIZES } from 'components/Grm2/constants';
import { workspaceShape } from 'redux/events/utils/shapes';

import useCard from './useCard';
import Container from './components/Container';
import {
  userShape,
  linkPreviewShape,
  uploadShape,
  assigneeShape,
} from './shapes';

function Card({
  id,
  isSingleCard,
  title,
  eventDate,
  interactionType,
  creationDate,
  createdBy,
  user,
  task,
  description,
  tags,
  links,
  commentsCount,
  priorityIconOnly,
  customFieldsAnswers,
  selectedCardIds,
  setFilter,
  selectCard,
  unselectCard,
  cardType,
  onSaveTags,
  updateCard,
  updateCardById,
  updateCommentsCount,
  uploads,
  showCheckbox,
  onSetFilterInCard,
  documentType,
  updateDataItem,
  ui,
  size,
  showCommentsInDrawer,
  onShowCommentsInDrawerClick,
  linkPreview,
  isMobile,
  disabled,
  sharings,
}) {
  const cardProps = useCard({
    id,
    selectedCardIds,
    setFilter,
    selectCard,
    unselectCard,
    onSaveTags,
    updateCard,
    showCheckbox,
    onSetFilterInCard,
  });
  const { isLoadingLinksPreview } = ui;

  return (
    <Container
      id={id}
      isSingleCard={isSingleCard}
      title={title}
      task={task}
      linkPreview={linkPreview}
      creationDate={creationDate}
      eventDate={eventDate}
      interactionType={interactionType}
      createdBy={createdBy}
      user={user}
      description={description}
      tags={tags}
      links={links}
      customFieldsAnswers={customFieldsAnswers}
      cardType={cardType}
      updateCommentsCount={updateCommentsCount}
      updateCardById={updateCardById}
      uploads={uploads}
      commentsCount={commentsCount}
      priorityIconOnly={priorityIconOnly}
      documentType={documentType}
      updateDataItem={updateDataItem}
      size={size}
      isMobile={isMobile}
      isLoadingLinksPreview={isLoadingLinksPreview}
      showCommentsInDrawer={showCommentsInDrawer}
      onShowCommentsInDrawerClick={onShowCommentsInDrawerClick}
      disabled={disabled}
      sharings={sharings}
      {...cardProps}
    />
  );
}

Card.defaultProps = {
  priorityIconOnly: false,
  task: {},
  size: CARD_SIZES.large,
  showCheckbox: true,
  showCommentsInDrawer: false,
  disabled: false,
};

Card.propTypes = {
  id: PropTypes.number.isRequired,
  isSingleCard: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  creationDate: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  eventDate: PropTypes.string,
  size: PropTypes.string,
  interactionType: PropTypes.string,
  user: userShape.isRequired,
  description: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(tagShape).isRequired,
  links: PropTypes.arrayOf(linkShape).isRequired,
  commentsCount: PropTypes.number.isRequired,
  priorityIconOnly: PropTypes.bool,
  customFieldsAnswers: PropTypes.arrayOf(customFieldDataShape).isRequired,
  cardType: PropTypes.string.isRequired,
  uploads: PropTypes.arrayOf(uploadShape).isRequired,
  selectedCardIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  setFilter: PropTypes.func.isRequired,
  selectCard: PropTypes.func.isRequired,
  unselectCard: PropTypes.func.isRequired,
  onSaveTags: PropTypes.func.isRequired,
  updateCard: PropTypes.func.isRequired,
  updateCardById: PropTypes.func.isRequired,
  updateCommentsCount: PropTypes.func.isRequired,
  task: PropTypes.shape({
    assignee: assigneeShape,
    dueDateTime: PropTypes.string,
    priority: PropTypes.string,
    status: PropTypes.string,
  }),
  linkPreview: linkPreviewShape,
  showCheckbox: PropTypes.bool,
  isMobile: PropTypes.bool,
  onSetFilterInCard: PropTypes.func,
  documentType: PropTypes.string.isRequired,
  updateDataItem: PropTypes.func.isRequired,
  ui: uiShape.isRequired,
  disabled: PropTypes.bool,
  showCommentsInDrawer: PropTypes.bool,
  onShowCommentsInDrawerClick: PropTypes.func,
  sharings: PropTypes.arrayOf(workspaceShape),
};

export default reduxConnect(Card);

export { default as CardPlaceholder } from './components/CardPlaceholder';
export { default as CardTransition } from './components/CardTransition';
export { useCard, Container };
