import { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import { useAccordionState } from 'components/core/Accordion';

function useAccordion({ filters }) {
  const { isOpen, handleToggle } = useAccordionState({}, true);

  useEffect(() => {
    if (!isOpen.creator && !isEmpty(filters.creator)) {
      handleToggle('creator');
    }
  }, [filters.creator]);

  useEffect(() => {
    if (!isOpen.tags && !isEmpty(filters.tags)) {
      handleToggle('tags');
    }
  }, [filters.tags]);

  return {
    isOpen,
    handleToggle,
  };
}

export default useAccordion;
