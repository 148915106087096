import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'components/core/Checkbox';
import LabelGroup from 'components/core/LabelGroup';

import styles from '../styles.module.scss';

function Attachments({ value, onChange }) {
  const handleChange = (event) => {
    const newValue = event.target.checked;

    onChange('attachments', newValue);
  };

  return (
    <LabelGroup containerClassName={styles.item}>
      <Checkbox
        label="Only show items with attachments"
        onChange={handleChange}
        isChecked={value}
        className="test__filter-has-attachments"
      />
    </LabelGroup>
  );
}

Attachments.defaultProps = {
  value: false,
};

Attachments.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default Attachments;
