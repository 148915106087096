import PropTypes from 'prop-types';

import { CARD_SIZES } from 'components/Grm2/constants';

export const userShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  photo: PropTypes.string,
});

export const uploadShape = PropTypes.shape({
  file_upload_id: PropTypes.number,
  file_upload_url: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.number,
});

export const assigneeShape = PropTypes.shape({
  id: PropTypes.number,
  fullName: PropTypes.string,
});

export const linkPreviewShape = PropTypes.shape({
  url: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
});

export const cardSizeShape = PropTypes.oneOf(
  Object.keys(CARD_SIZES)
);
