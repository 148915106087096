import React from 'react';
import PropTypes from 'prop-types';

import { customFieldDataShape } from 'components/Grm2/shapes';
import { transformCardForForm, transformTaskCardForBE } from 'redux/grm2/transformers';
import reduxConnect from 'components/Grm2/reduxConnect';
import { NO_PRIORITY } from 'components/Grm2/constants';

import Container from './components/Container';
import { newTaskValuesShape } from '../common/shapes';
import useNewCard from '../common/useNewCard';

function NewTask({ id, onClose, updateCard, customFields, initialValues, onSuccess }) {
  const {
    card,
    saveCard,
    isLoading,
  } = useNewCard({
    id,
    customFields,
    transformCardForBE: transformTaskCardForBE,
    transformCardForForm,
  });

  const handleSuccess = () => {
    if (onSuccess) {
      return onSuccess();
    }

    return onClose();
  };

  const handleSubmit = (updatedTask) => {
    const isNew = !updatedTask.id;

    return saveCard(updatedTask)
      .then(task => updateCard(task, isNew))
      .then(handleSuccess);
  };

  return (
    <Container
      isLoading={isLoading}
      initialValues={{
        priority: NO_PRIORITY,
        ...card,
        ...initialValues,
      }}
      onSubmit={handleSubmit}
      onClose={onClose}
      customFields={customFields}
    />
  );
}

NewTask.propTypes = {
  id: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  updateCard: PropTypes.func.isRequired,
  initialValues: newTaskValuesShape,
  customFields: PropTypes.arrayOf(customFieldDataShape).isRequired,
  onSuccess: PropTypes.func,
};

export default reduxConnect(NewTask);
