import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import moment from 'moment';
import { isEmpty, isFunction } from 'lodash';
import classNames from 'classnames';

import DatePicker from 'components/core/DatePicker';
import Label from 'components/core/Label';
import { formatDate } from 'utils/date';

import Tooltip from './Tooltip';
import styles from './styles.module.scss';

function DateField({
  name,
  title,
  placeholder,
  testClassName,
  onChange,
}) {
  return (
    <Field name={name}>
      {({ field }) => {
        const handleChange = (date) => {
          field.onChange(name)(formatDate(date));

          isFunction(onChange) && onChange(field, date);
        };

        const selectedDate = isEmpty(field.value) ? undefined : moment(field.value);

        return (
          <div className={styles.container}>
            <div className={styles.field}>
              <Label className={styles.label}>
                {title}
                <Tooltip content={placeholder} theme="light" />
              </Label>

              <DatePicker
                selected={selectedDate}
                onChange={handleChange}
                className={classNames(
                  testClassName,
                  styles.datePicker,
                )}
              />
            </div>
          </div>
        );
      }}
    </Field>
  );
}

DateField.propTypes = {
  name: PropTypes.string.isRequired,
  testClassName: PropTypes.string,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

export default DateField;
