import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import Accordion, { Item, CaretTitle } from 'components/core/Accordion';
import theme from 'components/core/TagInput/theme';

import { accordionStateShape } from '../../../common/shapes';
import {
  SelectField,
  CustomSelectOption,
  CustomSelectValue,
  DateField,
  AssigneeField,
  FieldsGroup,
  TimeField,
} from '../../../common/Fields';
import { SystemFields, TopFields, CustomFields } from '../../../common';
import styles from '../../../common/Form/styles.module.scss';

// WARNING
// If you add/remove or change any of the Form Fields,
//
// Please update front/components/Grm2/common/NewCard/common/useNewCard.js -> emptyCard
// Otherwise the CloseConfirmation modal won't work
//
// And also update front/components/Grm2/common/NewCard/utils.js -> systemFields
// Otherwise the SystemFields section won't open by default.

function Form({ hasChanged, setFieldTouched, accordionState }) {
  const isDisabled = form => isEmpty(form.values.dueDate);

  const onChangeDueDate = (field, date) => {
    if (isEmpty(date)) {
      field.onChange('time')('');
    }
  };

  // TODO: Remove that when core/PrioritiesTooltip/constants will be ready
  const PRIORITY_TYPES = [
    { value: 'highest', label: 'Highest', icon: 'fas fa-chevron-double-up' },
    { value: 'high', label: 'High', icon: 'fas fa-arrow-alt-up' },
    { value: 'no_priority', label: 'No priority', icon: 'fas fa-times-circle' },
    { value: 'low', label: 'Low', icon: 'fas fa-arrow-alt-down' },
    { value: 'lowest', label: 'Lowest', icon: 'fas fa-chevron-double-down' },
  ];

  return (
    <>
      <TopFields />
      <AssigneeField
        name="assignee"
        title="Assignee"
        isRequired
        strategy="user"
        testClassName="test__new-task-assignee"
      />
      <FieldsGroup>
        <DateField
          name="dueDate"
          title="Due date"
          testClassName="test__new-task-due-date"
          onChange={onChangeDueDate}
        />
        <TimeField
          name="time"
          title="Time"
          testClassName="test__new-task-time"
          placeholder=""
          isDisabled={isDisabled}
        />
        <SelectField
          name="priority"
          title="Priority"
          testClassName="test__new-task-priority"
          options={PRIORITY_TYPES}
          defaultOption={PRIORITY_TYPES[2]}
          customTheme={theme()}
          components={{ Option: CustomSelectOption, SingleValue: CustomSelectValue }}
          clearable={false}
          isSearchable={false}
        />
      </FieldsGroup>
      <Accordion
        renderTitle={CaretTitle}
        className={styles.accordion}
        isOpen={accordionState.isOpen}
        onToggle={accordionState.handleToggle}
      >
        <Item
          key="systemFields"
          title="System fields"
          className={styles.item}
        >
          <SystemFields />
        </Item>
        <Item
          key="customFieldsAnswers"
          title="Custom fields"
          className={styles.item}
        >
          <CustomFields hasChanged={hasChanged} setFieldTouched={setFieldTouched} />
        </Item>
      </Accordion>
    </>
  );
}

Form.propTypes = {
  hasChanged: PropTypes.bool.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  accordionState: accordionStateShape.isRequired,
};

export default Form;
