import React from 'react';
import PropTypes from 'prop-types';

import IconButton from 'components/Dashboard/common/IconButton';
import { ConfirmationModal } from 'components/core/Modal';

import ActionPrompt from './components/ActionPrompt';
import { NOTE_CARD_TYPE, CARD_TYPES } from '../../constants';
import styles from './styles.module.scss';

function RemoveCard({
  isOpen,
  onRemove,
  onClose,
  isMobile,
  title,
  type,
  isLoading,
}) {
  const typeLabel = CARD_TYPES[type].label;
  const text = `Are you sure you would like to delete “${title}” ${typeLabel}?`;

  if (isMobile) {
    return (
      <ConfirmationModal
        title="Remove card"
        message={text}
        isOpen={isOpen}
        className={styles.modal}
        disableContinue={isLoading}
        footerClassName={styles.footer}
        messageContinue="Delete"
        toggle={onClose}
        action={onRemove}
      />
    );
  }

  return (
    <ActionPrompt isOpen={isOpen}>
      <p>
        {text}
      </p>
      <IconButton
        iconClass="fas fa-check-circle"
        className={styles.button}
        iconClassName={styles.remove}
        disabled={isLoading}
        onClick={onRemove}
      >
        Yes
      </IconButton>
      <IconButton
        iconClass="fas fa-times-circle"
        className={styles.button}
        iconClassName={styles.cancel}
        onClick={onClose}
        disabled={isLoading}
      >
        No
      </IconButton>
    </ActionPrompt>
  );
}

RemoveCard.defaultProps = {
  type: NOTE_CARD_TYPE,
  isMobile: false,
  isLoading: false,
};

RemoveCard.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default RemoveCard;
export { default as Undo } from './components/Undo';
