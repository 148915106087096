import { useState } from 'react';
import { size, isEmpty } from 'lodash';

import useModal from 'components/core/Modal/useModal';
import { updateTaskCards } from 'components/Grm2/api';
import notifications from 'components/Grm2/notifications';

function usePriorityModalButton({
  onSave,
  ids,
}) {
  const { isOpenModal, toggleModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [priority, setPriority] = useState('');

  const confirmable = !isEmpty(priority) && !isLoading;

  const handleChange = ({ target: { value } }) => {
    setPriority(value);
  };

  const resetToggleModal = () => {
    toggleModal();
    setPriority('');
  };

  const successNotify = () => (
    size(ids) === 1
      ? notifications.updateTaskPrioritySuccess()
      : notifications.updateTasksPrioritySuccess()
  );

  const handleSave = () => {
    setIsLoading(true);

    updateTaskCards({
      ids,
      priority,
    }).then((response) => {
      onSave(response);
      successNotify();
    }).catch(notifications.generalFailNotification)
      .finally(() => {
        setIsLoading(false);
        resetToggleModal();
      });
  };

  return {
    handleSave,
    resetToggleModal,
    isOpenModal,
    isLoading,
    confirmable,
    toggleModal,
    handleChange,
  };
}

export default usePriorityModalButton;
