import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { isEmpty } from 'lodash';

import CustomField from 'components/core/CustomField';
import EmptyPlaceholder from 'components/core/EmptyPlaceholder';
import Button from 'components/core/Button';
import reduxConnect from 'components/Grm2/reduxConnect';
import { isCompanyAdmin } from 'utils/currentUser';
import { CloseConfirmation } from 'components/core/Modal';
import { customFieldShape } from 'components/Grm2/shapes';
import { CUSTOM_FIELD_NAME_PREFIX } from 'components/Grm2/common/NewCard/common/constants';
import { getError } from 'utils/forms';

import styles from './styles.module.scss';

function CustomFields({ customFields, hasChanged, setFieldTouched }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);

  const handleSubmit = () => {
    window.location.href = '/custom_fields';
  };

  const handleOpen = () => {
    if (hasChanged) {
      setIsOpen(true);
    } else {
      handleSubmit();
    }
  };

  const getEmptyDescription = () => {
    if (!isCompanyAdmin()) {
      return 'Your company admin has not created/enabled any custom fields yet. When they do, they will be shown here.';
    }

    return (
      <>
        <p>You haven’t created/enabled any custom fields yet. When you do, they will be shown here.</p>
        <Button
          onClick={handleOpen}
          theme="link"
          className={styles.emptyCustomFieldsLink}
        >
          Navigate to custom fields
        </Button>

        <CloseConfirmation
          isOpen={isOpen}
          toggleOpen={handleSubmit}
          onReturn={handleClose}
          onRequestClose={handleClose}
          onCrossBtnClick={handleClose}
        />
      </>
    );
  };

  if (isEmpty(customFields)) {
    return (
      <EmptyPlaceholder
        title="Looking for something?"
        description={getEmptyDescription()}
      />
    );
  }

  const handleFieldTouched = name => setFieldTouched && setFieldTouched(name);

  return (
    customFields.map((customField) => {
      const name = `${CUSTOM_FIELD_NAME_PREFIX}.${customField.token}`;
      const handleChange = field => (event) => {
        field.onChange(name)(event);
        handleFieldTouched(name);
      };
      const handleBlur = () => handleFieldTouched(name);

      return (
        <Field name={name} key={name}>
          {({ field, form }) => (
            <div className={styles.container}>
              <CustomField
                field={customField}
                controlState={{
                  value: field.value || [],
                  error: getError(name, form),
                }}
                handlers={{
                  handleChange: handleChange(field),
                  handleBlur,
                }}
                tooltipTheme="light"
                className={styles.field}
              />
            </div>
          )}
        </Field>
      );
    })
  );
}

CustomFields.propTypes = {
  customFields: PropTypes.arrayOf(customFieldShape).isRequired,
  hasChanged: PropTypes.bool.isRequired,
  setFieldTouched: PropTypes.func,
};

export default reduxConnect(CustomFields);
