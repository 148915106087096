import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty, size, max } from 'lodash';

import CoreAppliedFiltersLabel from 'components/core/AppliedFiltersLabel';
import useFilters from 'components/Grm2/common/Filters/useFilters';
import { filtersShape } from 'components/Grm2/shapes';
import { pluralize } from 'utils/string';
import RefreshButton from 'components/Grm2/common/RefreshButton';

import styles from './styles.module.scss';

function AppliedFiltersLabel({
  isLoading,
  totalCount,
  singularActivityWord,
  pluralActivitiesWord,
  currentPage,
  filters,
  defaultFilters,
  resetFilters,
  wrapperClassName,
}) {
  const { keywordLookup } = filters;
  const { appliedFilters } = useFilters({ filters });

  const filtersCount = max([0, appliedFilters - size(defaultFilters)]);
  const activitiesWord = pluralize(singularActivityWord, pluralActivitiesWord, totalCount);
  const onClearFilters = () => resetFilters(defaultFilters);

  if (!keywordLookup && filtersCount === 0) {
    return null;
  }

  return (
    <div className={classNames(styles.wrapper, wrapperClassName)}>
      <CoreAppliedFiltersLabel
        isLoading={isLoading}
        containerClass={styles.label}
        results={totalCount}
        resultsWord={activitiesWord}
        onClearFilters={onClearFilters}
        filtersCount={filtersCount}
        searchValue={keywordLookup}
      />
      <RefreshButton
        filtersCount={filtersCount}
        hasKeyword={!isEmpty(keywordLookup)}
        page={currentPage}
      />
    </div>
  );
}

AppliedFiltersLabel.defaultProps = {
  defaultFilters: {},
  singularActivityWord: 'activity',
  pluralActivitiesWord: 'activities',
};

AppliedFiltersLabel.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  singularActivityWord: PropTypes.string,
  pluralActivitiesWord: PropTypes.string,
  currentPage: PropTypes.number,
  filters: filtersShape.isRequired,
  defaultFilters: filtersShape,
  resetFilters: PropTypes.func.isRequired,
  wrapperClassName: PropTypes.string,
};

export default AppliedFiltersLabel;
