import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function FieldsGroup({ children }) {
  return (
    <div className={styles.container}>
      <div className={classNames(styles.fieldsGroup, styles.marginLeft)}>
        {children}
      </div>
    </div>
  );
}

FieldsGroup.propTypes = {
  children: PropTypes.node,
};

export default FieldsGroup;
