import moment from 'moment';
import { useRef, useEffect } from 'react';

import { formatDate } from 'utils/date';

function useDateFilter({ onChange, startDate: initialStartDate, endDate: initialEndDate }) {
  const startDate = initialStartDate ? moment(initialStartDate).toDate() : null;
  const endDate = initialEndDate ? moment(initialEndDate).toDate() : null;
  const datePickerRef = useRef();

  const isSelectingEndDate = startDate && !endDate;

  const openDatePicker = () => {
    datePickerRef.current.setOpen(true);
  };

  const handleChange = (date) => {
    isSelectingEndDate
      ? onChange(formatDate(startDate), formatDate(date))
      : onChange(formatDate(date), null);
  };

  useEffect(() => {
    isSelectingEndDate && openDatePicker();
  }, [isSelectingEndDate]);

  const startProps = {
    selectsStart: true,
    selected: startDate,
  };

  const endProps = {
    selectsEnd: true,
    selected: endDate,
    minDate: startDate,
  };

  const datePickerProps = isSelectingEndDate
    ? endProps
    : startProps;

  return {
    onChange: handleChange,
    forwardRef: datePickerRef,
    startDate,
    endDate,
    ...datePickerProps,
  };
}

export default useDateFilter;
