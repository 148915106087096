import * as Yup from 'yup';

import getCustomFieldsSchema from 'utils/forms/getCustomFieldsSchema';
import {
  errorMessages,
  CUSTOM_FIELD_NAME_PREFIX,
} from 'components/Grm2/common/NewCard/common/constants';

const getValidationSchema = customFields => Yup.object().shape({
  title: Yup.string().required(errorMessages.title.required),
  description: Yup.string().required(errorMessages.description.required),
  [CUSTOM_FIELD_NAME_PREFIX]: Yup.object().shape(
    getCustomFieldsSchema(customFields, errorMessages.customField.required)
  ),
});

export default getValidationSchema;
