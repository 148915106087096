import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';

import styles from './styles.module.scss';

function MobileView({ children, onClearFilters, onApplyFilters }) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div>
          {children}
        </div>
        <div className={styles.footer}>
          <Button
            size="small"
            onClick={onApplyFilters}
            className={styles.button}
          >
            Apply filters
          </Button>
          <Button
            size="small"
            theme="light"
            onClick={onClearFilters}
            className={styles.button}
          >
            Clear filters
          </Button>
        </div>
      </div>
    </div>
  );
}

MobileView.propTypes = {
  children: PropTypes.node.isRequired,
  onClearFilters: PropTypes.func.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
};

export default MobileView;
