import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function ActionPrompt({ isOpen, children }) {
  return (
    <div className={classNames(styles.container, isOpen && styles.openContainer)}>
      {children}
    </div>
  );
}

ActionPrompt.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default ActionPrompt;
