import React from 'react';
import PropTypes from 'prop-types';

import reduxConnect from 'components/Grm2/reduxConnect';
import { analyticsShape } from 'components/Grm2/shapes';

import Container from './components/Container';

function VisualizationButton({ analytics, showDashboard, hideDashboard }) {
  const { ui } = analytics;
  const isActive = ui.showDashboard;

  const handleClick = () => {
    isActive ? hideDashboard() : showDashboard();
  };

  return (
    <Container
      isActive={isActive}
      onClick={handleClick}
    />
  );
}

VisualizationButton.propTypes = {
  analytics: analyticsShape.isRequired,
  showDashboard: PropTypes.func.isRequired,
  hideDashboard: PropTypes.func.isRequired,
};

export default reduxConnect(VisualizationButton);
