import { isCompanyAdmin } from 'utils/currentUser';

function useCustomFields() {
  const showCustomFields = isCompanyAdmin();

  const handleClick = () => {
    window.location.assign('/custom_fields');
  };

  return {
    showCustomFields,
    onCustomFieldsClick: handleClick,
  };
}

export default useCustomFields;
