import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';

import reduxConnect from 'components/Grm2/reduxConnect';
import KanbanBoard from 'components/core/KanbanBoard';
import { NewTask } from 'components/Grm2/common/NewCard';
import CommentsDrawer from 'components/Grm2/common/CommentsDrawer';
import useCommentsDrawer from 'components/Grm2/common/Feed/useCommentsDrawer';
import { uiShape, cardShape, linksPreviewShape } from 'components/Grm2/shapes';
import renderNotifications from 'components/Dashboard/utils/notificationsPortal';

import Column from './components/Column';
import { TaskBoardContext } from './utils/withTaskBoard';
import useTaskBoard from './utils/useTaskBoard';
import useTaskModal from './utils/useTaskModal';
import useSetupTaskBoard from './utils/useSetupTaskBoard';
import useUpdateTask from './utils/useUpdateTask';
import styles from './styles.module.scss';

function TaskBoard({
  cards,
  cardsLinkPreview,
  updateCard,
  updateCardById,
  setSort,
  setPerPage,
  setFilters,
  resetData,
  ui,
  setFilter,
  setNamespace,
  setAdvancedSearch,
  advancedSearch,
  setIsTasksContext,
  onSaveTags,
  updateCommentsCount,
  updateDataItem,
  isMobile,
}) {
  const {
    isLoading,
    isLoadingCustomFields,
    isLoadingMyFeedDocuments,
  } = ui;
  const { lastSearchId } = advancedSearch;

  const {
    showTaskModal,
    taskModalId,
    taskModalInitialValues,
    onOpenTaskModal,
    onCloseTaskModal,
  } = useTaskModal();

  useUpdateTask({ cards, updateCard });

  useSetupTaskBoard({ setNamespace, resetData });

  const {
    columnsData,
    handleMoveCard,
    placeholderCompareFunction,
    isLoadingMove,
  } = useTaskBoard({
    cards,
    setSort,
    setPerPage,
    setIsTasksContext,
    setFilters,
    updateCard,
    onOpenTaskModal,
    setAdvancedSearch,
    lastSearchId,
  });

  const {
    commentsDrawerCardId,
    onCommentsDrawerClose,
    isCommentsDrawerOpen,
    onShowCommentsInDrawerClick,
  } = useCommentsDrawer();

  const context = {
    setFilter,
    updateCard,
    updateCardById,
    updateCommentsCount,
    onOpenTaskModal,
    onSaveTags,
    cardsLinkPreview,
    updateDataItem,
    isLoadingMyFeedDocuments,
    onShowCommentsInDrawerClick,
  };

  const newTaskProps = {
    id: taskModalId,
    onClose: onCloseTaskModal,
    initialValues: taskModalInitialValues,
  };

  // TaskBoardContext is used to pass redux props to components inside react-beaultiful-dnd DragDropContext
  // full explanation of the problem in first description of https://github.com/GovPredict/gov_congress/pull/9206
  return (
    <TaskBoardContext.Provider value={context}>
      <KanbanBoard onMoveCard={handleMoveCard} className={styles.board}>
        {map(columnsData, ({ status, ...restProps }) => (
          <Column
            {...restProps}
            key={status}
            status={status}
            isLoading={isLoading || isLoadingCustomFields}
            isDragDisabled={isLoadingMove}
            placeholderCompareFunction={placeholderCompareFunction}
          />
        ))}
      </KanbanBoard>
      {showTaskModal && <NewTask {...newTaskProps} />}
      <CommentsDrawer
        isOpen={isCommentsDrawerOpen}
        cardId={commentsDrawerCardId}
        onClose={onCommentsDrawerClose}
        updateCard={updateCard}
        updateCommentsCount={updateCommentsCount}
      />
      {/* TODO check if there is another solution to fix notification button duplication */}
      {renderNotifications({ isMobile })}
    </TaskBoardContext.Provider>
  );
}

TaskBoard.propTypes = {
  cards: PropTypes.arrayOf(cardShape).isRequired,
  updateCard: PropTypes.func.isRequired,
  updateCardById: PropTypes.func.isRequired,
  setIsTasksContext: PropTypes.func.isRequired,
  ui: uiShape.isRequired,
  resetData: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  updateCommentsCount: PropTypes.func.isRequired,
  updateDataItem: PropTypes.func.isRequired,
  onSaveTags: PropTypes.func.isRequired,
  setNamespace: PropTypes.func.isRequired,
  setAdvancedSearch: PropTypes.func.isRequired,
  advancedSearch: PropTypes.shape({
    lastSearchId: PropTypes.number,
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  cardsLinkPreview: linksPreviewShape.isRequired,
};

export default reduxConnect(TaskBoard);
