import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/core/Button';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import styles from './styles.module.scss';

function Container({ isActive, onClick }) {
  const { isMobile } = useMediaQuery();

  const customIcon = (
    <i className={classNames('fas fa-chart-area', styles.icon, isActive && styles.activeIcon)} />
  );

  const label = isActive
    ? 'Hide visualization'
    : 'Show visualization';

  const theme = isMobile
    ? 'default'
    : 'light';

  return (
    <Button
      theme={theme}
      className={styles.button}
      iconClassName={styles.iconContainer}
      onClick={onClick}
      customIcon={customIcon}
    >
      <span className={styles.text}>{label}</span>
    </Button>
  );
}

Container.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Container;
