import { useEffect } from 'react';
import { isEmpty, isFunction, keys } from 'lodash';

import useSearchParams from 'components/core/PageLookup/utils/useSearchParams';
import useDidUpdateEffect from 'utils/useDidUpdateEffect';
import { getUrlParam } from 'utils/browser-history';

import { getUrlParamsByOptions } from './common/Filters/utils/urlFilters';

function useAdvancedSearch({
  fetchCards,
  setAdvancedSearch,
  setFilters,
  currentPage,
  lastSearchId,
}) {
  const {
    getSearchParams,
    updateSearchParams,
  } = useSearchParams({
    currentPage: currentPage || getUrlParam('page'),
    lastSearchId: lastSearchId || getUrlParam('searchId'),
  });

  const filterByUrlParams = () => {
    const rawUrlFilters = getUrlParamsByOptions();
    const shouldApplyUrlRawFilters = !isEmpty(rawUrlFilters) && isFunction(setFilters);

    if (shouldApplyUrlRawFilters) {
      updateSearchParams({}, [], keys(rawUrlFilters));
      return setFilters(rawUrlFilters, { avoidDispatchFetch: true });
    }

    return Promise.resolve();
  };

  const fetchCardsWithUrlParams = () => {
    setAdvancedSearch(getSearchParams())
      .then(filterByUrlParams)
      .then(fetchCards);
  };

  useEffect(() => {
    fetchCardsWithUrlParams();
  }, []);

  useDidUpdateEffect(() => {
    lastSearchId === undefined && updateSearchParams({ searchId: updateSearchParams }, ['searchId'], ['searchId']);
  }, [lastSearchId]);
}

export default useAdvancedSearch;
