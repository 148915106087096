import React from 'react';
import PropTypes from 'prop-types';
import { withResizeDetector } from 'react-resize-detector';
import WordCloudChart from 'react-d3-cloud';

import { scaleValues } from './utils';
import styles from './styles.module.scss';

function WordCloud({ width, data }) {
  const fontSizeMapper = word => Math.log2(word.value) * 5;
  const rotate = () => Math.round(Math.random()) * 270;

  const renderChart = () => {
    if (!width) {
      return null;
    }

    return (
      <WordCloudChart
        data={scaleValues(data)}
        width={width}
        height={150}
        fontSizeMapper={fontSizeMapper}
        rotate={rotate}
        font="Roboto"
      />
    );
  };

  return (
    <div className={styles.container}>
      {renderChart()}
    </div>
  );
}

const tagShape = PropTypes.shape({
  text: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
});

WordCloud.propTypes = {
  data: PropTypes.arrayOf(tagShape),
  width: PropTypes.number,
};

export default withResizeDetector(WordCloud);
