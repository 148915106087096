import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';
import Modal from 'components/core/Modal';
import { pluralize } from 'utils/string';

function RemoveButton({
  count,
  className,
  onSave,
  isLoading,
  toggleModal,
  isOpenModal,
  ...restProps
}) {
  const text = `Are you sure you would like to delete ${pluralize('this', 'these', count)} ${count} ${pluralize('activity', 'activities', count)}?`;

  const modalActions = (
    <>
      <Button
        theme="light"
        size="small"
        onClick={toggleModal}
      >
        Cancel
      </Button>
      <Button
        size="small"
        isLoading={isLoading}
        onClick={onSave}
      >
        Confirm
      </Button>
    </>
  );

  return (
    <>
      <Button
        {...restProps}
        className={className}
        isLoading={isLoading}
        onClick={toggleModal}
      >
        Delete
      </Button>
      <Modal
        title="Remove activities"
        isOpen={isOpenModal}
        toggleOpen={toggleModal}
        buttons={modalActions}
      >
        {text}
      </Modal>
    </>
  );
}

RemoveButton.defaultProps = {
  isOpenModal: false,
  isLoading: false,
};

RemoveButton.propTypes = {
  className: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  isOpenModal: PropTypes.bool,
  isLoading: PropTypes.bool,
  count: PropTypes.number,
};

export default RemoveButton;
