import React from 'react';
import PropTypes from 'prop-types';

import { Radar as RadarChart } from 'react-chartjs-2';

function Radar({ data, colors, tooltip }) {
  const chartData = {
    labels: data.labels,
    datasets: [{
      data: data.values,
      pointRadius: 0,
      tension: 0,
      backgroundColor: colors.background,
      borderColor: colors.border,
    }],
  };

  return (
    <RadarChart
      data={chartData}
      options={{
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scale: {
          gridLines: {
            circular: true,
          },
          ticks: {
            display: false,
            maxTicksLimit: 4,
          },
        },
        tooltips: {
          displayColors: false,
          titleAlign: 'center',
          titleFontStyle: 'normal',
          titleFontFamily: 'Roboto',
          bodyAlign: 'center',
          bodyFontStyle: 'bold',
          bodyFontFamily: 'Roboto',
          yPadding: 8,
          xPadding: 12,
          backgroundColor: '#FFFFFF',
          titleFontColor: '#000000',
          bodyFontColor: '#000000',
          footerFontColor: '#000000',
          intersect: false,
          callbacks: {
            title(tooltipItem, inputData) {
              const label = inputData.labels[tooltipItem[0].index];
              const value = inputData.datasets[tooltipItem[0].datasetIndex].data[tooltipItem[0].index];

              return tooltip.title({ value, label });
            },
            label(tooltipItem) {
              const { value, label } = tooltipItem;

              return tooltip.label({ value, label });
            },
          },
        },
      }}
    />
  );
}

Radar.defaultProps = {
  data: {
    labels: [],
    values: [],
  },
  colors: {
    background: '#FCAE72',
    border: '#F3F11F',
  },
  tooltip: {
    title: ({ label }) => label,
    label: ({ value }) => value,
  },
};

Radar.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    values: PropTypes.arrayOf(PropTypes.number).isRequired,
  }),
  colors: PropTypes.shape({
    background: PropTypes.string.isRequired,
    border: PropTypes.string.isRequired,
  }),
  tooltip: PropTypes.shape({
    title: PropTypes.func.isRequired,
    label: PropTypes.func.isRequired,
  }),
};

export default Radar;
