import * as actions from 'redux/grm2/actions';
import reduxConnect from 'utils/redux-connect';

const mergeProps = ({ grm, ...stateProps }, dispatchProps, ownProps) => ({
  ...ownProps,
  ...grm,
  ...stateProps,
  ...dispatchProps,
});

export default reduxConnect('grm2', actions, { mergeProps });
