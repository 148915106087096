import React from 'react';

import { Provider } from 'react-redux';
import store from 'redux/store';

import Container from './components/Container';

function NewTaskForNavigation(props) {
  return (
    <Provider store={store}>
      <Container {...props} />
    </Provider>
  );
}

export default NewTaskForNavigation;
