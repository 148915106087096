import {
  ACTIVITY_CUSTOM_EVENT_TYPE,
  CARD_UPDATED,
  CARDS_UPDATED,
  CARD_CHANGED,
  CARD_CREATED,
  DATA_ITEM_UPDATED,
} from './constants';

const dispatchEvent = (event, namespace, payload = null) => {
  const detail = {
    event,
    namespace,
    payload,
  };

  const customEvent = new CustomEvent(ACTIVITY_CUSTOM_EVENT_TYPE, { detail });

  document.dispatchEvent(customEvent);
};

export default namespace => ({
  cardUpdated: payload => dispatchEvent(CARD_UPDATED, namespace, payload),
  cardsUpdated: payload => dispatchEvent(CARDS_UPDATED, namespace, payload),
  cardChanged: payload => dispatchEvent(CARD_CHANGED, namespace, payload),
  cardCreated: payload => dispatchEvent(CARD_CREATED, namespace, payload),
  dataItemUpdated: payload => dispatchEvent(DATA_ITEM_UPDATED, namespace, payload),
});
