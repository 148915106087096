import { useState } from 'react';

import { removeCard, undoCard } from 'components/Grm2/api';
import notifications from 'components/Grm2/notifications';
import { CARD_TYPES } from 'components/Grm2/constants';
import useLoader from 'utils/useLoader';

function useRemoveCard({
  updateCardById,
  id,
  type,
}) {
  const [isRemovePrompt, setIsRemovePrompt] = useState(false);
  const { label } = CARD_TYPES[type];

  const {
    isLoading: isLoadingUndo,
    startLoading: startLoadingUndo,
    stopLoading: stopLoadingUndo,
  } = useLoader();

  const {
    isLoading: isLoadingRemove,
    startLoading: startLoadingRemove,
    stopLoading: stopLoadingRemove,
  } = useLoader();

  const toggleRemovePrompt = () => {
    setIsRemovePrompt(prevState => !prevState);
  };

  const closeRemovePrompt = () => {
    setIsRemovePrompt(false);
  };

  const handleRemove = () => {
    startLoadingRemove();
    removeCard(id).then(() => {
      updateCardById(id, { disabled: true });
      notifications.deleteSuccess(label);
      stopLoadingRemove();
      closeRemovePrompt();
    }).catch(notifications.generalFailNotification);
  };

  const handleUndo = () => {
    startLoadingUndo();
    undoCard(id).then(() => {
      updateCardById(id, { disabled: false });
      notifications.undoSuccess(label);
      stopLoadingUndo();
    }).catch(notifications.generalFailNotification);
  };

  return {
    toggleRemovePrompt,
    handleRemove,
    handleUndo,
    closeRemovePrompt,
    isRemovePrompt,
    isLoadingUndo,
    isLoadingRemove,
  };
}

export default useRemoveCard;
