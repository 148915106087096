import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import PriorityTooltip from 'components/core/PriorityTooltip';
import { updateTaskCard } from 'components/Grm2/api';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';
import notifications from 'components/Grm2/notifications';

import styles from './styles.module.scss';

function PriorityButton({ defaultOption, id, onSave, priorityIconOnly }) {
  const [isLoading, setIsLoading] = useState(false);

  const { isMobile } = useMediaQuery();

  const onChangePriority = (priority) => {
    setIsLoading(true);

    updateTaskCard({ id, priority })
      .then((response) => {
        onSave(response);
        notifications.updateTaskPrioritySuccess();
      })
      .catch(notifications.generalFailNotification)
      .finally(() => setIsLoading(false));
  };

  const iconClassNames = classNames(
    styles.iconButton,
    !priorityIconOnly && styles.iconButtonTitle,
  );

  return (
    <PriorityTooltip
      isMobile={isMobile}
      isLoadingPriority={isLoading}
      buttonClassName={classNames(styles.priorityButton, iconClassNames)}
      selectedPriority={defaultOption}
      onChangePriority={onChangePriority}
      containerClassName={iconClassNames}
      iconClassName={styles.icon}
    />
  );
}

PriorityButton.defaultProps = {
  priorityIconOnly: false,
};

PriorityButton.propTypes = {
  defaultOption: PropTypes.string,
  id: PropTypes.number,
  onSave: PropTypes.func,
  priorityIconOnly: PropTypes.bool,
};

export default PriorityButton;
