function useFilters({ setFilters }) {
  const handleDateFilterChange = (startDate, endDate) => {
    setFilters({ creationDate: { startDate, endDate } });
  };

  return {
    handleDateFilterChange,
  };
}

export default useFilters;
