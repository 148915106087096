import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import CardGroup from 'components/core/CardGroup';
import Avatar from 'components/core/Avatar';
import { CARD_MODAL_COMPONENTS } from 'components/Grm2/common/NewCard/constants';
import { CARD_TYPES, CARD_SIZES } from 'components/Grm2/constants';
import {
  createSrcForIcon,
  isCardEditable,
  isCardTypeNote,
  isCardTypeTask,
  isCardRemovable,
} from 'components/Grm2/utils';
import { TextWithMentions } from 'components/core/TextareaWithMentions';
import { getUrlParam } from 'utils/browser-history';
import ViewTags from 'components/core/ViewTags';
import { tagShape, customFieldDataShape, linkShape } from 'components/Grm2/shapes';
import RemoveCard, { Undo } from 'components/Grm2/common/RemoveCard';
import Comments from 'components/Grm2/common/Comments';
import LinkPreview from 'components/Grm2/common/LinkPreview';
import extractUrls from 'utils/extract-urls';
import { workspaceShape } from 'redux/events/utils/shapes';

import {
  userShape,
  uploadShape,
  assigneeShape,
  cardSizeShape,
  linkPreviewShape,
} from '../../shapes';
import ActionBar from '../ActionBar';
import CreatedBy from '../CreatedBy';
import TaskFields from '../TaskFields';
import CreationDate from '../CreationDate';
import CustomFields from '../CustomFields';
import Description from '../Description';
import EventDate from '../EventDate';
import InteractionType from '../InteractionType';
import Links from '../Links';
import Title from '../Title';
import Attachments from '../Attachments';
import StatusButton from '../StatusButton';
import useRemoveCard from '../../useRemoveCard';
import useComments from './useComments';
import styles from './styles.module.scss';

const sizeThemes = {
  large: styles.large,
  small: styles.small,
  medium: styles.medium,
};

function Card({
  id,
  title,
  eventDate,
  interactionType,
  createdBy,
  creationDate,
  description,
  task,
  user,
  tags,
  links,
  commentsCount,
  customFieldsAnswers,
  cardType,
  updateCard,
  updateCardById,
  updateCommentsCount,
  uploads,
  onCloseNewNoteModal,
  onEditClick,
  onShowLinksClick,
  onTagClick,
  onToggleCheckbox,
  onSaveTagsModal,
  onAvatarClick,
  isSingleCard,
  isChecked,
  isCheckboxPresent,
  shouldFilterOnAvatarClick,
  shouldFilterOnTagClick,
  priorityIconOnly,
  showStatusButton,
  showLinks,
  showEditModal,
  documentType,
  updateDataItem,
  isLoadingLinksPreview,
  showCommentsInDrawer,
  onShowCommentsInDrawerClick,
  size,
  linkPreview,
  isMobile,
  disabled,
  sharings,
}) {
  const highlightedCommentId = getUrlParam('comment_id');
  const highlightedParentId = getUrlParam('parent_id');
  const isEditable = isCardEditable(cardType, user);
  const isRemovable = isCardRemovable({ cardType, user });
  const isTask = isCardTypeTask(cardType);
  const isNote = isCardTypeNote(cardType);

  const EditModal = CARD_MODAL_COMPONENTS[cardType];

  const { assignee, assigner, dueDateTime, priority, status, id: taskID } = task;
  const hasLinkToPreview = !isEmpty(extractUrls(description));
  const isLoadingLinkPreview = hasLinkToPreview && isLoadingLinksPreview && isEmpty(linkPreview);

  const {
    toggleRemovePrompt,
    handleRemove,
    handleUndo,
    closeRemovePrompt,
    isRemovePrompt,
    isLoadingUndo,
    isLoadingRemove,
  } = useRemoveCard({
    id,
    updateCardById,
    type: cardType,
  });

  const {
    showComments,
    showCommentLoader,
    showCommentAction,
    onCommentClick,
    setShowCommentLoad,
  } = useComments({
    isNote,
    isTask,
    isSingleCard,
    updateCard,
    cardId: id,
    showCommentsInDrawer,
    onShowCommentsInDrawerClick,
  });

  if (disabled) {
    return (
      <Undo size={size} cardType={cardType} isLoading={isLoadingUndo} onUndo={handleUndo} />
    );
  }

  const handleAvatarClick = () => onAvatarClick(user);

  const renderAvatarIcon = () => {
    const { iconName, color } = CARD_TYPES[cardType];

    return (<img alt={iconName} src={createSrcForIcon(iconName, color)} />);
  };

  return (
    <div id={`test__${cardType}-${id}`}>
      <CardGroup
        isChecked={isChecked}
        isCheckboxPresent={isCheckboxPresent}
        isCheckboxDisabled={isRemovePrompt}
        className={classNames(styles.card, sizeThemes[size])}
        contentClassName={styles.content}
        headerContentClassName={styles.headerContent}
        toggleCheckbox={onToggleCheckbox}
        checkboxClassName="test__card-checkbox"
      >
        <div className={styles.container}>
          <div className={styles.row}>
            <div>
              <Title size={size}>{title}</Title>
              <CreationDate size={size}>{creationDate}</CreationDate>
            </div>
            <div className={styles.avatarWrapper}>
              <Avatar
                {...user}
                className={styles.avatar}
                photoUrl={user.photo}
                icon={renderAvatarIcon()}
                onClick={handleAvatarClick}
                isClickable={shouldFilterOnAvatarClick}
              />
            </div>
          </div>
          <Description size={size}>
            <TextWithMentions>{description}</TextWithMentions>
          </Description>
          <LinkPreview
            {...linkPreview}
            isLoading={isLoadingLinkPreview}
            size={size}
          />
          <CustomFields size={size} customFields={customFieldsAnswers} />
          {isTask && (
            <TaskFields
              size={size}
              dueDateTime={dueDateTime}
              assigner={assigner}
              assignee={assignee}
            />
          )}
          <div className={styles.badgesContainer}>
            <ViewTags
              tags={tags}
              onClick={shouldFilterOnTagClick && onTagClick}
              className={styles.badges}
              sharings={sharings}
            />
          </div>
          <StatusButton
            isVisible={isTask && showStatusButton}
            onClick={updateCard}
            id={taskID}
            defaultOption={status}
          />
          {showLinks && (
            <>
              <EventDate size={size} eventDate={eventDate} />
              <InteractionType size={size} interactionType={interactionType} />
              <Links size={size} links={links} />
              <CreatedBy size={size} createdBy={createdBy} />
              <Attachments uploads={uploads} />
            </>
          )}
        </div>
        <RemoveCard
          isOpen={isRemovePrompt}
          onRemove={handleRemove}
          onClose={closeRemovePrompt}
          isMobile={isMobile}
          type={cardType}
          title={title}
          isLoading={isLoadingRemove}
        />
        <ActionBar
          isEditable={isEditable}
          isRemovable={isRemovable}
          isTask={isTask}
          isShowingLinks={showLinks}
          onShowLinksClick={onShowLinksClick}
          onEditClick={onEditClick}
          onRemoveClick={toggleRemovePrompt}
          showCommentAction={showCommentAction}
          onCommentClick={onCommentClick}
          commentsCount={commentsCount}
          priority={priority}
          assignee={assignee}
          id={id}
          tags={tags}
          cardType={cardType}
          taskID={taskID}
          onSaveTags={onSaveTagsModal}
          showCommentLoader={showCommentLoader}
          updateCard={updateCard}
          priorityIconOnly={priorityIconOnly}
          documentType={documentType}
          updateDataItem={updateDataItem}
          size={size}
        />
        {showComments && (
          <Comments
            cardId={id}
            highlightedCommentId={highlightedCommentId}
            highlightedParentId={highlightedParentId}
            loadAll={isSingleCard}
            updateCard={updateCard}
            updateCommentsCount={updateCommentsCount}
            setShowLoader={setShowCommentLoad}
          />
        )}
      </CardGroup>
      {showEditModal && (
        <EditModal id={id} onClose={onCloseNewNoteModal} />
      )}
    </div>
  );
}

Card.defaultProps = {
  priorityIconOnly: false,
  shouldFilterOnAvatarClick: true,
  shouldFilterOnTagClick: true,
  showStatusButton: true,
  showCommentsInDrawer: false,
  size: CARD_SIZES.large,
  disabled: false,
};

Card.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  eventDate: PropTypes.string,
  size: cardSizeShape,
  interactionType: PropTypes.string,
  createdBy: PropTypes.string.isRequired,
  creationDate: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  task: PropTypes.shape({
    assignee: assigneeShape,
    assigner: assigneeShape,
    dueDateTime: PropTypes.string,
    priority: PropTypes.string,
    status: PropTypes.string,
    id: PropTypes.number,
  }),
  user: userShape.isRequired,
  tags: PropTypes.arrayOf(tagShape).isRequired,
  links: PropTypes.arrayOf(linkShape).isRequired,
  linkPreview: linkPreviewShape,
  commentsCount: PropTypes.number,
  customFieldsAnswers: PropTypes.arrayOf(customFieldDataShape).isRequired,
  cardType: PropTypes.oneOf(Object.keys(CARD_TYPES)),
  uploads: PropTypes.arrayOf(uploadShape).isRequired,
  updateCard: PropTypes.func.isRequired,
  updateCardById: PropTypes.func.isRequired,
  updateCommentsCount: PropTypes.func.isRequired,
  onCloseNewNoteModal: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onShowLinksClick: PropTypes.func.isRequired,
  onTagClick: PropTypes.func.isRequired,
  onToggleCheckbox: PropTypes.func.isRequired,
  onSaveTagsModal: PropTypes.func.isRequired,
  onAvatarClick: PropTypes.func.isRequired,
  isSingleCard: PropTypes.bool.isRequired,
  isChecked: PropTypes.bool.isRequired,
  isCheckboxPresent: PropTypes.bool.isRequired,
  priorityIconOnly: PropTypes.bool,
  shouldFilterOnAvatarClick: PropTypes.bool,
  shouldFilterOnTagClick: PropTypes.bool,
  showStatusButton: PropTypes.bool,
  showLinks: PropTypes.bool.isRequired,
  showEditModal: PropTypes.bool.isRequired,
  documentType: PropTypes.string.isRequired,
  updateDataItem: PropTypes.func.isRequired,
  isLoadingLinksPreview: PropTypes.bool.isRequired,
  showCommentsInDrawer: PropTypes.bool,
  isMobile: PropTypes.bool,
  disabled: PropTypes.bool,
  onShowCommentsInDrawerClick: PropTypes.func,
  sharings: PropTypes.arrayOf(workspaceShape),
};

export default Card;
