import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';

import PageHeader from 'components/core/PageHeader';
import titles from 'components/Grm2/titles';
import useElementById from 'utils/useElementById';

import TasksSearchHeader from '../TasksSearchHeader';
import styles from './styles.module.scss';

function TasksHeader({ boardUrl, listUrl }) {
  const { ref } = useElementById('navigation');
  const { bottom: top } = isFunction(ref.getBoundingClientRect) && ref.getBoundingClientRect();

  const pageHeaderProps = {
    applyExtraMargin: false,
    headerClassName: styles.pageHeader,
    titleClassName: styles.subProductTitle,
    ...titles.tasks,
  };

  return (
    <div style={{ top: `${top}px` }} className={styles.header}>
      <div className={styles.container}>
        <PageHeader {...pageHeaderProps} />
        <TasksSearchHeader boardUrl={boardUrl} listUrl={listUrl} />
      </div>
    </div>
  );
}

TasksHeader.propTypes = {
  boardUrl: PropTypes.string.isRequired,
  listUrl: PropTypes.string.isRequired,
};

export default TasksHeader;
