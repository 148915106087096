import { mapValues } from 'lodash';

import {
  OPEN,
  IN_PROGRESS,
  CLOSED,
  STATUS_TYPES,
  TASK_CARD_TYPE,
} from 'components/Grm2/constants';
import sortDirections from 'utils/constants/sortDirections';
import { getCurrentUser } from 'utils/currentUser';

export const COLUMN_STATUS = mapValues(STATUS_TYPES, 'value');

const COUNTER_THEMES = {
  RED: 'redWithBorder',
  DARK_GREY: 'darkGrey',
};

export const COLUMN_HEADER_PROPS = {
  [OPEN]: {
    counterTheme: COUNTER_THEMES.RED,
    title: STATUS_TYPES[OPEN].label,
  },
  [IN_PROGRESS]: {
    counterTheme: COUNTER_THEMES.DARK_GREY,
    title: STATUS_TYPES[IN_PROGRESS].label,
  },
  [CLOSED]: {
    counterTheme: COUNTER_THEMES.DARK_GREY,
    title: STATUS_TYPES[CLOSED].label,
  },
};

export const COLUMN_FOOTER_PROPS = {
  [CLOSED]: { content: 'See all closed tasks' },
};

export const COLUMN_EXTRA_PROPS = {
  [CLOSED]: {
    displayLimit: 5,
    showFooterOnLimitExceed: true,
  },
};

export {
  OPEN,
  IN_PROGRESS,
  CLOSED,
};

export const defaultSort = {
  sort: 'task.priority_created_at',
  direction: sortDirections.DESC,
};

export const tasksLimit = 100;

export const defaultFilters = {
  assignee: [
    { value: getCurrentUser().userId },
  ],
  activityTypes: [
    TASK_CARD_TYPE,
  ],
};
