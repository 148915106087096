import { includes } from 'lodash';

import useActivityEffect from 'components/Grm2/events/useActivityEffect';
import {
  CARD_UPDATED,
  CARDS_UPDATED,
  CARD_CREATED,
  ACTIVITIES_DRAWER_NAMESPACE,
} from 'components/Grm2/events/constants';
import { isAlreadyUpdated, isLinkedWithThePage, mergeCards } from 'components/Grm2/utils';

function shouldUpdateCard(cards, uiCard, reduxCard) {
  if (isAlreadyUpdated(cards, uiCard)) {
    return false;
  }

  if (isLinkedWithThePage(reduxCard)) {
    return true;
  }

  return false;
}

function useUpdateActivity({ cards, updateCard }) {
  useActivityEffect((event, namespace, payload) => {
    const isNew = event === CARD_CREATED;
    const cardsUpdated = includes([CARD_UPDATED, CARD_CREATED], event)
      ? [payload]
      : mergeCards(payload); // CARDS_UPDATED

    cardsUpdated.forEach(({ reduxCard, uiCard }) => {
      setTimeout(() => {
        shouldUpdateCard(cards, uiCard, reduxCard) && updateCard(reduxCard, isNew, false);
      }, 0);
    });
  }, [CARD_UPDATED, CARDS_UPDATED, CARD_CREATED], [ACTIVITIES_DRAWER_NAMESPACE]);
}

export default useUpdateActivity;
