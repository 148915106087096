import { size, filter, includes } from 'lodash';

import { STATUS_TYPES } from 'components/Grm2/constants';

function useBadge({ cards }) {
  const statusOnDrawer = [
    STATUS_TYPES.open.value,
    STATUS_TYPES.inProgress.value,
  ];

  const tasksCount = size(filter(cards, card => includes(statusOnDrawer, card.task.status)));

  const numberOfTasks = tasksCount >= 100 ? '99+' : tasksCount;

  const showBadge = !!numberOfTasks;

  return {
    showBadge,
    numberOfTasks,
  };
}

export default useBadge;
