import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';

import styles from './styles.module.scss';

function Undo({ onClick, message, disabled }) {
  return (
    <div className={styles.undo}>
      <div className={styles.content}>
        {message}
      </div>
      <Button
        theme="light"
        size="small"
        className={styles.button}
        onClick={onClick}
        disabled={disabled}
      >
        Undo
      </Button>
    </div>
  );
}

Undo.propTypes = {
  onClick: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Undo;

export { default as useUndo } from './useUndo';
