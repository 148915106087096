import { STATUS_TYPES } from 'components/Grm2/constants';

function useFilters({ status, onStatusChange }) {
  const statusOpen = STATUS_TYPES.open.value;
  const statusInProgress = STATUS_TYPES.inProgress.value;

  const isOpenActive = status === statusOpen;
  const isInProgressActive = status === statusInProgress;

  const handleOpenClick = () => {
    onStatusChange(statusOpen);
  };

  const handleInProgressClick = () => {
    onStatusChange(statusInProgress);
  };

  return {
    onOpenClick: handleOpenClick,
    isOpenActive,
    onInProgressClick: handleInProgressClick,
    isInProgressActive,
  };
}

export default useFilters;
