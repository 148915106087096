export const errorMessages = {
  title: {
    required: 'Please add a Title.',
  },
  description: {
    required: 'Please add a Description.',
  },
  customField: {
    required: 'This field is required.',
  },
  assignee: {
    required: 'Please add an assignee.',
  },
};

export const CUSTOM_FIELD_NAME_PREFIX = 'customFieldsAnswers';
