import { size, find } from 'lodash';

import useModal from 'components/core/Modal/useModal';
import { updateTaskCards } from 'components/Grm2/api';
import {
  isOnlyTasksSelected,
  getCardsTaskIds,
  getCardsByIds,
} from 'components/Grm2/utils';

function useFooterActions({
  updateTasks,
  cards,
  selectedCardIds = [],
}) {
  const { isOpenModal: showExportModal, toggleModal: toggleExportModal } = useModal();
  const { isOpenModal: showEditModal, toggleModal: toggleEditModal } = useModal();

  const isTasksSelected = isOnlyTasksSelected(selectedCardIds, cards);
  const tasksId = isTasksSelected ? getCardsTaskIds(getCardsByIds(cards, selectedCardIds)) : [];

  const isOneCardSelected = size(selectedCardIds) === 1;
  const cardId = isOneCardSelected ? selectedCardIds[0] : null;
  const card = find(cards, { id: cardId });

  const onReAssignSave = assigneeId => updateTaskCards({
    ids: tasksId,
    assignee_id: assigneeId,
  }).then(updateTasks);

  return {
    onReAssignSave,
    toggleExportModal,
    toggleEditModal,
    showEditModal,
    showExportModal,
    isTasksSelected,
    tasksId,
    isOneCardSelected,
    cardId,
    card,
  };
}

export default useFooterActions;
