import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Checkbox from 'components/core/Checkbox';
import LabelGroup from 'components/core/LabelGroup';
import { CARD_TYPES } from 'components/Grm2/constants';

import Item from './Item';
import { handleCheckboxChange } from '../../utils';
import styles from './styles.module.scss';
import filterComponentStyles from '../styles.module.scss';

function ActivityTypes({ value, onChange }) {
  const handleChange = (event) => {
    const newValues = handleCheckboxChange(value, event);

    onChange('activityTypes', newValues);
  };

  // eslint-disable-next-line
  const renderLabel = (props) => (
    <Item {...props} />
  );

  const options = Object.keys(CARD_TYPES).map(type => ({
    ...CARD_TYPES[type],
    optionValue: type,
  }));

  const isChecked = optionValue => value.includes(optionValue);

  return (
    <LabelGroup containerClassName={filterComponentStyles.item}>
      {options.map(({ label, optionValue, color, iconName }) => (
        <Checkbox
          key={optionValue}
          value={optionValue}
          label={renderLabel({
            label,
            isChecked: isChecked(optionValue),
            color,
            iconName,
          })}
          onChange={handleChange}
          isChecked={isChecked(optionValue)}
          className={classNames(
            `test__filter-activity-type-${optionValue.replace(/_/g, '-')}`,
            styles.checkbox,
          )}
        />
      ))}
    </LabelGroup>
  );
}

ActivityTypes.defaultProps = {
  value: [],
};

ActivityTypes.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};

export default ActivityTypes;
