import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import TagInput from 'components/core/TagInput';
import Label from 'components/core/Label';
import { getAutosuggesterOptionValue } from 'components/core/Select/utils';

import Tooltip from './Tooltip';
import styles from './styles.module.scss';

function TagInputField({
  name,
  title,
  strategy,
  placeholder,
  testClassName,
  ...restProps
}) {
  return (
    <Field name={name}>
      {({ field }) => {
        const handleChange = tags => field.onChange(name)(tags);

        return (
          <div className={styles.container}>
            <div key={title}>
              <Label className={styles.label}>
                {title}
                <Tooltip content={placeholder} theme="light" />
              </Label>

              <TagInput
                strategy={strategy}
                onChange={handleChange}
                defaultRawValues={field.value}
                className={testClassName}
                getOptionValue={getAutosuggesterOptionValue}
                {...restProps}
              />
            </div>
          </div>
        );
      }}
    </Field>
  );
}

TagInputField.propTypes = {
  name: PropTypes.string.isRequired,
  testClassName: PropTypes.string,
  title: PropTypes.string.isRequired,
  strategy: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  strategyInputs: PropTypes.objectOf(PropTypes.bool),
};

export default TagInputField;
