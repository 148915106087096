import { useState } from 'react';

import { getCurrentUser } from 'utils/currentUser';

function useTaskModal() {
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [taskModalId, setTaskModalId] = useState();
  const [taskModalInitialValues, setTaskModalInitialValues] = useState();

  const { userId, fullNameWithEmail } = getCurrentUser();

  const handleOpenTaskModal = ({ id, status }) => {
    setShowTaskModal(true);
    id && setTaskModalId(id);
    status && setTaskModalInitialValues({
      status,
      assignee: { value: userId, label: fullNameWithEmail },
    });
  };

  const handleCloseTaskModal = () => {
    setShowTaskModal(false);
    setTaskModalId();
    setTaskModalInitialValues();
  };

  return {
    showTaskModal,
    taskModalId,
    taskModalInitialValues,
    onOpenTaskModal: handleOpenTaskModal,
    onCloseTaskModal: handleCloseTaskModal,
  };
}

export default useTaskModal;
