import { useState } from 'react';

function useNewNote() {
  const [showNewNoteModal, setShowNewNoteModal] = useState(false);

  const handleClick = () => {
    setShowNewNoteModal(true);
  };

  const handleClose = () => {
    setShowNewNoteModal(false);
  };

  return {
    showNewNoteModal,
    onNewNoteClick: handleClick,
    onNewNoteClose: handleClose,
  };
}

export default useNewNote;
