import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Loader from 'components/core/Loader';
import Button from 'components/core/Button';
import ApplyTagsButton from 'components/Grm2/common/ApplyTagsButton';
import ReAssignButton from 'components/Grm2/common/ReAssignButton';
import RemoveButton from 'components/Grm2/common/Card/components/RemoveButton';
import ButtonShare from 'components/core/ButtonShare';
import { tagShape } from 'components/Grm2/shapes';
import { updateTaskCard } from 'components/Grm2/api';
import { CARD_SIZES } from 'components/Grm2/constants';
import { getMyFeed } from 'components/Dashboard/utils';

import { assigneeShape, cardSizeShape } from '../../shapes';
import PriorityTooltipButton from '../PriorityTooltipButton';
import styles from './styles.module.scss';

const sizeThemes = {
  large: styles.large,
  small: styles.small,
  medium: styles.medium,
};

function ActionBar({
  isShowingLinks,
  isEditable,
  isTask,
  priority,
  assignee,
  onShowLinksClick,
  onEditClick,
  onRemoveClick,
  showCommentAction,
  onCommentClick,
  id,
  tags,
  cardType,
  taskID,
  commentsCount,
  priorityIconOnly,
  onSaveTags,
  showCommentLoader,
  updateCard,
  documentType,
  updateDataItem,
  size,
  isRemovable,
}) {
  const showLinksLabel = isShowingLinks
    ? 'HIDE LINKS'
    : 'SHOW LINKS';

  const handleEditClick = () => {
    onEditClick(id);
  };

  const handleCommentClick = () => {
    onCommentClick(id);
  };

  const onReAssignSave = assigneeId => updateTaskCard({
    id: taskID,
    assignee_id: assigneeId,
  }).then(updateCard);

  const renderComment = () => (
    <Button
      theme="tableLink"
      disabled={showCommentLoader}
      onClick={handleCommentClick}
      className={classNames(styles.commentAction, sizeThemes[size])}
    >
      COMMENT{!!commentsCount && ` (${commentsCount})`}
      {showCommentLoader && <Loader size="small" onlyIcon className={styles.loader} />}
    </Button>
  );

  const buttonProps = {
    className: styles.button,
    theme: 'light',
  };

  function handleShareSuccess(recordId) {
    return updateDataItem && updateDataItem({
      recordId,
      sharings: [getMyFeed()],
    });
  }

  return (
    <div className={classNames(styles.actionBar, sizeThemes[size])}>
      <div className={classNames(styles.leftGroup, sizeThemes[size])}>
        <Button
          {...buttonProps}
          onClick={onShowLinksClick}
        >
          {showLinksLabel}
        </Button>
        <ApplyTagsButton
          onSave={onSaveTags}
          buttonClassName={styles.button}
          cardIds={[id]}
          cardType={cardType}
          tags={tags}
        />
        {isEditable && (
          <Button
            {...buttonProps}
            onClick={handleEditClick}
          >
            EDIT
          </Button>
        )}
        <ButtonShare
          {...buttonProps}
          tooltipContentClassName={styles.button}
          recordId={id}
          itemType="Activity"
          recordType={documentType}
          withOptions={false}
          onSuccess={handleShareSuccess}
        />
        {showCommentAction && renderComment()}
        {isTask && (
          <ReAssignButton
            onSave={onReAssignSave}
            defaultOption={assignee}
            {...buttonProps}
          >
            RE-ASSIGN
          </ReAssignButton>
        )}
      </div>
      <div className={classNames(styles.rightGroup, sizeThemes[size])}>
        {isTask && (
          <PriorityTooltipButton
            defaultOption={priority}
            id={taskID}
            onSave={updateCard}
            priorityIconOnly={priorityIconOnly}
          />
        )}
        {isRemovable && (
          <RemoveButton size={size} onClick={onRemoveClick} />
        )}
      </div>
    </div>
  );
}

ActionBar.propTypes = {
  isShowingLinks: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isRemovable: PropTypes.bool.isRequired,
  isTask: PropTypes.bool.isRequired,
  onShowLinksClick: PropTypes.func.isRequired,
  priority: PropTypes.string,
  assignee: assigneeShape,
  tags: PropTypes.arrayOf(tagShape),
  updateCard: PropTypes.func,
  onEditClick: PropTypes.func.isRequired,
  showCommentAction: PropTypes.bool,
  onCommentClick: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  cardType: PropTypes.string.isRequired,
  taskID: PropTypes.number,
  commentsCount: PropTypes.number,
  priorityIconOnly: PropTypes.bool,
  showCommentLoader: PropTypes.bool,
  onSaveTags: PropTypes.func,
  documentType: PropTypes.string.isRequired,
  updateDataItem: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  size: cardSizeShape,
};

ActionBar.defaultProps = {
  size: CARD_SIZES.large,
};

export default ActionBar;
